import { userAdminTheme } from '@naevaas/webtastic/dist/themes'

export const muiTheme = userAdminTheme

export interface ITheme {
  colors: {
    white: string
    black: string
    go: string
    stop: string
    mainGray: string
    mainOrange: string
    logoGreen: string
    logoRed: string
    mainGreen: string
    mainRed: string
    mainTransparent: string
    background: string
    backgroundGray: string
  }
}

const theme: ITheme = {
  colors: {
    white: '#FFFFFF',
    black: '#424543',
    go: '#449F35',
    stop: '#DB2323',
    mainGray: '#202020',
    mainOrange: '#E88B3A',
    logoGreen: '#55CC83',
    logoRed: '#E36B5B',
    mainGreen: '#438637',
    mainRed: '#f24747',
    mainTransparent: 'rgba(29,29,29,0.65)',
    background: '#303030',
    backgroundGray: '#262626',
  },
}

export default theme
