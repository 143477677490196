import React, { createContext, useState, useContext, useEffect } from 'react'
import { firestore } from 'firebase/app'

//
import { IAuthUserOwnData } from 'types/user'
import { useAuthContext } from './authContext'

const AuthUserOwnDataContext = createContext<IAuthUserOwnData | null>(null)
const AuthUserOwnDataActionsContext = createContext<any>(null)

export const useAuthUserOwnDataContext = (): IAuthUserOwnData | null =>
  useContext(AuthUserOwnDataContext)
export const useAuthUserOwnDataContextActions = (): React.ReactElement =>
  useContext(AuthUserOwnDataActionsContext)

const Provider = (props: P): React.ReactElement => {
  const { children } = props

  // State
  const [ownData, setOwnData] = useState<IAuthUserOwnData | null>(null)
  const [isListening, setIsListening] = useState(false)

  const authData = useAuthContext()
  const uid = authData?.uid
  const isSignUpLoading = authData?.isSignUpLoading
  /**
   * Firestore ownData (usersPrivate) listener
   */
  useEffect(() => {
    const unsubscribe =
      uid && !isListening
        ? firestore()
            .collection('usersPrivate')
            .doc(uid)
            .onSnapshot(function(response: any) {
              if (response.exists) {
                const ownData = response.data()

                setIsListening(true)
                setOwnData(ownData)
              } else {
                // Don't show error notification during sign up process
                if (!isSignUpLoading) {
                  window.furball.error(
                    'An error occured. Could not fetch data.',
                  )
                }
              }
            })
        : (): void => {
            // Do nothing
          }

    // Unsubscribe and reset state on unmount
    return (): void => {
      if (isListening) {
        unsubscribe()
        setOwnData(null)
        setIsListening(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, isListening])

  return (
    <AuthUserOwnDataContext.Provider value={ownData}>
      <AuthUserOwnDataActionsContext.Provider value={setOwnData}>
        {children}
      </AuthUserOwnDataActionsContext.Provider>
    </AuthUserOwnDataContext.Provider>
  )
}

export default Provider

type P = {
  children: React.ReactNode
}
