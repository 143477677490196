import React from 'react'

import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined'
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined'

import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles({
  container: {
    minWidth: '150px',
  },
  labelSection: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '8px 12px',
    margin: '8px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: 'inherit',
  },
  disabled: {
    pointerEvents: 'none',
    background: '#cacaca',
  },
  errorLabel: {
    color: '#E36B5B',
    marginRight: '8px',
  },
  errorBlock: {
    display: 'flex',
    alignItems: 'center',
  },
})

interface IFormInputProps {
  isValid?: boolean
  label?: string
  type: string
  name: string
  [x: string]: any
}

function FormInput(props: IFormInputProps): React.ReactElement {
  const {
    isValid,
    label,
    type,
    name,
    disabled,
    errorMessage,
    isFieldTouched,
    hideStateIcon,
    value,
    ...rest
  } = props
  const classes = useStyles()

  const inputStyles = disabled
    ? clsx([classes.input, classes.disabled])
    : classes.input
  const tabIndex = disabled ? -1 : 0

  return (
    <div className={classes.container}>
      <div className={classes.labelSection}>
        <label htmlFor={name}>{label}</label>
        {!hideStateIcon &&
          (isValid || (!isValid && isFieldTouched === undefined) ? (
            <SentimentSatisfiedOutlinedIcon
              style={{ color: !!isFieldTouched ? '#55CC83' : '#b5b5b5' }}
            />
          ) : (
            <div className={classes.errorBlock}>
              {errorMessage && (
                <span className={classes.errorLabel}>{errorMessage}</span>
              )}
              <SentimentDissatisfiedOutlinedIcon style={{ color: '#E36B5B' }} />
            </div>
          ))}
      </div>
      <input
        className={inputStyles}
        name={name}
        id={name}
        type={type}
        tabIndex={tabIndex}
        value={value}
        {...rest}
      />
    </div>
  )
}

export default React.memo(FormInput)
