//
import { sortBy } from 'utils/sortBy'
import { IList } from '../List/types'
import { ISitePermission } from 'types/site'

export const filterList = (
  status: IList['status'],
  permissions: ISitePermission[],
): ISitePermission[] => {
  return permissions
    .filter(p => {
      return status === 'VALID' ? p.__isValid : !p.__isValid
    })
    .sort(sortBy('name'))
}
