import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'

//
import { IAuthUser, IAuthUserActions } from 'types/user'
import useFetchPreApprovedWorkerSites from 'hooks/fetchSites/useFetchPreApprovedWorkerSites'
import useFetchPreApprovedCompanySites from 'hooks/fetchSites/useFetchPreApprovedCompanySites'
import useFetchCompanySites from 'hooks/fetchSites/useFetchCompanySites'
import useUserPermissions from 'hooks/useUserPermissionsListener'
import useCompanyPermissions from 'hooks/useCompanyPermissionsListener'
import { useAuthContext } from 'state/context/authContext'
import * as db from 'utils/firestoreDB'

const AuthUserContext = createContext<IAuthUser | null>(null)
const AuthUserActionsContext = createContext<any>(null)

export const useAuthUserContext = (): IAuthUser | null =>
  useContext(AuthUserContext)
export const useAuthUserContextActions = (): IAuthUserActions =>
  useContext(AuthUserActionsContext)

const Provider = (props: P): React.ReactElement => {
  const { children } = props

  // State
  const [authUser, setAuthUser] = useState<IAuthUser | null>(null)
  const [isListening, setIsListening] = useState(false)

  // Hooks
  const [fetchCompanySites, { sites: companySites }] = useFetchCompanySites()

  const authData = useAuthContext()
  const uid = authData?.uid
  const isSignUpLoading = authData?.isSignUpLoading

  const [
    fetchPreApprovedCompanySites,
    { sites: preApprovedCompanySites },
  ] = useFetchPreApprovedCompanySites()

  const [
    fetchPreApprovedWorkerSites,
    { sites: preApprovedWorkerSites },
  ] = useFetchPreApprovedWorkerSites()

  const [fetchUserPermissions, { userPermissions }] = useUserPermissions()
  const [
    fetchCompanyPermissions,
    { companyPermissions },
  ] = useCompanyPermissions()

  /**
   * Firestore authUser listener
   */
  useEffect(() => {
    const unsubscribe =
      uid && !isListening
        ? db
            .users()
            .doc(uid)
            .onSnapshot(function(response) {
              if (response.exists) {
                const authUser = response.data()
                if (authUser) {
                  const formattedAuthUser = { ...authUser, uid: authUser.id }
                  setIsListening(true)
                  setAuthUser(state => {
                    return {
                      ...state,
                      ...formattedAuthUser,
                    }
                  })
                }
              } else {
                // Don't show error notification during sign up process
                if (!isSignUpLoading) {
                  window.furball.error(
                    'An error occured. Could not fetch user data.',
                  )
                }
              }
            })
        : (): void => {
            // Do nothing
          }

    // Unsubscribe and reset state on unmount
    return (): void => {
      if (isListening) {
        unsubscribe()
        setAuthUser(null)
        setIsListening(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, isListening])

  /**
   * Fetch sites owned by auth users company
   */
  useEffect(() => {
    if (authUser?.company?.id && !companySites) {
      fetchCompanySites({ companyId: authUser.company.id })
    } else if (companySites) {
      // Was fetched
    }
  }, [authUser, companySites, fetchCompanySites])

  /**
   * Fetch sites where authUsers company is pre-approved
   */
  useEffect(() => {
    if (authUser?.company?.id && !preApprovedCompanySites) {
      fetchPreApprovedCompanySites({ companyId: authUser.company.id })
    } else if (preApprovedCompanySites) {
      // Was fetched
    }
  }, [authUser, preApprovedCompanySites, fetchPreApprovedCompanySites])

  /**
   * Fetch sites where authUser is preApprovedWorker (on external sites)
   */
  useEffect(() => {
    if (authUser?.uid && !preApprovedWorkerSites) {
      fetchPreApprovedWorkerSites({ uid: authUser.uid })
    } else if (preApprovedWorkerSites) {
      // Was fetched
    }
  }, [authUser, preApprovedWorkerSites, fetchPreApprovedWorkerSites])

  /**
   * Fetch user permissions
   */
  useEffect(() => {
    if (authUser?.uid && !userPermissions) {
      fetchUserPermissions({ userId: authUser.uid })
    } else if (userPermissions) {
      // Was fetched
    }
  }, [authUser, userPermissions, fetchUserPermissions])

  /**
   * Fetch company permissions
   */
  useEffect(() => {
    if (authUser?.uid && !companyPermissions) {
      fetchCompanyPermissions({ companyId: authUser.company.id })
    } else if (companyPermissions) {
      // Was fetched
    }
  }, [authUser, companyPermissions, fetchCompanyPermissions])

  /**
   * Set authUser.sites
   */
  useEffect(() => {
    let newCompanySites = {}
    let newPreApprovedCompanySites = {}
    let newPreApprovedWorkerSites = {}

    if (companySites && preApprovedCompanySites && preApprovedWorkerSites) {
      newCompanySites = companySites
      newPreApprovedCompanySites = preApprovedCompanySites
      newPreApprovedWorkerSites = preApprovedWorkerSites

      setAuthUser((state: any) => {
        if (state?.uid) {
          return {
            ...state,
            sites: {
              ...newCompanySites,
              ...newPreApprovedCompanySites,
              ...newPreApprovedWorkerSites,
            },
          }
        }

        return {
          ...state,
          sites: {},
        }
      })
    }
  }, [companySites, preApprovedCompanySites, preApprovedWorkerSites])

  /**
   * Set authUser.userPermissions
   */
  useEffect(() => {
    if (userPermissions) {
      setAuthUser((state: any) => {
        if (state?.uid) {
          return {
            ...state,
            userPermissions: { ...userPermissions },
          }
        }

        return {
          ...state,
          userPermissions: {},
        }
      })
    }
  }, [userPermissions])

  /**
   * Set authUser.companyPermissions
   */
  useEffect(() => {
    if (companyPermissions) {
      setAuthUser((state: any) => {
        if (state?.uid) {
          return {
            ...state,
            companyPermissions: { ...companyPermissions },
          }
        }

        return {
          ...state,
          companyPermissions: {},
        }
      })
    }
  }, [companyPermissions])

  const ready = useCallback(() => {
    if (
      authUser?.sites &&
      Object.keys(authUser?.sites) !== undefined &&
      authUser?.userPermissions &&
      Object.keys(authUser?.userPermissions) !== undefined &&
      authUser?.companyPermissions &&
      Object.keys(authUser?.companyPermissions) !== undefined
    ) {
      //console.log('READY')

      return true
    }

    return false
  }, [authUser])

  /**
   * Function to add a site to state
   */
  const addSite: IAuthUserActions['addSite'] = useCallback(site => {
    setAuthUser((state: any) => {
      return {
        ...state,
        sites: {
          ...state.sites,
          [site.id]: site,
        },
      }
    })
  }, [])

  const actions = useMemo(() => {
    return { addSite }
  }, [addSite])

  return (
    <AuthUserContext.Provider value={ready() ? authUser : null}>
      <AuthUserActionsContext.Provider value={actions}>
        {children}
      </AuthUserActionsContext.Provider>
    </AuthUserContext.Provider>
  )
}

export default Provider

type P = {
  children: React.ReactNode
}
