import React, { useEffect, useState, useCallback } from 'react'
import clsx from 'clsx'

// Material UI
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Typography,
  CardContent,
  Button,
  Checkbox,
} from '@material-ui/core'

// Webtastic
import useStyledScrollbar from '@naevaas/webtastic/dist/hooks/useStyledScrollbar'

//
import {
  ICompany,
  IUploadCompany,
} from 'sections/systemAdmin/state/types/saletool'
import { Modals } from 'sections/systemAdmin/constants/routing'
import {
  deleteCompanies,
  getCompanies,
  uploadCompany,
} from 'sections/systemAdmin/utils/company'

import LoadingSpinner from 'sections/systemAdmin/components/common/LoadingSpinner'
import Navigation from 'sections/systemAdmin/components/common/Navigation'
import CompanyDialog from '../CompanyDialog'
import DeleteDialog from '../DeleteDialog'

import { useStyles } from './styles'

function Companies(): React.ReactElement {
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])
  const [currentCompany, setCurrentCompany] = useState<ICompany | undefined>()
  const [dialogView, toggleDialog] = useState('')
  const [isLoading, setLoadingState] = useState(true)
  const styledScrollbar = useStyledScrollbar()
  const classes = useStyles()

  const closeDialog = useCallback(() => {
    toggleDialog('')
    setCurrentCompany(undefined)
  }, [])

  const handleGetCompanies = (): void => {
    getCompanies().then((data: ICompany[]) => {
      setCompanies(data)
      setLoadingState(false)
    })
  }

  useEffect(() => {
    handleGetCompanies()
  }, [])

  const handleSelectCompany = (e: any, id: string): void => {
    let currentCompanies
    if (e.target.checked) {
      currentCompanies = [...selectedCompanies, id]
    } else {
      currentCompanies = selectedCompanies.filter((item: string) => item !== id)
    }
    setSelectedCompanies(currentCompanies)
  }

  const handleDeleteCompanies = useCallback(() => {
    setLoadingState(true)
    deleteCompanies(selectedCompanies).then(() => {
      setSelectedCompanies([])
      closeDialog()
      handleGetCompanies()
    })
  }, [selectedCompanies, closeDialog])

  const handleUploadCompany = useCallback(
    (payload: IUploadCompany, file: File) => {
      setLoadingState(true)
      uploadCompany(payload, file).then(() => {
        closeDialog()
        handleGetCompanies()
      })
    },
    [closeDialog],
  )

  const selectedCompaniesLength = selectedCompanies.length

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="flex-start"
      padding="24px"
      flexDirection="column"
    >
      <Navigation />
      <Typography className={classes.pageTitle} variant="h6" component="h2">
        Companies
      </Typography>
      <div className={classes.controlPanel}>
        <Button
          className={classes.createButton}
          color="primary"
          onClick={(): void => {
            toggleDialog(Modals.CREATE_COMPANY)
          }}
        >
          Create new company
        </Button>
        {!!selectedCompaniesLength && (
          <Button
            color="primary"
            onClick={(): void => {
              toggleDialog(Modals.DELETE_COMPANIES)
            }}
          >
            Delete {selectedCompanies.length}{' '}
            {selectedCompaniesLength === 1 ? 'company' : 'companies'}
          </Button>
        )}
      </div>
      <Grid
        container
        spacing={3}
        className={clsx(
          classes.cardsContainer,
          styledScrollbar.styledScrollbar,
        )}
      >
        {companies.map((company: ICompany) => (
          <Grid key={company.id} item xs={12} md={6} lg={4}>
            <Card className={classes.cardWrapper}>
              <Checkbox
                color="primary"
                id={company.id}
                checked={selectedCompanies.some(
                  (item: string) => item === company.id,
                )}
                onChange={(e: any): void => {
                  e.stopPropagation()
                  handleSelectCompany(e, company.id)
                }}
                className={classes.checkbox}
              />
              <CardActionArea
                className={classes.wrapper}
                onClick={(): void => {
                  setCurrentCompany(company)
                  toggleDialog(Modals.EDIT_COMPANY)
                }}
              >
                <CardContent className={classes.content}>
                  <Typography
                    className={classes.cardTitle}
                    variant="h6"
                    component="h3"
                  >
                    {company.name}
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img
                      className={classes.image}
                      src={company.logoUrl}
                      alt={company.name}
                    />
                  </div>
                  <Button component="div" className={classes.editButton}>
                    Edit
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {dialogView === Modals.CREATE_COMPANY && (
        <CompanyDialog
          isEdit={false}
          onClose={closeDialog}
          saveCompany={handleUploadCompany}
        />
      )}
      {dialogView === Modals.EDIT_COMPANY && (
        <CompanyDialog
          isEdit
          onClose={closeDialog}
          company={currentCompany}
          saveCompany={handleUploadCompany}
        />
      )}
      {dialogView === Modals.DELETE_COMPANIES && (
        <DeleteDialog
          onClose={closeDialog}
          deleteItem={handleDeleteCompanies}
          title="Delete selected companies?"
        />
      )}
    </Box>
  )
}

export default Companies
