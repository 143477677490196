import React, { useEffect, useState } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'

//
import { useStyles } from './styles'
import { IAuthLayout } from './types'

const image = new Image()

function AuthLayout(props: IAuthLayout): React.ReactElement {
  const {
    bgImageSrc,
    bgMode,
    menuComponent,
    contentComponent,
    children,
  } = props

  const [bg, setBg] = useState<{
    src: string | null
    mode: IAuthLayout['bgMode'] | null
  }>({ src: null, mode: null })

  useEffect(() => {
    if (!bgImageSrc) {
      setBg({
        src: null,
        mode: null,
      })
    } else if (bgImageSrc && bgImageSrc !== image.src) {
      image.src = bgImageSrc

      image.onload = (_el): void => {
        setBg({
          src: bgImageSrc,
          mode: bgMode,
        })
      }
      image.onerror = (_el): void => {
        setBg({
          src: null,
          mode: null,
        })
      }
    }
    return (): void => {
      image.onload = null
      image.onerror = null
    }
  }, [bgImageSrc, bgMode])

  // reset image src after component unmount
  useEffect(() => {
    return (): void => {
      image.src = ''
    }
  }, [])

  const classes = useStyles()

  return (
    <>
      <Box
        flex="auto"
        display="flex"
        /* overflow="hidden" NOTE: this breaks position:sticky in child elements */
        style={{
          background: 'center / cover no-repeat',
          backgroundSize: '150%',
          backgroundImage: bg.src ? `url("${bg.src}")` : 'none',
          boxShadow: bg.src
            ? bg.mode === 'light'
              ? 'inset 0 -100vh rgba(0, 0, 0, 0.8)'
              : 'inset 0 -100vh rgba(0, 0, 0, 0.3)'
            : undefined,
        }}
      >
        {menuComponent && <Box>{menuComponent}</Box>}
        {contentComponent && (
          <Box
            flex="auto"
            className={classes.contentBox}
            style={{ backdropFilter: bg.src ? 'blur(3px)' : undefined }}
          >
            {contentComponent}
          </Box>
        )}
      </Box>
      {children}
    </>
  )
}

export default AuthLayout
