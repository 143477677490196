import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      ailgnItems: 'center',
      padding: theme.spacing(2),
    },
    sections: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      '& > div': {
        width: '250px',
        '&:first-of-type': {
          marginRight: '48px',
        },
      },
    },
    signInWithGoogle: {
      backgroundColor: '#fff',
      color: '#737373',
      alignSelf: 'center',
      width: '210px',
      marginTop: '30px',
      opacity: 1,
      textTransform: 'none',
      padding: 8,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#fff',
        boxShadow: '1px 4px 5px 1px rgba(0,0,0,0.1)',
      },
      '& svg': {
        marginRight: 24,
      },
    },
    submitBtn: {
      alignSelf: 'center',
      width: '210px',
      marginTop: '54px',
      marginLeft: 'calc(50% - 105px)',
    },
  }
})
