import React, { useEffect } from 'react'

import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined'
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined'
import { useStyles } from './styles'
import ColorCircularProgress from 'components/common/ColorCircularProgress'
import clsx from 'clsx'

interface IFormInputWithAutocompleteProps {
  selectOption(payload: any): void
  clearOptions(): void
  isValid?: boolean
  label?: string
  type: string
  name: string
  options: Array<any>
  [x: string]: any
  isSearching: boolean
}

function FormInputWithAutocomplete(
  props: IFormInputWithAutocompleteProps,
): React.ReactElement {
  const {
    isValid,
    label,
    type,
    name,
    disabled,
    errorMessage,
    hideStateIcon,
    options,
    selectOption,
    clearOptions,
    isConnectedFieldsEmpty,
    isSearching,
    isFieldTouched,
    ...rest
  } = props

  const classes = useStyles()

  // const [isFieldTouched, setIsFieldTouched] = useState(false);

  // if (props.value && !isFieldTouched) setIsFieldTouched(true);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [options]) // eslint-disable-line

  const handleOutsideClick = (): void => {
    const { options, clearOptions } = props
    if (options && options.length) {
      clearOptions()
    }
  }

  const handleClick = (event: any): void => {
    if (event.nativeEvent) {
      event.nativeEvent.stopImmediatePropagation()
    }
  }

  const isValidField =
    isValid && (!isFieldTouched || (isFieldTouched && !isConnectedFieldsEmpty))

  return (
    <div className={classes.container} onClick={handleClick}>
      <div className={classes.labelSection}>
        <label htmlFor={name}>{label}</label>
        {!hideStateIcon &&
          (isValidField || (!isValid && isFieldTouched === undefined) ? (
            <SentimentSatisfiedOutlinedIcon
              style={{ color: !!isFieldTouched ? '#55CC83' : '#b5b5b5' }}
            />
          ) : (
            <div className={classes.errorBlock}>
              {errorMessage && (
                <span className={classes.errorLabel}>{errorMessage}</span>
              )}
              <SentimentDissatisfiedOutlinedIcon style={{ color: '#E36B5B' }} />
            </div>
          ))}
      </div>
      <input
        className={clsx(
          disabled ? [classes.input, classes.disabled] : classes.input,
        )}
        name={name}
        id={name}
        type={type}
        {...rest}
        autoComplete="new-password"
      />
      {(options[0] || isSearching) && (
        <div className={classes.autoCompleteBlock}>
          {isSearching ? (
            <div className={classes.searchSpinner}>
              <ColorCircularProgress />
            </div>
          ) : (
            options.map((option: any) => (
              <li
                key={option.orgNumber}
                onClick={(): void => selectOption(option)}
              >
                {option.companyName}
              </li>
            ))
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(FormInputWithAutocomplete)
