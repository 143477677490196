import React, { ReactElement } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

//
import { IEmptyState } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: '10rem',
    marginBottom: '5vh',
    color: theme.palette.primary.main,
    opacity: '0.5',
  },
  defaultIcon: {
    fontSize: '2rem',
  },
}))

const EmptyState = (props: IEmptyState): ReactElement => {
  const { icon, primaryText, secondaryText, actions, ...rest } = props
  const classes = useStyles()
  return (
    <Box
      flex="auto"
      display="flex"
      flexDirection="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {icon ? (
        React.cloneElement(icon, {
          className: classes.icon,
        })
      ) : (
        <Box className={`${classes.icon} ${classes.defaultIcon}`}>
          ¯\\_(ツ)_/¯
        </Box>
      )}
      {primaryText && (
        <Typography component="h1" variant="h5" color="textSecondary">
          {primaryText}
        </Typography>
      )}
      {secondaryText && (
        <Box textAlign="center">
          <br />
          <Typography variant="body1" color="textSecondary">
            {secondaryText}
          </Typography>
        </Box>
      )}
      {actions && (
        <Box textAlign="center">
          <br />
          {actions}
        </Box>
      )}
    </Box>
  )
}

export default EmptyState
