import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'

// Material UI
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@naevaas/webtastic/dist/components/Dialog'
import ConfigCodeIcon from '@naevaas/webtastic/dist/icons/CodeLock'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import { IConfigCodeDialog } from './types'
import { useFetch } from 'hooks/useFetch'
import { useAuthUserOwnDataContext } from 'state/context/authUserOwnDataContext'
import useDebounce from 'hooks/useDebounce'

export function ConfigCodeDialog(props: IConfigCodeDialog): React.ReactElement {
  const {
    onClose = (): void => {
      // do nothing
    },
  } = props

  const [openState, setOpenState] = useState(true)
  const [countDown, setCountDown] = useState(0)

  // Context
  const ownData = useAuthUserOwnDataContext()
  const configCode = ownData?.configCode || ''
  const expiresAtMillis = ownData?.configCodeExpiresTimestamp?.toMillis() || 0

  const nowMillis = new Date().getTime()

  // Hooks
  const [fetch, { loading }] = useFetch()
  const debouncedCountDown = useDebounce(countDown, 1000)

  const buttonHandler = (): void => {
    fetch({
      apiName: 'API',
      endpoint: 'generateConfigCode',
      data: {},
    }).then(response => {
      if (response?.code === 'C2000') {
        // All good. Get the code from authUserPrivate
      } else {
        window.furball.error('Error! Could not create config code.')
        console.error(response)
      }
    })
  }

  useEffect(() => {
    if (expiresAtMillis > nowMillis) {
      const diff = expiresAtMillis - nowMillis
      setCountDown(diff)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresAtMillis, debouncedCountDown])

  return (
    <Dialog
      open={openState}
      onClose={(): void => {
        setOpenState(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      titleIcon={<ConfigCodeIcon fontSize="large" />}
      fullHeight={false}
      maxWidth={'sm'}
    >
      <DialogTitle>Config code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The config code is used to configure refero units and machines.
        </DialogContentText>
        <DialogContentText>
          Share the code with others to let them create a NAEVA user account.
        </DialogContentText>
        <Box my={4}>
          {configCode && expiresAtMillis > nowMillis ? (
            <>
              <Typography
                variant="overline"
                style={{
                  fontSize: '2rem',
                  lineHeight: 'inherit',
                  letterSpacing: '0.5rem',
                }}
              >
                {configCode}
              </Typography>
              <br />
              <Typography variant="caption" color="textSecondary">
                {`Expires at ${format(expiresAtMillis, 'HH:mm')} (${format(
                  debouncedCountDown,
                  'mm',
                )}m:${format(debouncedCountDown, 'ss')}s left)`}
              </Typography>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={(): void => buttonHandler()}
              isWaiting={loading}
            >
              Generate code
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
