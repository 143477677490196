import React, { ReactElement } from 'react'

// Material-UI
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

//
import { IPageNavigation } from './types'

const PageNavigation = (props: IPageNavigation): ReactElement => {
  const { page, onChange } = props

  return (
    <Tabs
      value={page}
      onChange={(e, value): void => {
        onChange(value as IPageNavigation['page'])
      }}
      indicatorColor="primary"
      textColor="primary"
      variant="standard"
      aria-label="Navigation"
    >
      <Tab label="Active" value={'active'} />
      <Tab label="Archived" value={'archive'} />
    </Tabs>
  )
}

export default PageNavigation
