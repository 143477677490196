import React, { useCallback, useState } from 'react'

// Material UI
import { Typography, TextField, FormControl } from '@material-ui/core'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import { ICreateCategory } from 'sections/systemAdmin/state/types/saletool'
import { formErrors } from 'sections/systemAdmin/constants/salestool'
import { useStyles } from './styles'

export interface ICompanyDialog {
  onClose: () => void
  createCategory: (category: ICreateCategory) => void
}

export function CreateCategoryDialog(
  props: ICompanyDialog,
): React.ReactElement {
  const { onClose, createCategory } = props
  const [isOpen, toggleDialog] = useState(true)
  const [categoryName, setCategoryName] = useState('')
  const [norwCategoryName, setNorwCategoryName] = useState('')
  const [categoryNameError, setCategoryNameError] = useState('')
  const [norwCategoryNameError, setNorwCategoryNameError] = useState('')
  const classes = useStyles()

  const submitForm = (e: any): void => {
    e.preventDefault()

    let isErrorExist = false

    if (!categoryName) {
      isErrorExist = true
      setCategoryNameError(formErrors.required)
    }

    if (!norwCategoryName) {
      isErrorExist = true
      setNorwCategoryNameError(formErrors.required)
    }

    if (!isErrorExist) {
      createCategory({ name: categoryName, norwegianName: norwCategoryName })
      onClose()
    }
  }

  const handleChangeName = useCallback((e: any): void => {
    const value = e.target.value
    setCategoryNameError(value ? '' : formErrors.required)
    setCategoryName(value)
  }, [])

  const handleChangeNorwName = useCallback((e: any): void => {
    const value = e.target.value
    setNorwCategoryNameError(value ? '' : formErrors.required)
    setNorwCategoryName(value)
  }, [])

  return (
    <Dialog
      open={isOpen}
      onClose={(): void => {
        toggleDialog(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      fullHeight={false}
      maxWidth="md"
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle} variant="h5" component="h3">
          New category
        </Typography>
      </DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent>
          <FormControl className={classes.inputControlBlock}>
            <TextField
              id="name"
              name="name"
              label="Category name"
              type="text"
              onChange={handleChangeName}
              value={categoryName}
              error={!!categoryNameError}
              helperText={categoryNameError}
            />
          </FormControl>
          <FormControl className={classes.inputControlBlock}>
            <TextField
              id="norwName"
              name="norwName"
              label="Category name(Norwegian)"
              type="text"
              onChange={handleChangeNorwName}
              value={norwCategoryName}
              error={!!norwCategoryNameError}
              helperText={norwCategoryNameError}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default React.memo(CreateCategoryDialog)
