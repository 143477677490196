import React, { useEffect, useState, useCallback } from 'react'

// Material UI
import Box from '@material-ui/core/Box'
import EmployeeRolesIcon from '@material-ui/icons/HowToReg'
import Button from '@material-ui/core/Button'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@naevaas/webtastic/dist/components/Dialog'
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

//
import { IEmployeeRolesDialog } from './types'
import { useGetEmployeeRoles } from 'hooks/useGetEmployeeRoles'
import { IAuthUser } from 'types/user'
import List from './List'
import EditEmployee from './EditEmployee'
import AddEmployee from './AddEmployee'
import { useAuthUserContext } from 'state/context/authUserContext'
import { ICompanyUserPermission } from 'types/company'
import { IUserProfile } from 'types/user'
import { useContext as useCompanyContext } from 'state/context/companyContext'

export function EmployeeRolesDialog(
  props: IEmployeeRolesDialog,
): React.ReactElement {
  const {
    onClose = (): void => {
      // do nothing
    },
  } = props

  const [openState, setOpenState] = useState(true)
  const [openAddEmployee, setOpenAddEmployee] = useState(false)
  const [
    editEmployee,
    setEditEmployee,
  ] = useState<ICompanyUserPermission | null>(null)

  // Context
  const authUser = useAuthUserContext() as IAuthUser
  const { selectedCompany } = useCompanyContext()

  const [fetch, { loading, data: employees }] = useGetEmployeeRoles({
    companyId: selectedCompany?.company.id as string,
  })

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getEmployeeById = useCallback(
    (id: string): ICompanyUserPermission => {
      return employees.filter(employee => employee.userId === id)[0]
    },
    [employees],
  )

  const handleEditEmployee = (id: string): void => {
    setEditEmployee(getEmployeeById(id))
  }

  const doneEditing = (): void => {
    setEditEmployee(null)
    fetch()
  }

  const createEmployeeByUser = (user: IUserProfile): ICompanyUserPermission => {
    return {
      userId: user.id,
      userName: user.name,
      userImageThumbUrl: user.imageThumbUrl,
      userCompanyId: '',
      userCompanyName: '',
      companyPermission: null,
      sitePermissions: [],
    }
  }

  const handleAddEmployee = useCallback(
    (user: IUserProfile): void => {
      setOpenAddEmployee(false)
      setEditEmployee(getEmployeeById(user.id) || createEmployeeByUser(user))
    },
    [getEmployeeById],
  )

  return (
    <Dialog
      open={openState}
      onClose={(): void => {
        setOpenState(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      titleIcon={<EmployeeRolesIcon fontSize="large" />}
    >
      <DialogTitle>Employee roles</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Company: {selectedCompany?.company.name}{' '}
        </DialogContentText>
        <Box display="flex" justifyContent="flex-end" mr={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={(): void => setOpenAddEmployee(true)}
          >
            Find employee
          </Button>
        </Box>

        <List
          uid={authUser.uid}
          employees={employees}
          handleEditEmployee={handleEditEmployee}
        />
        {editEmployee && selectedCompany && (
          <EditEmployee
            onClose={doneEditing}
            company={{
              id: selectedCompany?.company.id,
              name: selectedCompany?.company.name,
            }}
            employee={editEmployee}
          />
        )}

        {selectedCompany && (
          <AddEmployee
            open={openAddEmployee}
            onClose={(): void => {
              setOpenAddEmployee(false)
            }}
            company={{
              id: selectedCompany?.company.id,
              name: selectedCompany?.company.name,
            }}
            onAddUser={handleAddEmployee}
          />
        )}

        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
