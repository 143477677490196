import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Material-UI
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import GridIcon from '@material-ui/icons/ViewComfy'
import AddSiteIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ArchiveIcon from '@material-ui/icons/MoveToInbox'

//
import { INavButtons } from './types'
import { Routes, routes } from 'constants/routing'
import ArchiveSiteDialog from 'sections/siteAdmin/components/ArchiveSiteDialog'
import { Modals } from 'constants/routing'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'

export default function NavButtons(props: INavButtons): React.ReactElement {
  const { site, changeSelectedSite } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openDialog, setOpenDialog] = useState<Modals.ARCHIVE_SITE | null>(null)

  // Hooks
  const { sitePolicy } = useSitePolicy()
  const history = useHistory()

  const openSiteDropdownMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const closeSiteDropdownMenu = (): void => {
    setAnchorEl(null)
  }

  function closeDialog(): void {
    setOpenDialog(null)
  }

  return (
    <>
      <Tooltip title="All sites">
        <IconButton
          aria-label="View all sites"
          onClick={(): void => {
            history.push(Routes.SITES)
          }}
        >
          <GridIcon />
        </IconButton>
      </Tooltip>{' '}
      {sitePolicy().create() && (
        <>
          <Tooltip title="Create site">
            <IconButton
              aria-label="Create site"
              onClick={(): void => {
                history.push(routes.SITES.NEW)
              }}
            >
              <AddSiteIcon />
            </IconButton>
          </Tooltip>{' '}
        </>
      )}
      {sitePolicy().archive(site) && !site?.archived && (
        <>
          <Tooltip title="More options...">
            <IconButton
              aria-label="More options..."
              aria-controls={`more-options-menu-${site.id}`}
              aria-haspopup="true"
              onClick={openSiteDropdownMenu}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id={`more-options-menu-${site.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeSiteDropdownMenu}
          >
            <MenuItem
              onClick={(): void => {
                setOpenDialog(Modals.ARCHIVE_SITE)
                closeSiteDropdownMenu()
              }}
            >
              <ListItemIcon>
                <ArchiveIcon />
              </ListItemIcon>
              Archive site
            </MenuItem>
          </Menu>
          {openDialog === Modals.ARCHIVE_SITE && (
            <ArchiveSiteDialog
              onClose={closeDialog}
              changeSelectedSite={changeSelectedSite}
            />
          )}
        </>
      )}
    </>
  )
}
