import React, { ReactElement, useState } from 'react'
import { OrderedMap } from 'immutable'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import add from 'date-fns/add'

// Materiual-UI
import Box from '@material-ui/core/Box'
import ListSubheader from '@material-ui/core/ListSubheader'
import HistoryIcon from '@material-ui/icons/History'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

// Webtastic
import useStyledScrollbar from '@naevaas/webtastic/dist/hooks/useStyledScrollbar'

//
import Activity from './Activity'
import { TSiteTimelineEvent } from 'types/site'
import {
  useMenuDrawerContext,
  useMenuDrawerContextActions,
} from 'state/context/menuDrawerContext'
import TimelineContextProvider, {
  useTimeLineContext,
} from 'state/context/timelineContext'
import { useContext as useSiteContext } from 'state/context/siteContext'

function RecentActivity(): React.ReactElement {
  // eslint-disable-next-line
  const [mountedTimestamp, setMountedTimestamp] = useState(Date.now())

  // Context
  const { selectedSite } = useSiteContext()
  const siteActivity = useTimeLineContext()
  const { open: drawerIsOpen } = useMenuDrawerContext()

  // Hooks
  const styledScrollbar = useStyledScrollbar()
  const { setOpen: setDrawerOpen } = useMenuDrawerContextActions()

  const eventsOnDate = (
    events: TSiteTimelineEvent[],
    date: string,
  ): React.ReactElement | React.ReactElement[] => {
    if (!events || !selectedSite) return <></>

    return (
      <li key={`section-${date}`}>
        <ul style={{ padding: '0px', paddingBottom: '1px' }}>
          <ListSubheader
            style={{ backgroundColor: '#121212' }}
          >{`${date}`}</ListSubheader>
          {events.map((event) => {
            return (
              <Activity
                key={event.eventId}
                userName={event.user.name}
                action={event.action}
                checkType={event.checkType}
                date={event.timestamp}
                site={selectedSite.name}
                company={event.user.company.name}
                userAvatarUrl={
                  event.user.imageThumbUrl || event.user.imageUrl || ''
                }
                displayText={event.displayText}
                animate={!!(Date.now() - mountedTimestamp > 2000)}
              />
            )
          })}
        </ul>
      </li>
    )

    /* return (
      <Box key={date}>
        <Box ml={2} mt={3}>
          <Typography variant="body1">{date}</Typography>
        </Box>
        {renderEvents(events, selectedSite)}
      </Box>
    ) */
  }

  const sortSiteActivityEvents = (siteActivity: TSiteTimelineEvent[]): any => {
    const todaysEvens = []
    const yesterdaysEvents = []
    const remainsEvents = []
    for (const event of siteActivity) {
      if (isSameDay(new Date(), event.timestamp)) {
        todaysEvens.push(event)
      } else if (isSameDay(add(new Date(), { days: -1 }), event.timestamp)) {
        yesterdaysEvents.push(event)
      } else {
        remainsEvents.push(event)
      }
    }
    return { todaysEvens, yesterdaysEvents, remainsEvents }
  }

  const renderRemainsEvents = (
    siteActivity: TSiteTimelineEvent[],
    _selectedSite: IFirestoreSite,
  ): React.ReactElement => {
    let eventsPerDate = OrderedMap()
    for (const event of siteActivity) {
      const eventKey = format(event.timestamp, 'PP')
      if (eventsPerDate.has(eventKey)) {
        eventsPerDate = eventsPerDate.update(eventKey, (list: any) =>
          list.concat(event),
        )
      } else {
        eventsPerDate = eventsPerDate.set(eventKey, [event])
      }
    }
    const ev = eventsPerDate
      .map((events: any, key: any) => {
        return eventsOnDate(events, key)
      })
      .toArray()
      .map((array) => array.slice(1))

    return (
      <Box display="flex" flexDirection="column">
        {ev}
      </Box>
    )
  }

  const { todaysEvens, yesterdaysEvents, remainsEvents } =
    sortSiteActivityEvents(siteActivity)

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <List>
        <Tooltip
          title={!drawerIsOpen ? 'Recent activity' : ''}
          placement="left"
          arrow={true}
        >
          <ListItem
            button
            onClick={() => setDrawerOpen(true)}
            disabled={drawerIsOpen ? true : false}
          >
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText
              primary={drawerIsOpen ? 'Recent activity' : '&nbsp;'}
              /* secondary={selectedSite.name} */
              style={{ color: drawerIsOpen ? 'inherit' : 'transparent' }}
            />
          </ListItem>
        </Tooltip>
      </List>

      {drawerIsOpen && (
        <Box flex="auto" overflow="hidden">
          <List
            className={styledScrollbar.styledScrollbar}
            style={{
              maxHeight: '100%',
              overflow: 'auto',
            }}
            subheader={<li />}
          >
            {selectedSite && siteActivity[0] ? (
              <>
                {todaysEvens[0] &&
                  renderRemainsEvents(todaysEvens, selectedSite)}
                {yesterdaysEvents[0] &&
                  renderRemainsEvents(yesterdaysEvents, selectedSite)}
                {remainsEvents[0] &&
                  renderRemainsEvents(remainsEvents, selectedSite)}
              </>
            ) : (
              <Box textAlign="center" p={2}>
                <Box>¯\\_(ツ)_/¯</Box>

                <Box py={1}>No activity yet</Box>
              </Box>
            )}
          </List>
        </Box>
      )}
    </Box>
  )
}

const RecentActivityWithContext = (): ReactElement => {
  return (
    <TimelineContextProvider>
      <RecentActivity />
    </TimelineContextProvider>
  )
}
export default React.memo(RecentActivityWithContext)
