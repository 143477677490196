import { useState } from 'react'

import { useFetch } from './useFetch'

interface IArchiveSite {
  siteId: string
  forceCheckOut?: boolean
}

const useArchiveSite = (): Response => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [fetch] = useFetch()

  const archiveSite = async (props: IArchiveSite): Promise<any> => {
    setLoading(true)
    try {
      await fetch({
        apiName: 'API',
        endpoint: 'archiveSite',
        data: { ...props },
      })
    } catch (e) {
      setError(e)
      window.furball.error(
        'Something went wrong. Could not archive the site.',
      )
      console.error(e)
    }

    setLoading(false)
  }

  const result = {
    loading,
    error,
  }

  return [archiveSite, result]
}

type Response = [
  (props: IArchiveSite) => Promise<any>,
  {
    loading: boolean
    error: string
  },
]

export default useArchiveSite
