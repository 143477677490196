import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Chip } from '@material-ui/core'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

//
import DatePicker from 'components/common/DatePicker'
import { IEditAccess, ISavedState, ITimeRangeState } from './types'
import { useCloudFunction } from 'hooks/useCloudFunctions'
import GateKeeper from '../GateKeeper'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  switch: {
    marginRight: '-15px',
  },
  accordionDetails: {
    flexDirection: 'column',
  },
}))

const EditAccess = (props: IEditAccess): ReactElement => {
  const {
    onClose,
    site,
    accesses: { __typename, items, ...restAccesses },
  } = props

  const newAccess = !!restAccesses?.new

  const [isNew, setIsNew] = useState(false)
  const [isArchived, setIsArchived] = useState(false)
  const [allowAsGuest, setAllowAsGuest] = useState(false)
  const [allowMachines, setAllowMachines] = useState(false)
  const [timeRange, setTimeRange] = useState<ITimeRangeState>({
    start: null,
    end: null,
  })
  const [savedState, setSavedState] = useState<ISavedState>({})
  const [hasChanged, setHasChanged] = useState(false)

  const [timeAccordionOpen, setTimeAccordionOpen] = useState(false)
  const [doorAccordionOpen, setDoorAccordionOpen] = useState(false)
  const [isUpdatingDoor, setIsUpdatingDoor] = useState(false)

  const [fetch, { loading }] = useCloudFunction()

  const classes = useStyles()

  const isCompanies = __typename === 'COMPANY'

  /**
   * Init state
   */
  useEffect(() => {
    const isNew = newAccess
    const isArchived = !items[0]?.__isValid || false
    const allowAsGuest = items[0]?.entryAllow?.includes('guest') || false
    const allowMachines = items[0]?.machinesAllow || false
    const timeRangeStart = items[0]?.timeRestrictionStart
      ? +startOfDay(items[0]?.timeRestrictionStart.toMillis())
      : null
    const timeRangeEnd = items[0]?.timeRestrictionEnd
      ? +endOfDay(items[0]?.timeRestrictionEnd.toMillis())
      : null

    setIsNew(isNew)
    setIsArchived(isArchived)
    setAllowAsGuest(allowAsGuest)
    setAllowMachines(allowMachines)
    setTimeRange({
      start: timeRangeStart,
      end: timeRangeEnd,
    })

    if (items[0]?.timeRestricted) setTimeAccordionOpen(true)

    setSavedState((state) => {
      return {
        ...state,
        isNew,
        isArchived,
        allowAsGuest,
        allowMachines,
        timeRange: {
          start: timeRangeStart,
          end: timeRangeEnd,
        },
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Function to check if time range is valid or not.
   */
  const validTimeRange = useCallback((): boolean => {
    const start = timeRange?.start
    const end = timeRange?.end
    if (start && end && end > start) {
      return true
    } else if (!start && !end) {
      return true
    }

    return false
  }, [timeRange])

  /**
   * Check diffs with current state and most recent savedState and set `hasChanged`
   * Note: `savedState` reflects confirmed changes on server
   */
  useEffect(() => {
    if (
      Object.keys(savedState).length &&
      (isArchived !== savedState.isArchived ||
        allowAsGuest !== savedState.allowAsGuest ||
        allowMachines !== savedState.allowMachines ||
        timeRange?.start !== savedState?.timeRange?.start ||
        timeRange?.end !== savedState?.timeRange?.end)
    ) {
      setHasChanged(true)
    } else {
      setHasChanged(false)
    }
  }, [isArchived, allowAsGuest, allowMachines, timeRange, savedState])

  /**
   * Save to db
   */
  const saveHandler = useCallback(() => {
    let saveSuccess = true
    items.forEach((item) => {
      const request = isCompanies
        ? fetch('updateApprovedCompany', {
            companyOrgNr: item?.companyData?.orgNr || '',
            siteId: site.id,
            timeRestriction: !!(timeRange.start && timeRange.end),
            timeRestrictionStart: timeRange.start ? timeRange.start : undefined,
            timeRestrictionEnd: timeRange.end ? timeRange.end : undefined,
            allowBooking: true,
            allowSiteEntry: true,
            allowMachines: allowMachines,
            archive: isArchived,
          })
        : fetch('updateApprovedWorker', {
            workerId: item.id,
            siteId: site.id,
            timeRestriction: !!(timeRange.start && timeRange.end),
            timeRestrictionStart: timeRange.start ? timeRange.start : undefined,
            timeRestrictionEnd: timeRange.end ? timeRange.end : undefined,
            allowBooking: true,
            allowSiteEntry: true,
            allowMachines: allowMachines,
            allowAsGuest,
            archive: isArchived,
          })

      request
        .then((res) => {
          if (res?.data?.code.substring(0, 2) === 'C2') {
            setSavedState((state) => {
              return {
                ...state,
                allowAsGuest,
                allowMachines,
                timeRange: {
                  start: timeRange.start,
                  end: timeRange.end,
                },
                isArchived,
              }
            })
          } else {
            throw new Error('err')
          }
        })
        .catch(() => {
          window.furball.error('Something went wrong. Changes was not saved')
          saveSuccess = false
        })
    })

    // If the item(s) was added/created, close the dialog.
    // This way the user needs to actively click Edit to make changes, and a clean state is ensured
    if (isNew && saveSuccess) {
      window.furball.success('Permissions was saved')
      setTimeout(() => {
        onClose()
      }, 500)
    }
  }, [
    items,
    isNew,
    isCompanies,
    fetch,
    site.id,
    timeRange.start,
    timeRange.end,
    allowMachines,
    isArchived,
    allowAsGuest,
    onClose,
  ])

  /**
   * Handle enable/disable toggle switch
   */
  const toggleIsArchived = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsArchived(!event.target.checked)
  }

  /**
   * Handle guest toggle switch
   */
  const toggleAllowAsGuest = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setAllowAsGuest(event.target.checked)
  }

  /**
   * Handle allowMachine toggle switch
   */
  const toggleAllowMachine = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setAllowMachines(event.target.checked)
  }

  /**
   * Handle timerange accordion open/close
   */
  const handleAccordionChange = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ): void => {
    setTimeAccordionOpen(isExpanded)
  }

  /**
   * Handle timerange `from` input change
   */
  const handleTimeRangeStart = (time: any): void => {
    setTimeRange((state) => {
      return {
        ...state,
        start: +startOfDay(time),
      }
    })
  }

  /**
   * Handle timerange `to` input change
   */
  const handleTimeRangeEnd = (time: any): void => {
    setTimeRange((state) => {
      return {
        ...state,
        end: +endOfDay(time),
      }
    })
  }

  return (
    <Dialog open={!!items} onClose={onClose} fullHeight={false} maxWidth="sm">
      <DialogTitle>
        {`Edit site access for ${items.length === 1 ? items[0].name : ''}`}
      </DialogTitle>

      <DialogContent>
        {items.length > 1 && (
          <Box mb={4}>
            {items.map((item) => {
              return (
                <Box key={item.id} component="span" py={1} pr={1}>
                  <Chip label={item.name} />
                </Box>
              )
            })}
          </Box>
        )}

        <Accordion expanded={false} disabled={loading}>
          <AccordionSummary>
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="auto">
                <Typography id="isActive-switch-label">Enable</Typography>
                <Typography variant="body2" color="textSecondary">
                  {isArchived ? `No access to the site` : `Enabled site access`}
                </Typography>
              </Box>
              <Box>
                <Switch
                  checked={!isArchived}
                  onChange={toggleIsArchived}
                  aria-labelledby="isActive-switch-label"
                  color="primary"
                  classes={{ root: classes.switch }}
                />
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
        <Accordion
          expanded={timeAccordionOpen}
          disabled={loading || isArchived}
          onChange={handleAccordionChange}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="auto">
                <Typography>Time restriction</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <Typography variant="inherit" color="textSecondary">
              Both dates are required
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Box mr={1}>
                <DatePicker
                  label="From (and including)"
                  id="date-picker-from"
                  value={timeRange.start}
                  onChange={handleTimeRangeStart}
                  disabled={loading || isArchived}
                />
              </Box>
              <Box ml={1}>
                <DatePicker
                  label="To (and including)"
                  id="date-picker-to"
                  value={timeRange.end}
                  onChange={handleTimeRangeEnd}
                  disabled={loading || isArchived}
                />
              </Box>
            </Box>

            <Box mt={2}>
              <Button
                onClick={(): void => {
                  setTimeRange({ start: null, end: null })
                }}
                disabled={
                  loading || isArchived || (!timeRange.start && !timeRange.end)
                }
              >
                Reset time restriction
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Box mt={2} />

        {!isCompanies && (
          <Accordion expanded={false} disabled={loading || isArchived}>
            <AccordionSummary
              expandIcon={
                <Switch
                  checked={allowAsGuest}
                  onChange={toggleAllowAsGuest}
                  aria-labelledby="allowAsGuest-switch-label"
                  color="primary"
                  classes={{ root: classes.switch }}
                />
              }
            >
              <Box display="flex" alignItems="center" width="100%">
                <Box flex="auto">
                  <Typography id="allowAsGuest-switch-label">Guest</Typography>
                </Box>
              </Box>
            </AccordionSummary>
          </Accordion>
        )}
        <Accordion expanded={false} disabled={loading || isArchived}>
          <AccordionSummary
            expandIcon={
              <Switch
                checked={allowMachines}
                onChange={toggleAllowMachine}
                aria-labelledby="allowMachines-switch-label"
                color="primary"
                classes={{ root: classes.switch }}
              />
            }
          >
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="auto">
                <Typography id="allowMachines-switch-label">
                  Operate machines
                </Typography>
                <Typography variant="body2" color="textSecondary"></Typography>
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>

        {!isCompanies && (
          <Accordion
            expanded={doorAccordionOpen}
            disabled={loading || isArchived}
            onChange={() => setDoorAccordionOpen(!doorAccordionOpen)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center" width="100%">
                <Box flex="auto">
                  <Typography>Open doors</Typography>
                  <Typography variant="inherit" color="textSecondary">
                    {items.length === 1
                      ? `Set which doors ${items[0].name} is allowed to open`
                      : `Set which doors they are allowed to open`}
                  </Typography>
                </Box>
                <Box>
                  {isUpdatingDoor ? <CircularProgress size={20} /> : ''}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <GateKeeper
                siteId={site.id}
                userIds={items.map((item) => {
                  return item.id
                })}
                setIsUpdating={setIsUpdatingDoor}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          onClick={saveHandler}
          disabled={
            isNew
              ? isArchived
                ? true
                : loading
              : !validTimeRange() || !hasChanged || loading
          }
          isWaiting={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditAccess
