import React, { ReactElement, useEffect, useRef } from 'react'

// Common
import FullScreenLoading from 'components/common/FullScreenLoading'

//
import {
  Provider as CompanyContextProvider,
  useContext as useCompanyContext,
  useContextDispatch as useCompanyDispatch,
} from 'state/context/companyContext'
import { companies } from 'utils/firestoreDB'
import { useAuthUserContext } from '../../authUserContext'

const CompanyContextContainer = (props: IProps): ReactElement => {
  const { children } = props

  const authUser = useAuthUserContext()
  const companyId = authUser?.company.id

  const companyContext = useCompanyContext()
  const { defaultCompany, selectedCompany, selectCompanyById } = companyContext

  const dispatch = useCompanyDispatch()

  const defaultRef = useRef<Function | null>(null)
  const selectedRef = useRef<Function | null>(null)

  /**
   * Set Default company (listener)
   */
  useEffect(() => {
    if (companyId && !defaultCompany) {
      // Unsubscribe previous listener
      defaultRef.current && defaultRef.current()

      const q = companies().doc(companyId)

      const unsub = q.onSnapshot((res): void => {
        const company = res.data()

        if (company) {
          dispatch('SET_DEFAULT', { company })

          // Select default
          const selectedCompanyCookie = localStorage.getItem('selectedCompany')
          if (selectedCompanyCookie) {
            dispatch('SELECT_COMPANY_BY_ID', { id: selectedCompanyCookie })
          } else {
            !selectedCompany && dispatch('SELECT_DEFAULT')
          }
        }
      })

      defaultRef.current = unsub
    }

    return (): void => {
      defaultRef.current && defaultRef.current()
    }
  }, [companyId, defaultCompany, dispatch, selectedCompany])

  /**
   * Select company by ID
   * Set listener
   */
  useEffect(() => {
    if (selectCompanyById !== selectedCompany?.company.id) {
      // Unsubscribe previous listener
      selectedRef.current && selectedRef.current()

      const q = companies().doc(selectCompanyById)

      const unsub = q.onSnapshot((res): void => {
        const company = res.data()

        if (company) {
          dispatch('SET_SELECTED', { company })
        } else {
          dispatch('SELECT_DEFAULT')
        }
      })

      selectedRef.current = unsub
    }

    return (): void => {
      selectedRef.current && selectedRef.current()
    }
  }, [selectCompanyById, dispatch, selectedCompany, companyId])

  /* useEffect(() => {
    console.log('companyContext', companyContext)
  }, [companyContext]) */

  if (authUser) {
    if (selectedCompany?.company) {
      return <>{children}</>
    }
    return <FullScreenLoading />
  } else {
    return <>{children}</>
  }
}

const CompanyContextContainerWithContext = (
  props: IProps,
): React.ReactElement => {
  const { children } = props
  return (
    <CompanyContextProvider>
      <CompanyContextContainer>{children}</CompanyContextContainer>
    </CompanyContextProvider>
  )
}

export default CompanyContextContainerWithContext

interface IProps {
  children: React.ReactNode
}
