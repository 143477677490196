import { useState, useCallback } from 'react'
import { firestore } from 'firebase/app'

import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import add from 'date-fns/add'
import startOfWeek from 'date-fns/startOfWeek'
import startOfMonth from 'date-fns/startOfMonth'

//
import { CrewListData, CrewListQueryParams } from 'state/actions/site'
import { formatCrewListData } from 'state/formatters/site'
import * as db from 'utils/firestoreDB'

const useGetCrewList = (): Response => {
  const [data, setData] = useState<CrewListData[]>([])
  const [error, setError] = useState(null)

  const fetch = useCallback((props: CrewListQueryParams) => {
    const { timeRange, searchSelection, siteId, timeRangeFrom, timeRangeTo } =
      props

    let currentTimeRangeFrom = timeRangeFrom
    let currentTimeRangeTo = timeRangeTo
    let sessionsRef = db
      .checkSessions()
      .where('siteId', '==', siteId)
      .orderBy('timestampIn', 'desc')

    if (timeRangeFrom && timeRangeTo) {
      currentTimeRangeFrom = +startOfDay(timeRangeFrom)
      currentTimeRangeTo = +endOfDay(timeRangeTo)

      sessionsRef = sessionsRef
        .where(
          'timestampIn',
          '>=',
          firestore.Timestamp.fromMillis(currentTimeRangeFrom),
        )
        .where(
          'timestampIn',
          '<=',
          firestore.Timestamp.fromMillis(currentTimeRangeTo),
        )
    }

    if (timeRange === 'Now') {
      sessionsRef = sessionsRef.where('open', '==', true)
    }

    if (!timeRangeFrom && !timeRangeTo && timeRange !== 'Now') {
      currentTimeRangeTo = +endOfDay(new Date())
      switch (timeRange) {
        case 'Today': {
          currentTimeRangeFrom = +startOfDay(new Date())
          break
        }
        case 'Yesterday': {
          currentTimeRangeFrom = +startOfDay(add(new Date(), { days: -1 }))
          currentTimeRangeTo = +endOfDay(add(new Date(), { days: -1 }))
          break
        }
        case 'This week': {
          currentTimeRangeFrom = +startOfWeek(new Date())
          break
        }
        case 'This month': {
          currentTimeRangeFrom = +startOfMonth(new Date())
          break
        }
        default: {
          // just for keeping list empty before user selects time range
          currentTimeRangeFrom = +startOfDay(add(new Date(), { days: 1 }))
          currentTimeRangeTo = +endOfDay(add(new Date(), { days: 1 }))
        }
      }
      sessionsRef = sessionsRef
        .where(
          'timestampIn',
          '>=',
          firestore.Timestamp.fromMillis(currentTimeRangeFrom),
        )
        .where(
          'timestampIn',
          '<=',
          firestore.Timestamp.fromMillis(currentTimeRangeTo),
        )
    }

    if (searchSelection) {
      if (searchSelection.__typename === 'USER') {
        sessionsRef = sessionsRef.where('user.id', '==', searchSelection.id)
      } else if (searchSelection.__typename === 'COMPANY') {
        sessionsRef = sessionsRef.where(
          'user.company.id',
          '==',
          searchSelection.id,
        )
      }
    }

    sessionsRef
      .get()
      .then((data) => {
        const docs = data.docs.map((item) => {
          return item.data()
        })

        const crewList = formatCrewListData(docs)
        setData(crewList)
      })
      .catch((e) => {
        console.error(e)
        setError(e)
        setData([])
      })
  }, [])

  return [fetch, { data, error }]
}

export default useGetCrewList

type Response = [
  (props: CrewListQueryParams) => void,
  {
    data: CrewListData[]
    error: any
  },
]
