import React from 'react'

// Material UI
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'

// Webtastic
import EmptyState from '@naevaas/webtastic/dist/components/EmptyState'

//
import DocumentListItem from './DocumentListItem/DocumentListItem'
import { IDocumentsItems } from './types'

function DocumentList({
  items,
  isArchived,
  siteId,
}: IDocumentsItems): React.ReactElement {
  const filteredItems = Object.entries(items).filter(
    (e) => e[1].active === !isArchived,
  )
  return (
    <Box mt={4}>
      <List>
        {filteredItems.length ? (
          filteredItems.map((rde) => {
            return (
              <DocumentListItem
                key={rde[0]}
                rdeId={rde[0]}
                rde={rde[1]}
                siteId={siteId}
              />
            )
          })
        ) : (
          <Box mt={4}>
            <EmptyState
              primaryText={
                isArchived
                  ? 'No archived documents'
                  : 'No required documents yet'
              }
            />
          </Box>
        )}
      </List>
    </Box>
  )
}

export default DocumentList
