import { geom, operation } from 'jsts'
import { distanceTo } from 'geolocation-utils'

import { PolygonCoordinate } from '../types'

type _TPolygonPoint = {
  x: number
  y: number
}

export const calculateBufferPath = (
  path: PolygonCoordinate[],
  spacing: number,
): PolygonCoordinate[] => {
  // Function to convert map coordinates to meter grid
  const convertToMeterCoordinates = (
    pathy: PolygonCoordinate[],
  ): PolygonCoordinate[] => {
    const meterPrLng: number = distanceTo(
      { lat: path[0].lat, lng: 5 },
      { lat: path[0].lat, lng: 4 },
    )
    const meterPrLat = 111319.49079327357
    const meterCoordinates = pathy.map(coordinate => {
      return {
        lng: coordinate.lng * meterPrLng,
        lat: coordinate.lat * meterPrLat,
      }
    })
    return meterCoordinates
  }

  // Function to convert meter grid to coordinates
  const convertToLngLatCoordinates = (
    lat: number,
    pathy2: PolygonCoordinate[],
  ): PolygonCoordinate[] => {
    const meterPrLng: number = distanceTo({ lat, lng: 5 }, { lat, lng: 4 })

    const meterPrLat = 111319.49079327357
    const lngCoordinates = pathy2.map(coordinate => {
      return {
        lng: coordinate.lng / meterPrLng,
        lat: coordinate.lat / meterPrLat,
      }
    })
    return lngCoordinates
  }
  // 1. Convert path to meter grid
  const meterPath = convertToMeterCoordinates(path)

  // 2. Create geometrical buffer path on meter grid
  const points = meterPath.map((coordinate: PolygonCoordinate) => ({
    x: coordinate.lng,
    y: coordinate.lat,
  }))
  const geoInput = points.map(
    (point: _TPolygonPoint) => new geom.Coordinate(point.x, point.y),
  )
  geoInput.push(geoInput[0])
  const geometryFactory = new geom.GeometryFactory() as any
  const shell = geometryFactory.createPolygon(geoInput)
  const polygon = shell.buffer(
    spacing,
    operation.buffer.BufferParameters.CAP_FLAT,
  )
  let inflatedCoordinates
  if (
    polygon &&
    polygon._shell &&
    polygon._shell._points &&
    polygon._shell._points._coordinates
  ) {
    const oCoordinates: _TPolygonPoint[] = polygon._shell._points._coordinates
    inflatedCoordinates = oCoordinates.map((item: _TPolygonPoint) => ({
      x: item.x,
      y: item.y,
    }))
  } else {
    inflatedCoordinates = null
  }
  // 3. Convert path back to path on map coordinates
  const mapCoordinates =
    inflatedCoordinates &&
    convertToLngLatCoordinates(
      path[0].lat,
      inflatedCoordinates.map(
        (polygonPoint: _TPolygonPoint): PolygonCoordinate => ({
          lng: polygonPoint.x,
          lat: polygonPoint.y,
        }),
      ),
    )
  return mapCoordinates || []
}
