import React from 'react'

// Material-UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper'

//
import { IRequirementItemProps } from './types'

function RequirementItem(props: IRequirementItemProps): React.ReactElement {
  const {
    name,
    description,
    value,
    changeSiteRequirement,
    requirement,
    children,
    gridProps,
    disabled = false,
    altAction,
  } = props

  const handleToggleClick = (): void => {
    changeSiteRequirement(requirement, !value)
  }

  return (
    <Grid item xs={12} md={6} {...gridProps}>
      <Paper style={{ height: '100%' }}>
        <List component="div">
          <ListItem component="div">
            <Box pr={3}>
              <ListItemText primary={name} secondary={description} />
            </Box>
            <ListItemSecondaryAction>
              {altAction ? (
                <>{altAction}</>
              ) : (
                <Switch
                  checked={value}
                  color="primary"
                  onChange={handleToggleClick}
                  inputProps={{ 'aria-label': `Toggle ${name}` }}
                  disabled={disabled}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {children}
      </Paper>
    </Grid>
  )
}

export default React.memo(RequirementItem)
