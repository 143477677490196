import { IState, TAction } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_DIALOG_IS_OPEN': {
      if (action.open) {
        return {
          ...state,
          dialogIsOpen: true,
        }
      } else {
        return {
          ...state,
          dialogIsOpen: false,
          view: 'ACTIVE',
        }
      }
    }

    case 'SET_VIEW': {
      return {
        ...state,
        view: action.view,
        selectedFile:
          action.view !== 'UPLOADER' ? undefined : state.selectedFile,
        uploadStatus: action.view !== 'UPLOADER' ? 'NONE' : state.uploadStatus,
      }
    }

    case 'SET_SELECTED_FILE': {
      return {
        ...state,
        selectedFile: action.file,
      }
    }

    case 'SET_SELECTED_FILE_NAME': {
      return {
        ...state,
        selectedFileName: action.name,
      }
    }

    case 'SET_UPLOAD_STATUS': {
      return {
        ...state,
        uploadStatus: action.status,
      }
    }

    case 'RESET_SELECTED_FILE': {
      return {
        ...state,
        selectedFile: undefined,
        selectedFileName: undefined,
        uploadStatus: 'NONE',
      }
    }

    default:
      return state
  }
}
