import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      flexGrow: 1,
      display: 'flex',
      height: 224,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: 200,
    },
    tab: {},
    selectSiteContainer: {
      minHeight: 80,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    wordBreak: {
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
    selectRoot: {
      maxWidth: '100%',
    },
    select: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    inputBase: {
      maxWidth: '100%',
    },
  }
})
