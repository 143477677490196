import React, { ReactElement, useCallback } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import Tooltip from '@material-ui/core/Tooltip'

//
import { useContext, useContextDispatch } from '../context'

const Toolbar = (): ReactElement => {
  // context
  const state = useContext()
  const dispatch = useContextDispatch()

  const undo = useCallback(() => {
    dispatch('POLYGON_HISTORY_BACK')
  }, [dispatch])

  const redo = useCallback(() => {
    dispatch('POLYGON_HISTORY_FORWARD')
  }, [dispatch])

  return (
    <Box display="flex" py={1}>
      <Box flex="auto"></Box>
      <Box>
        <Tooltip title={'Undo'} enterDelay={500} leaveDelay={0} arrow>
          <Box>
            <IconButton
              aria-label={'Undo polygon change'}
              onClick={undo}
              disabled={state.polygonHistory?.index ? false : true}
            >
              <UndoIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Box pl={1}>
        <Tooltip title={'Redo'} enterDelay={500} leaveDelay={0} arrow>
          <Box>
            <IconButton
              aria-label={'Redo polygon change'}
              onClick={redo}
              disabled={
                state.polygonHistory?.hasOwnProperty('index') &&
                state.polygonHistory?.index <
                  state.polygonHistory?.snapshots.length - 1
                  ? false
                  : true
              }
            >
              <RedoIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default Toolbar
