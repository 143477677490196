import React, { ChangeEvent, useState } from 'react'

// Material UI
import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import DescriptionIcon from '@material-ui/icons/Description'

// WebStatic
import TextFieldWithValidation from '@naevaas/webtastic/dist/components/TextFieldValidation'

//
import { IAddDocument } from './types'
import { useContext, useContextDispatch } from '../context'
import SelectFile from './SelectFile/SelectFile'
import { IFile } from 'utils/fileSelector'
import { useEffect } from 'react'
import usefileUploader from 'utils/fileUploader'
import UploadSuccess from './Success'
import UploadError from './Error'
import { useFetch } from 'hooks/useFetch'

function AddDocument(props: IAddDocument): React.ReactElement {
  const { siteId } = props
  const [nameErrors, setNameErrors] = useState<[string] | null>(null)

  // context
  const context = useContext()
  const dispatch = useContextDispatch()

  // hooks
  const [uploadFile] = usefileUploader()
  const [fetch] = useFetch()

  /**
   * Dispatch selected file
   */
  const setFile = (file: IFile) => {
    dispatch('SET_SELECTED_FILE', { file })

    const seperator = /[.|,| |_|-]/gi
    const filenameSuggestion = file.name
      .split('.')
      .slice(0, -1)
      .join('.')
      .replace(seperator, ' ')

    dispatch('SET_SELECTED_FILE_NAME', { name: filenameSuggestion })
  }

  /**
   * Dispatch selected file status
   */
  useEffect(() => {
    if (context.selectedFile && context.selectedFileName && !nameErrors) {
      dispatch('SET_UPLOAD_STATUS', { status: 'READY' })
    } else {
      dispatch('SET_UPLOAD_STATUS', { status: 'NONE' })
    }
  }, [context.selectedFile, context.selectedFileName, nameErrors, dispatch])

  /**
   * File name error handling
   */
  useEffect(() => {
    if (!context.selectedFileName || context.selectedFileName.length < 3) {
      setNameErrors(['Please give the file a descriptive name'])
    } else {
      setNameErrors(null)
    }
  }, [context.selectedFileName])

  /**
   * Upload the file
   */
  useEffect(() => {
    if (context?.selectedFile && context?.uploadStatus === 'START') {
      dispatch('SET_UPLOAD_STATUS', { status: 'IN_PROGRESS' })
      uploadFile({
        path: `sites/${siteId}/`,
        file: context.selectedFile,
      })
        .then((uploadedFile: IFile) => {
          fetch({
            apiName: 'API',
            endpoint: 'addRdeTemplate',
            data: {
              siteId,
              title: context.selectedFileName,
              shortTitle: context.selectedFileName?.substr(0, 3),
              rdePath: uploadedFile.__data?.metadata?.fullPath,
            },
          })
            .then(() => {
              dispatch('SET_UPLOAD_STATUS', { status: 'SUCCESS' })
            })
            .catch(() => {
              dispatch('SET_UPLOAD_STATUS', { status: 'ERROR' })
            })
        })
        .catch(() => {
          dispatch('SET_UPLOAD_STATUS', { status: 'ERROR' })
        })
    }
  }, [
    context?.uploadStatus,
    context.selectedFile,
    context.selectedFileName,
    siteId,
    uploadFile,
    dispatch,
    fetch,
  ])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="90%"
      padding={4}
    >
      {!context.selectedFile ? (
        <SelectFile setFile={setFile} />
      ) : (
        <Box width="100%" textAlign="center">
          <>{context.uploadStatus === 'SUCCESS' && <UploadSuccess />}</>
          <>{context.uploadStatus === 'ERROR' && <UploadError />}</>

          {!['SUCCESS', 'ERROR'].includes(context?.uploadStatus || '') && (
            <>
              <Box>
                <DescriptionIcon
                  style={{ fontSize: '100px' }}
                  color="inherit"
                />
              </Box>

              <Typography variant="caption" color="textSecondary">
                {context.selectedFile.name}
              </Typography>

              <Box mt={4}>
                <TextFieldWithValidation
                  id="file-name"
                  label={'Name'}
                  value={context.selectedFileName ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                    dispatch('SET_SELECTED_FILE_NAME', {
                      name: event.currentTarget.value,
                    })
                  }
                  errors={nameErrors}
                  textFieldProps={{
                    disabled: ['START', 'IN_PROGRESS', 'DONE'].includes(
                      context?.uploadStatus || '',
                    ),
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default AddDocument
