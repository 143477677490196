import React, { useState, useEffect, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'

// Material-UI
import Button from '@material-ui/core/Button'

//
import { IAllSites } from './types'
import PageNavigation from './PageNavigation'
import { Routes } from 'constants/routing'
import { IAllSitesParams } from 'sections/siteAdmin/SiteAdminRoot/types'
import SiteList from './SiteList'
import PageWithAppBar from 'components/layout/PageWithAppBar'
import EmptyState from 'components/common/EmptyState'
import CreateSiteButton from './CreateSiteButton'
import CreateSiteAppBarButton from './CreateSiteAppBarButton'

import SearchInput from 'components/layout/PageWithAppBar/AppBar/SearchInput'
import useDebounce from 'hooks/useDebounce'
import { useContext as useSiteContext } from 'state/context/siteContext'

export function AllSites(props: IAllSites): React.ReactElement {
  const { history } = props
  const page = props.match.params.page

  const { sites } = useSiteContext()

  const [keyword, setKeyword] = useState<string | null>(null)
  const [filter, setFilter] = useState({
    archived: page === 'archive',
    keyword: '',
  })

  // Hooks
  const debouncedKeyword = useDebounce(keyword, 250)
  //const sites = useSites({ filter })

  const filterSites = useCallback(() => {
    if (sites) {
      const filtered = {} as typeof sites
      Object.values(sites).forEach(site => {
        const pass = [true]
        if (filter?.archived !== undefined) {
          pass.push(!(site.archived !== filter.archived))
        }

        if (filter?.keyword) {
          const keyword = filter.keyword.toLowerCase().trim()
          if (keyword) {
            pass.push(site.searchName.includes(keyword))
          }
        }

        if (!pass.includes(false)) {
          filtered[site.id] = site
        }
      })

      return filtered
    }
  }, [filter, sites])

  const filteredSites = filterSites()

  const navigate = (page: IAllSitesParams['page']): void => {
    history.push(`${Routes.SITES}/${page}`)
  }

  const searchInputHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setKeyword(e.target.value)
  }

  /**
   * Reset filter
   */
  const resetFilter = () => {
    setKeyword('')
  }

  /**
   * Set filter: archived
   */
  useEffect(() => {
    setFilter(state => {
      return { ...state, archived: page === 'archive' }
    })
  }, [page])

  /**
   * Set filter: keyword
   */
  useEffect(() => {
    setFilter(state => {
      return { ...state, keyword: debouncedKeyword }
    })
  }, [debouncedKeyword])

  return (
    <>
      <PageWithAppBar
        AppBarProps={{
          title: 'Sites',
          actions: [
            <SearchInput
              key="search"
              value={keyword || ''}
              onChange={searchInputHandler}
            />,
            <CreateSiteAppBarButton key="createsite" />,
          ],
          lowerContent: <PageNavigation page={page} onChange={navigate} />,
        }}
        ContainerProps={{
          maxWidth: false,
        }}
      >
        {filteredSites && !isEmpty(filteredSites) ? (
          <SiteList sites={filteredSites} />
        ) : (
          <>
            {page === 'active' ? (
              <>
                {filter.keyword ? (
                  <EmptyState
                    primaryText={'No sites was found'}
                    actions={
                      <Button onClick={(): void => resetFilter()}>
                        Reset filter
                      </Button>
                    }
                    mt={10}
                  />
                ) : (
                  <EmptyState
                    primaryText={'No sites was found'}
                    secondaryText={'Would you like to create one now?'}
                    actions={<CreateSiteButton />}
                    mt={10}
                  />
                )}
              </>
            ) : (
              <>
                <EmptyState
                  primaryText={'No archived sites was found'}
                  secondaryText={''}
                  actions={
                    <Button onClick={(): void => resetFilter()}>
                      Reset filter
                    </Button>
                  }
                  mt={10}
                />
              </>
            )}
          </>
        )}
      </PageWithAppBar>
    </>
  )
}

export default AllSites
