import React, { ReactElement } from 'react'

// Webtastic
import {
  useContext as useValidationContext,
  useValidationHelper,
} from '@naevaas/webtastic/dist/components/TextFieldValidation'

// Common
import FindUserOrCompany, {
  IFindUserOrCompany,
} from 'components/common/FindUserOrCompany'

//
import { IClient } from './types'
import { useContextDispatch } from '../context'

const Client = (__props: IClient): ReactElement => {
  const dispatch = useContextDispatch()
  const { errors } = useValidationContext()

  const validationHelper = useValidationHelper({
    errors: errors?.client ?? null,
  })

  const onSelectionChange: IFindUserOrCompany['onSelectionChange'] = (
    selection,
    reason,
  ): void => {
    if (reason === 'clear') {
      dispatch('SET_CLIENT', {
        company: null,
      })
    } else {
      dispatch('SET_CLIENT', {
        company: {
          id: selection[0]?.id || '',
          orgNr: selection[0]?.data?.orgNr || '',
        },
      })
    }
    validationHelper.handleFocus()
    validationHelper.handleBlur()
  }

  return (
    <>
      <FindUserOrCompany
        typename="COMPANY"
        onSelectionChange={onSelectionChange}
        autocompleteProps={{
          multiple: false,
          textFieldProps: {
            onFocus: validationHelper.handleFocus,
            onBlur: validationHelper.handleBlur,
            helperText: validationHelper.helperText,
            error: validationHelper.isError,
            required: true,
          },
          filterOptions: (x: any) => x,
        }}
      />
    </>
  )
}

export default Client
