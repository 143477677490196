import React, { ReactElement } from 'react'
import format from 'date-fns/format'

// Material-UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt'

// Webtastic
import { DialogContentText } from '@naevaas/webtastic/dist/components/Dialog'

//
import { IBookingDetails } from './types'

const BookingDetails = (props: IBookingDetails): ReactElement => {
  const { booking, showFields = ['PERIOD', 'USAGE'] } = props

  return (
    <>
      {showFields?.includes('PERIOD') &&
        booking.toTimestamp &&
        booking.fromTimestamp && (
          <Box display="flex" alignItems="center" alignContent="center">
            <Box pr={2}>
              {booking.fromTimestamp &&
                format(booking.fromTimestamp.toMillis(), 'dd.MM.yyyy HH:mm')}
            </Box>

            <ArrowRightIcon />

            <Box pl={2}>
              {booking.toTimestamp &&
                format(booking.toTimestamp.toMillis(), 'dd.MM.yyyy HH:mm')}
            </Box>
          </Box>
        )}

      {showFields?.includes('USAGE') && booking.comment && (
        <Box mt={3}>
          <Typography variant="body2">Usage</Typography>
          <DialogContentText style={{ whiteSpace: 'pre-line' }}>
            {booking.comment || ''}
          </DialogContentText>
        </Box>
      )}
    </>
  )
}

export default BookingDetails
