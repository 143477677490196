import React from 'react'

// Material-UI
import SiteAccessIcon from '@material-ui/icons/VerifiedUser'
import EditSiteIcon from '@material-ui/icons/PinDrop'
import RequirementsIcon from '@material-ui/icons/ToggleOn'
import CrewListIcon from '@material-ui/icons/FormatListBulleted'

//
import { Modals } from 'constants/routing'

export interface ISiteAction {
  key: Modals
  title: string
  description: string
  icon: React.ReactElement
}

const siteActions = [
  {
    key: Modals.SITE_ACCESS,
    title: 'Site access',
    description: '',
    icon: <SiteAccessIcon fontSize="large" />,
  },
  {
    key: Modals.EDIT_SITE,
    title: 'Edit site',
    description: '',
    icon: <EditSiteIcon fontSize="large" />,
  },
  {
    key: Modals.REQUIREMENTS,
    title: 'Worker requirements',
    description: '',
    icon: <RequirementsIcon fontSize="large" />,
  },
  {
    key: Modals.CREW_LIST,
    title: 'Crew list',
    description: '',
    icon: <CrewListIcon fontSize="large" />,
  },
] as ISiteAction[]

export default siteActions
