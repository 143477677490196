import { useReducer as _useReducer, useCallback } from 'react'

import { IState, TAction, IDispatch } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_SITES': {
      return {
        ...state,
        sites: action.sites,
      }
    }

    case 'SET_SELECTED_SITE': {
      return {
        ...state,
        selectedSite: action.site,
      }
    }

    case 'RESET': {
      return {
        sites: null,
        selectedSite: null,
      }
    }

    default:
      return state
  }
}

export const useReducer = (initialState: IState): [IState, IDispatch] => {
  const [state, _dispatch] = _useReducer(reducer, initialState)

  const dispatch: IDispatch = useCallback(
    (type: string, action?: any): void => {
      const _action = { type, ...action } as TAction
      _dispatch(_action)
    },
    [_dispatch],
  )

  return [state, dispatch]
}
