import React, { ReactElement, useState, useCallback } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Alert from '@material-ui/lab/Alert'
import ViewAccessIcon from '@material-ui/icons/Visibility'
import ManageAccessIcon from '@material-ui/icons/Create'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

//
import { IEditEmployee } from './types'
import { useFetch } from 'hooks/useFetch'
import { ICompanyUserPermission } from 'types/company'
import { useContext as useSiteContext } from 'state/context/siteContext'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  switch: {
    marginRight: '-15px',
  },
}))

const EditEmployee = (props: IEditEmployee): ReactElement => {
  const { onClose, company, employee } = props
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(
    !!employee?.companyPermission?.editor || false,
  )
  const [sitePermissions, setSitePermissions] = useState<
    ICompanyUserPermission['sitePermissions']
  >(employee.sitePermissions)

  const [sitePanelOpen, setSitePanelOpen] = useState(!isCompanyAdmin)

  // Context
  const { sites } = useSiteContext() ?? {}

  // Hooks
  const [post, { loading }] = useFetch()
  const classes = useStyles()

  const toggleCompanyAdmin = useCallback(() => {
    post({
      endpoint: 'updateCompanyAdmin',
      data: {
        workerId: employee.userId,
        companyId: company.id,
        revoke: isCompanyAdmin,
      },
    }).then(res => {
      if (res.code === 'C2000') {
        if (isCompanyAdmin) {
          window.furball.success('Company admin was disabled')
        } else {
          window.furball.success('Company admin was enabled')
          setSitePanelOpen(false)
        }
        setIsCompanyAdmin(!isCompanyAdmin)
      }
    })
  }, [post, employee.userId, company.id, isCompanyAdmin])

  const handleCheckbox = useCallback(
    (site, role, checked): void => {
      post({
        endpoint: 'updateSiteRole',
        data: {
          workerId: employee.userId,
          siteId: site.id,
          role,
          revoke: !checked,
        },
      }).then(res => {
        if (res.code === 'C2000') {
          if (role === 'admin') {
            window.furball.success(
              `${checked ? 'Enabled' : 'Disabled'} Manage permissions at ${
                site.name
              }`,
            )
          } else if (role === 'viewer') {
            window.furball.success(
              `${checked ? 'Enabled' : 'Disabled'} View permissions at ${
                site.name
              }`,
            )
          }

          setSitePermissions(state => {
            const stateWithoutCurrentSite = state.filter(s => {
              return s.siteId !== site.id
            })

            if (checked) {
              return [
                ...stateWithoutCurrentSite,
                {
                  siteId: site.id,
                  siteName: site.name,
                  roles: {
                    editor: role === 'admin' ? ['all'] : null,
                    viewer: role === 'viewer' ? ['all'] : null,
                  },
                },
              ]
            } else {
              return stateWithoutCurrentSite
            }
          })
        }
      })
    },
    [employee, post],
  )

  return (
    <Dialog
      open={!!employee}
      onClose={onClose}
      fullHeight={false}
      maxWidth="sm"
    >
      <DialogTitle>Edit roles for {employee.userName}</DialogTitle>
      <DialogContent>
        <Accordion expanded={false} disabled={loading}>
          <AccordionSummary>
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="auto" id="company-admin-switch-label">
                <Typography color="textPrimary">Company admin</Typography>
                <Typography variant="body2" color="textSecondary">
                  Full access to manage people, roles, sites and more within{' '}
                  {company.name} on all NAEVA systems
                </Typography>
              </Box>
              <Box>
                <Switch
                  checked={isCompanyAdmin}
                  onChange={toggleCompanyAdmin}
                  classes={{ root: classes.switch }}
                  aria-labelledby="company-admin-switch-label"
                  color="primary"
                />
              </Box>
            </Box>
          </AccordionSummary>
        </Accordion>
        <Accordion
          expanded={sitePanelOpen}
          disabled={isCompanyAdmin || loading}
          onChange={(e, expanded): void => {
            setSitePanelOpen(expanded)
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="sites-accordion-content"
            id="sites-accordion-header"
          >
            <Box display="flex" alignItems="center" width="100%">
              <Box flex="auto">
                <Typography color="textPrimary">
                  Site admin
                  {sitePermissions?.length === 1
                    ? ' (1 site)'
                    : sitePermissions?.length > 1
                    ? ` (${sitePermissions.length} sites)`
                    : ''}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {`Select sites you will give ${employee.userName} access to "manage"
                  or "view"`}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Alert variant="outlined" severity="info">
              <Typography>Manage</Typography>
              <Typography color="textSecondary">
                {`Edit geofence, worker requirements, approve users and companies, add/remove other admins and more.`}
              </Typography>
              <br />

              <Typography>View</Typography>
              <Typography color="textSecondary">
                Can access everything a manager has access to, only with read
                permissions.
              </Typography>
            </Alert>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Box>
                <ManageAccessIcon fontSize="small" />
              </Box>
              <Box ml={3} mr={2}>
                <ViewAccessIcon fontSize="small" />
              </Box>
            </Box>
            <List dense>
              {sites &&
                Object.values(sites).map(site => {
                  return (
                    <ListItem key={site.id}>
                      <ListItemText
                        primary={`${site.name} ${
                          site.archived ? '(archived)' : ''
                        }`}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={(e): void => {
                            handleCheckbox(site, 'admin', e.target.checked)
                          }}
                          color="primary"
                          checked={sitePermissions.some(
                            s => s.siteId === site.id && s.roles.editor,
                          )}
                          inputProps={{
                            'aria-label': `Manage site ${site.name}`,
                          }}
                        />

                        <Box component="span" mx={1} />

                        <Checkbox
                          edge="end"
                          onChange={(e): void => {
                            handleCheckbox(site, 'viewer', e.target.checked)
                          }}
                          color="primary"
                          checked={sitePermissions?.some(
                            s => s.siteId === site.id && s.roles.viewer,
                          )}
                          inputProps={{
                            'aria-label': `View site ${site.name}`,
                          }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </List>
          </AccordionDetails>
        </Accordion>
        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(EditEmployee)
