import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { CssBaseline } from '@material-ui/core'
import {
  createTheme,
  StylesProvider,
  ThemeProvider as MUIThemeProvider,
} from '@material-ui/core/styles'

//
import App from 'components/App/index'
import store from './state'
import history from './state/history'
import ContextProvider from './state/context/ContextProvider'
import { muiTheme } from './styles/theme'

const MUITheme = createTheme(muiTheme)

ReactDOM.render(
  <MUIThemeProvider theme={MUITheme}>
    <StylesProvider injectFirst>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ContextProvider>
            <CssBaseline />
            <App />
          </ContextProvider>
        </ConnectedRouter>
      </Provider>
    </StylesProvider>
  </MUIThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
