import React, { ReactElement, useCallback, useMemo } from 'react'

// Material UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak'

// Common
import GMap, { IMapContainer } from 'components/common/GoogleMap'
import { useContextDispatch } from 'components/common/GoogleMap/context'
import { useContext as useStepperContext } from 'components/common/Stepper/context'

//
import { ISelectSiteCenter } from './types'

const SelectSiteCenter = (props: ISelectSiteCenter): ReactElement => {
  const { placeId, enablePolygon } = props

  const { activeStep } = useStepperContext()
  const dispatch = useContextDispatch()

  const polygon = useMemo<IMapContainer['polygon']>(() => {
    return {
      visible: enablePolygon,
    }
  }, [enablePolygon])

  const onMapDragEnd = useCallback((): void => {
    if (activeStep === 0) {
      dispatch('RESET_POLYGON')
    }
  }, [activeStep, dispatch])

  return (
    <Box pb={10} pt={2}>
      {activeStep === 0 ? (
        <Box mt={2} mb={1}>
          <Typography color="textSecondary">
            Is this the center of the site? Move the site to the center.
          </Typography>
        </Box>
      ) : null}
      {activeStep === 1 ? (
        <Typography color="textSecondary">
          Drag the dots to create an outline (geofence) of the site. The
          geofence is used to keep track of people at the site and automatically
          check workers in/out (if enabled).
        </Typography>
      ) : null}
      <Box style={{ position: 'relative' }}>
        <GMap
          placeId={placeId}
          polygon={polygon}
          showToolbar={activeStep === 1}
          onMapDragEnd={onMapDragEnd}
        />
        {activeStep === 0 ? (
          <CenterFocusWeakIcon
            style={{
              fontSize: '10rem',
              color: 'rgba(0,0,0,0.4)',
              margin: 0,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              pointerEvents: 'none',
            }}
          />
        ) : null}
      </Box>
    </Box>
  )
}

export default SelectSiteCenter
