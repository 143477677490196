import React, { ReactElement } from 'react'

// Material-UI
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

//
import { IElevationScroll } from './types'

const ElevationScroll = (props: IElevationScroll): ReactElement => {
  const { children } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // Note: Add `target` to target another node
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

export default ElevationScroll
