import { useCallback } from 'react'
import { useAuthUserContext } from 'state/context/authUserContext'

export const useSystemPolicy = (): IUseSystemPolicy => {
  const authUser = useAuthUserContext()

  const systemPolicy: IUseSystemPolicy['systemPolicy'] = useCallback(() => {
    return {
      accessSystemPreferences: (): boolean => {
        return !!authUser?.userPermissions?.naeva.systemAdmin
      },

      /**
       * Can switch company (represent another company)
       */
      switchCompany: (): boolean => {
        if (authUser?.userPermissions?.naeva.systemAdmin) return true
        return false
      },
    }
  }, [authUser])

  return { systemPolicy }
}

interface IUseSystemPolicy {
  systemPolicy: () => {
    accessSystemPreferences: () => boolean
    switchCompany: () => boolean
  }
}
