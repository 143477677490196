import React, { ReactElement, useState, useEffect } from 'react'

// Material-UI
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import { IMachineDialog } from './types'
import AboutItem from './MachineDetails'
import BookingForm from './Form'
import BookingConfirm from './Confirm'
import BookingContextProvider, {
  useBookingContext,
  useBookingContextActions,
} from 'sections/booking/context/bookingContext/useBookingContext'
import { useStyles } from './styles'
import { steps, stepName, stepIndexByName } from './stepper'

const MachineDialog = (props: IMachineDialog): ReactElement => {
  const { onClose, machine, booking, step } = props

  const [activeStep, setActiveStep] = useState(-1)
  const [completed, setCompleted] = useState(new Set<number>())
  const [open, setOpen] = useState(false)
  const [formIsValidated, setFormIsValidated] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)

  // Context
  const bookingContext = useBookingContext()
  const bookingContextActions = useBookingContextActions()

  // Hooks
  const classes = useStyles()

  /** Wrapper function to to close the dialog */
  const closeDialog = (): void => {
    onClose()
  }

  /**
   * Set next active step
   */
  const goNext = (): void => {
    setActiveStep(activeStep + 1)
  }

  /**
   * Set previous active step or close the dialog
   */
  const goBack = (): void => {
    if (stepName(activeStep) === 'DETAILS') {
      closeDialog()
    } else {
      setActiveStep(activeStep - 1)
    }
  }

  /**
   * Set step complete and go to next step
   */
  const setStepComplete = (): void => {
    const newCompleted = new Set(completed)
    newCompleted.add(activeStep)
    setCompleted(newCompleted)

    if (stepName(activeStep) !== 'CONFIRM') {
      goNext()
    }
  }

  /**
   * Handle the primary button
   */
  const handleComplete = (): void => {
    if (stepName(activeStep) === 'CONFIRM') {
      if (bookingContext?.doc?.completed) {
        closeDialog()
      } else {
        setIsWaiting(true)
        bookingContextActions
          .approveBooking(bookingContext?.doc?.id)
          .then(response => {
            if (response.code === 'C2000') {
              setStepComplete()
            }
          })
          .finally(() => {
            setIsWaiting(false)
          })
      }
    } else {
      setStepComplete()
    }
  }

  /**
   * Handle the stepper buttons
   */
  const handleStepButton = (index: number): void => {
    if (
      stepName(index) === 'CONFIRM' &&
      !bookingContext?.doc?.readyForApproval
    ) {
      // Disable. Do nothing.
    } else {
      setActiveStep(index)
    }
  }

  /** Return true if the step is completed */
  const isStepComplete = (step: number): boolean => {
    return completed.has(step)
  }

  /**
   * Set default step
   */
  useEffect(() => {
    if (machine) {
      setActiveStep(stepIndexByName(step))
    }
  }, [machine, step])

  /**
   * Set/reset state based on `machine`
   * Note: activeStep is not reset. This is to avoid step change while the dialog is closing
   */
  useEffect(() => {
    if (machine) {
      setOpen(true)
    } else {
      setOpen(false)
      setCompleted(new Set<number>())
      setFormIsValidated(false)
      bookingContextActions.resetState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machine])

  return (
    <Dialog
      open={!!open && activeStep > -1}
      onClose={closeDialog}
      fullHeight={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <>{machine?.name}</>
      </DialogTitle>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((step, index) => {
          return (
            <Step key={step.name}>
              <StepButton
                onClick={(): void => {
                  handleStepButton(index)
                }}
                disabled={
                  stepName(index) === 'CONFIRM' &&
                  (bookingContext?.doc?.completed || !formIsValidated)
                }
                completed={isStepComplete(index)}
              >
                {step.title}
              </StepButton>
            </Step>
          )
        })}
      </Stepper>

      <DialogContent
        classes={{ root: classes.dialogContent }}
        hidden={activeStep === 0 ? false : true}
      >
        <AboutItem machine={machine} />
      </DialogContent>

      <DialogContent
        classes={{ root: classes.dialogContent }}
        hidden={activeStep === 1 ? false : true}
      >
        {machine && (
          <BookingForm
            machine={machine}
            booking={booking}
            open={activeStep === 1 ? true : false}
            setFormIsValidated={setFormIsValidated}
          />
        )}
      </DialogContent>

      {activeStep === 2 ? (
        <DialogContent classes={{ root: classes.dialogContent }}>
          <BookingConfirm />
        </DialogContent>
      ) : (
        <div />
      )}

      <DialogActions>
        {!bookingContext?.doc?.completed ? (
          <Button onClick={goBack}>
            {stepName(activeStep) === 'DETAILS'
              ? isStepComplete(2)
                ? 'Close'
                : 'Cancel'
              : 'Back'}
          </Button>
        ) : null}
        <Button
          color="primary"
          onClick={handleComplete}
          disabled={
            isWaiting ||
            (stepName(activeStep) === 'FORM' &&
              (!bookingContext?.doc?.readyForApproval || !formIsValidated))
          }
          isWaiting={isWaiting}
        >
          {bookingContext?.doc?.completed ? (
            'Done'
          ) : (
            <>
              {stepName(activeStep) === 'CONFIRM' ? 'Confirm booking' : 'Next'}
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const MachineDialogWithContext = (props: IMachineDialog): ReactElement => {
  return (
    <BookingContextProvider>
      <MachineDialog {...props} />
    </BookingContextProvider>
  )
}

export default MachineDialogWithContext
