import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Material-UI
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CompanyIcon from '@material-ui/icons/Business'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import EmployeeRolesIcon from '@material-ui/icons/HowToReg'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import Badge from '@material-ui/core/Badge'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'
import ConfigCodeIcon from '@naevaas/webtastic/dist/icons/CodeLock'
import LogOutIcon from '@naevaas/webtastic/dist/icons/LogOut'
import ConditionalWrapper from '@naevaas/webtastic/dist/components/ConditionalWrapper'

//
import { IUserProfile } from './types'
import nameToInitials from 'utils/nameToInitials'
import EmployeeRolesDialog from 'components/views/EmployeeRolesDialog'
import ConfigCodeDialog from 'components/views/ConfigCodeDialog'
import SwitchCompanyDialog from 'components/views/SwitchCompanyDialog'
import { useCompanyPolicy } from 'hooks/policies/useCompanyPolicy'
import { useConfigCodePolicy } from 'hooks/policies/useConfigCodePolicy'
import { useSystemPolicy } from 'hooks/policies/useSystemPolicy'
import { useAuthContextActions } from 'state/context/authContext'
import { useAuthUserContext } from 'state/context/authUserContext'
import { Modals } from 'constants/routing'
import {
  useContext as useCompanyContext,
  useContextDispatch as useCompanyDispatch,
} from 'state/context/companyContext'
import { useStyles } from './styles'
import { Routes } from 'constants/routing'

const UserProfile = (__props: IUserProfile): ReactElement => {
  const [userProfileMenuEl, setUserProfileMenuEl] =
    useState<null | HTMLElement>(null)

  const [openDialog, setOpenDialog] = useState<
    Modals.EMPLOYEE_ROLES | Modals.CONFIG_CODE | Modals.SWITCH_COMPANY | null
  >(null)

  // Context
  const authActions = useAuthContextActions()
  const authUser = useAuthUserContext()
  const { defaultCompany, selectedCompany } = useCompanyContext()
  const companyDispatch = useCompanyDispatch()
  const { companyPolicy } = useCompanyPolicy()
  const { systemPolicy } = useSystemPolicy()
  const { configCodePolicy } = useConfigCodePolicy()

  // Hooks
  const classes = useStyles()
  const history = useHistory()

  const userProfileClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setUserProfileMenuEl(event.currentTarget)
  }

  const closeUserProfileMenu = (): void => {
    setUserProfileMenuEl(null)
  }

  if (!selectedCompany || !authUser) return <></>

  return (
    <>
      <Button
        className={classes.userProfileButton}
        aria-controls="user-profile-menu"
        aria-haspopup="true"
        aria-label="Open user menu"
        onClick={userProfileClick}
        disableRipple
      >
        <Box pl={'6px'} pr={3}>
          <ConditionalWrapper
            condition={!!authUser.userPermissions?.naeva.systemAdmin}
            wrapper={(children) => (
              <Badge
                classes={{ badge: classes.avatarBadge }}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={'SA'}
              >
                {children}
              </Badge>
            )}
          >
            <Avatar
              src={authUser?.imageThumbUrl || authUser?.imageUrl}
              alt={authUser?.name || ''}
              style={{ width: '44px', height: '44px', margin: 'auto' }}
              checkedIn
            >
              {nameToInitials(authUser?.name || '')}
            </Avatar>
          </ConditionalWrapper>
        </Box>
        <Box pr={1} style={{ minWidth: 0 }}>
          <Box display="flex" flexDirection="column" style={{ minWidth: 0 }}>
            <Box display="flex">
              <Typography
                className={classes.textOverflow}
                component="span"
                variant="body1"
              >
                {authUser?.name}
              </Typography>{' '}
              <ExpandMoreIcon color="disabled" />
            </Box>

            <Typography
              className={classes.textOverflow}
              component="span"
              variant="body1"
              color="textSecondary"
            >
              {selectedCompany.company.name}
            </Typography>
          </Box>
        </Box>
      </Button>

      <Menu
        id="user-profile-menu"
        anchorEl={userProfileMenuEl}
        PopoverClasses={{ paper: classes.userProfileMenu }}
        keepMounted
        open={Boolean(userProfileMenuEl)}
        onClose={closeUserProfileMenu}
      >
        <MenuItem disabled>
          <Typography
            className={classes.textOverflow}
            component="span"
            variant="body1"
            color="textSecondary"
          >
            {selectedCompany.company.name}
          </Typography>
        </MenuItem>

        {companyPolicy().update(selectedCompany.company) && (
          <MenuItem
            onClick={(): void => {
              setOpenDialog(Modals.EMPLOYEE_ROLES)
              closeUserProfileMenu()
            }}
          >
            <ListItemIcon>
              <EmployeeRolesIcon />
            </ListItemIcon>
            <Typography variant="inherit">Employee roles</Typography>
            {openDialog === Modals.EMPLOYEE_ROLES && (
              <EmployeeRolesDialog
                onClose={(): void => {
                  setOpenDialog(null)
                }}
              />
            )}
          </MenuItem>
        )}

        {configCodePolicy().create() && (
          <MenuItem
            onClick={(): void => {
              setOpenDialog(Modals.CONFIG_CODE)
              closeUserProfileMenu()
            }}
          >
            <ListItemIcon>
              <ConfigCodeIcon />
            </ListItemIcon>
            <Typography variant="inherit">Config code</Typography>
            {openDialog === Modals.CONFIG_CODE && (
              <ConfigCodeDialog
                onClose={(): void => {
                  setOpenDialog(null)
                }}
              />
            )}
          </MenuItem>
        )}

        {systemPolicy().switchCompany() && (
          <div>
            <Divider />
            <MenuItem disabled>
              <Typography
                className={classes.textOverflow}
                component="span"
                variant="body1"
                color="textSecondary"
              >
                Companies
              </Typography>
            </MenuItem>

            <MenuItem
              disabled={
                defaultCompany?.company.id === selectedCompany?.company.id
              }
              onClick={(): void => {
                companyDispatch('SELECT_DEFAULT')
                history.push(Routes.SITES)
                //closeUserProfileMenu()
              }}
            >
              <ListItemIcon>
                <CompanyIcon />
              </ListItemIcon>
              <Typography variant="inherit">
                {defaultCompany?.company.name}
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={(): void => {
                setOpenDialog(Modals.SWITCH_COMPANY)
                closeUserProfileMenu()
              }}
            >
              <ListItemIcon />

              <Typography variant="inherit">Manage other company</Typography>
              {openDialog === Modals.SWITCH_COMPANY && (
                <SwitchCompanyDialog
                  onClose={(): void => {
                    setOpenDialog(null)
                  }}
                />
              )}
            </MenuItem>
          </div>
        )}

        <Divider />
        <MenuItem
          onClick={(): void => {
            closeUserProfileMenu()
            authActions.logOut()
          }}
        >
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          <Typography variant="inherit">Sign out</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserProfile
