import inRange from 'lodash/inRange'
import { firestore } from 'firebase/app'
import { Timestamp } from '@google-cloud/firestore'

export const nowInRange = (
  timestampStart: Timestamp | null,
  timestampEnd: Timestamp | null,
): boolean => {
  if (timestampStart && timestampEnd) {
    return inRange(
      Date.now(),
      timestampStart?.toMillis() || 0,
      timestampEnd?.toMillis() || 0,
    )
  }

  return false
}

export const dateToFirestoreTimestamp = (date: Date): Timestamp => {
  return firestore.Timestamp.fromDate(date)
}

export const timeRestrictionIsValid = (
  start: Timestamp | null,
  end: Timestamp | null,
): boolean => {
  if (start) {
    return !nowInRange(start, end)
  }

  return true
}
