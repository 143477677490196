import { useCallback } from 'react'

//
import { useAuthUserContext } from 'state/context/authUserContext'
import { timeRestrictionIsValid } from 'utils/firebaseTimestamp'

interface IUseSitePolicy {
  sitePolicy: () => {
    accessSiteDashboard: () => boolean
    allowBooking: (site: IFirestoreSite) => boolean
    allowAdmin: (
      site: IFirestoreSite,
      permissionLevel: 'manage' | 'view',
    ) => boolean
    allowEntry: (
      site: IFirestoreSite,
      permissionLevel: 'worker' | 'guest',
    ) => boolean
    create: () => boolean
    view: (site: IFirestoreSite) => boolean
    update: (site: IFirestoreSite) => boolean
    archive: (site: IFirestoreSite) => boolean
  }
}

export const useSitePolicy = (): IUseSitePolicy => {
  const authUser = useAuthUserContext()

  /**
   * Override policy if user is systemAdmin
   */
  const before = useCallback(() => {
    if (authUser?.userPermissions?.naeva.systemAdmin) return true

    return false
  }, [authUser])

  const sitePolicy: IUseSitePolicy['sitePolicy'] = useCallback(() => {
    return {
      /**
       * Can access SiteDashboard
       */
      accessSiteDashboard: (): boolean => {
        if (before()) return true

        // ...if the user is company admin, company owner or admin (manager/viewer) on any site
        if (
          authUser?.isCompanyAdmin ||
          authUser?.isSuperUser ||
          authUser?.isSiteAdmin
        ) {
          return true
        }

        return false
      },

      /**
       * Can see and create bookings on the site
       */
      allowBooking: (site): boolean => {
        if (before()) return true

        if (site?.archived) return false

        const permission =
          authUser?.userPermissions?.userSitePermissions?.[site.id] ??
          authUser?.companyPermissions?.companySitePermissions[site.id]

        if (
          timeRestrictionIsValid(
            permission?.timeRestrictionStart ?? null,
            permission?.timeRestrictionEnd ?? null,
          ) &&
          permission?.bookingAllow
        ) {
          return true
        }

        return false
      },

      /**
       * Can enter site (fysically)
       */
      allowEntry: (site, permissionLevel): boolean => {
        if (before()) return true

        const permission =
          authUser?.userPermissions?.userSitePermissions?.[site.id] ??
          authUser?.companyPermissions?.companySitePermissions[site.id]

        if (
          permission &&
          timeRestrictionIsValid(
            permission.timeRestrictionStart,
            permission.timeRestrictionEnd,
          ) &&
          permission?.entryAllow?.includes(permissionLevel)
        ) {
          return true
        }

        return false
      },

      /**
       * Can administer the site (manage or view)
       */
      allowAdmin: (site, permissionLevel): boolean => {
        if (before()) return true

        // ...if own company
        if (
          authUser?.company?.id &&
          authUser?.company?.id === site?.ownerCompany?.id
        ) {
          // ...and user is company owner or company admin
          if (authUser.isSuperUser || authUser.isCompanyAdmin) return true

          // ...or user is site admin (manager)
          if (permissionLevel === 'manage') {
            if (authUser.siteRoles?.[site.id]?.role === 'admin') {
              return true
            }
          } else if (permissionLevel === 'view') {
            // Return true if `view` or higher
            if (
              authUser.siteRoles?.[site.id]?.role === 'admin' ||
              authUser.siteRoles?.[site.id]?.role === 'viewer'
            ) {
              return true
            }
          }
        }

        return false
      },

      /**
       * Can create site
       */
      create: (): boolean => {
        if (before()) return true

        // ...if company owner or company admin
        if (authUser?.isSuperUser || authUser?.isCompanyAdmin) return true

        return false
      },

      /**
       * Can view the site (dashboard)
       */
      view: (site: IFirestoreSite): boolean => {
        if (before()) return true

        // ...if viewer role (or higher)
        if (sitePolicy().allowAdmin(site, 'view')) return true

        return false
      },

      /**
       * Can update the site
       */
      update: (site: IFirestoreSite): boolean => {
        if (before()) return true

        if (site?.archived) return false

        // ...if allowed to manage the site
        if (sitePolicy().allowAdmin(site, 'manage')) return true

        return false
      },

      /**
       * Can archive the site
       */
      archive: (site: IFirestoreSite): boolean => {
        if (before()) return true

        // ...if site is owned by authUsers company
        if (
          authUser?.company?.id &&
          authUser?.company.id === site.ownerCompany.id
        ) {
          // ...and the user is company admin
          if (authUser?.isSuperUser || authUser?.isCompanyAdmin) return true
        }

        return false
      },

      /*
      delete: (item: any): boolean => {
        // rules...
        if (before()) return true
        return false
      }, */
    }
  }, [authUser, before])

  return { sitePolicy }
}
