import React, { ReactElement } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

// Webtastic
import EmptyState from '@naevaas/webtastic/dist/components/EmptyState'

//
import { useContextDispatch } from '../../context'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.error.main,
  },
}))

const Error = (): ReactElement => {
  const dispatch = useContextDispatch()
  const classes = useStyles()

  const retry = () => {
    dispatch('RESET_SELECTED_FILE')
  }

  return (
    <EmptyState
      secondaryText="The upload failed. Please try again or contact support."
      icon={<ErrorIcon classes={{ root: classes.icon }} />}
      actions={<Button onClick={retry}>Try again</Button>}
    />
  )
}

export default Error
