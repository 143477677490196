export default function nameToInitials(fullName: string): string {
  if (!fullName) return 'ツ'

  const nameArr = fullName.split(' ')
  if (nameArr.length === 1) return `${nameArr[0].charAt(0).toUpperCase()}`
  else
    return `${nameArr[0].charAt(0)}${nameArr[nameArr.length - 1].charAt(
      0,
    )}`.toUpperCase()
}
