import { useCallback } from 'react'

//
import { useAuthUserContext } from 'state/context/authUserContext'

export const useCompanyPolicy = (): IUseCompanyPolicy => {
  const authUser = useAuthUserContext()

  /**
   * Override policy if user is systemAdmin
   */
  const before = useCallback(() => {
    if (authUser?.userPermissions?.naeva.systemAdmin) return true

    return false
  }, [authUser])

  const companyPolicy: IUseCompanyPolicy['companyPolicy'] = useCallback(() => {
    return {
      /**
       * Can update company
       */
      update: (company): boolean => {
        if (before()) return true

        return !!(
          companyPolicy().isOwner(company) || companyPolicy().isEditor(company)
        )
      },

      /**
       * AuthUser is owner of the company
       */
      isOwner: (company): boolean => {
        if (before()) return true

        const p = authUser?.userPermissions?.userCompanyPermissions[company.id]

        return !!p?.owner
      },

      /**
       * AuthUser is editor of the company
       */
      isEditor: (company): boolean => {
        if (before()) return true

        const p = authUser?.userPermissions?.userCompanyPermissions[company.id]

        return !!p?.editor?.includes('all')
      },
    }
  }, [authUser, before])

  return { companyPolicy }
}

interface IUseCompanyPolicy {
  companyPolicy: () => {
    update: (company: IFirestoreCompany) => boolean
    isOwner: (company: IFirestoreCompany) => boolean
    isEditor: (company: IFirestoreCompany) => boolean
  }
}
