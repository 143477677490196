import React, { useCallback, useState, useEffect } from 'react'

// Material UI
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

// Webtastic
import Autocomplete, {
  IAutocompleteOption,
} from '@naevaas/webtastic/dist/components/Autocomplete'

//
import { CrewListQueryParams, TimeRange } from 'state/actions/site'
import DatePicker from 'components/common/DatePicker'
import useDebounce from 'hooks/useDebounce'
import { useFetchAutoCompleteData } from './useFetchAutoCompleteData'
import { useContext as useSiteContext } from 'state/context/siteContext'

export const timeRangeMap: { [index: number]: TimeRange } = {
  0: 'Today',
  1: 'Yesterday',
  2: 'This week',
  3: 'This month',
  4: 'Custom',
  5: 'Now',
}

const defaultTimeRange = 0

function CrewListForm(props: IProps): React.ReactElement {
  const { fetch } = props

  const [selectedOption, setSelectedOption] = useState<IAutocompleteOption[]>(
    [],
  )
  const [autocompleteData, setAutocompleteData] = useState<
    IAutocompleteOption[]
  >([])
  const [keyword, setKeyword] = useState('')
  const [autocompleteIsFetching, setAutocompleteIsFetching] = useState(false)

  // Context
  const { selectedSite } = useSiteContext()
  const selectedSiteId = selectedSite?.id

  // Hooks
  const debouncedKeyword = useDebounce(keyword, 400)
  const fetchAutoCompleteData = useFetchAutoCompleteData()
  const [{ timeRange, timeRangeFrom, timeRangeTo }, setTimeRange] = useState<{
    timeRange: number
    timeRangeFrom: number | undefined
    timeRangeTo: number | undefined
  }>({
    timeRange: defaultTimeRange,
    timeRangeFrom: undefined,
    timeRangeTo: undefined,
  })

  // const handlePdfReport = () => {};

  useEffect(() => {
    selectedSiteId &&
      fetch({
        timeRange: timeRangeMap[defaultTimeRange],
        siteId: selectedSiteId,
      })
    return (): void => {
      //getCrewListData({ timeRange: timeRangeMap[4] }); // TODO: Why do we need this?
    }
  }, [fetch, selectedSiteId])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setKeyword(e.target.value)
    },
    [],
  )

  useEffect(() => {
    if (debouncedKeyword) {
      setAutocompleteIsFetching(true)
      fetchAutoCompleteData(debouncedKeyword)
        .then((options: IAutocompleteOption[]) => {
          setAutocompleteData(options)
        })
        .finally(() => {
          setAutocompleteIsFetching(false)
        })
    }
  }, [debouncedKeyword, fetchAutoCompleteData])

  const handleSelectionChange = useCallback(
    (selection: IAutocompleteOption[], _reason: string) => {
      if (selectedSiteId) {
        fetch({
          searchSelection: selection[0],
          timeRange: timeRangeMap[timeRange],
          timeRangeFrom,
          timeRangeTo,
          siteId: selectedSiteId,
        })
        setSelectedOption([selection[0]])
      }
    },
    [fetch, selectedSiteId, timeRange, timeRangeFrom, timeRangeTo],
  )

  const handleTimeRange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (selectedSiteId) {
        fetch({
          searchSelection: selectedOption[0],
          timeRange: timeRangeMap[event.target.value as number],
          siteId: selectedSiteId,
        })
        setTimeRange({
          timeRange: event.target.value as number,
          timeRangeFrom: undefined,
          timeRangeTo: undefined,
        })
      }
    },
    [fetch, selectedOption, selectedSiteId],
  )

  const handleTimeRangeFrom = useCallback(
    timeRangeFrom => {
      const currentTimeRangeFrom = timeRangeFrom ? +timeRangeFrom : undefined
      if (selectedSiteId && timeRangeTo)
        fetch({
          searchSelection: selectedOption[0],
          timeRangeFrom: currentTimeRangeFrom,
          timeRangeTo,
          siteId: selectedSiteId,
        })
      setTimeRange({
        timeRangeFrom: currentTimeRangeFrom,
        timeRange,
        timeRangeTo,
      })
    },
    [fetch, selectedOption, selectedSiteId, timeRange, timeRangeTo],
  )

  const handleTimeRangeTo = useCallback(
    timeRangeTo => {
      const currentTimeRangeTo = timeRangeTo ? +timeRangeTo : undefined
      if (selectedSiteId && timeRangeFrom)
        fetch({
          searchSelection: selectedOption[0],
          timeRangeTo: currentTimeRangeTo,
          timeRangeFrom,
          siteId: selectedSiteId,
        })
      setTimeRange({
        timeRangeTo: currentTimeRangeTo,
        timeRange,
        timeRangeFrom,
      })
    },
    [fetch, selectedOption, selectedSiteId, timeRange, timeRangeFrom],
  )

  return (
    <>
      <Box display="flex" alignItems="center" width="100%" py={3}>
        <Box minWidth={150}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel htmlFor="crew-list-time-range">Time range</InputLabel>
            <Select
              value={timeRange}
              onChange={handleTimeRange}
              label="Time range"
              inputProps={{
                id: 'crew-list-time-range',
                style: { width: '100%' },
              }}
            >
              <MenuItem value={0}>{timeRangeMap[0]}</MenuItem>
              <MenuItem value={1}>{timeRangeMap[1]}</MenuItem>
              <MenuItem value={2}>{timeRangeMap[2]}</MenuItem>
              <MenuItem value={3}>{timeRangeMap[3]}</MenuItem>
              <MenuItem value={4}>{timeRangeMap[4]}</MenuItem>
              <MenuItem value={5}>{timeRangeMap[5]}</MenuItem>
            </Select>
            {/* Hide due to issue #192
        <Button className={clsx([classes.filledBtn, classes.greenBtn, classes.addBtn])} color="default" onClick={handlePdfReport}>
          Generate pdf report
        </Button> */}
          </FormControl>
        </Box>

        <Box flex="auto" maxWidth={450} pl={3}>
          <FormControl style={{ width: '100%' }}>
            <Autocomplete
              onInputChange={handleInputChange}
              onSelectionChange={handleSelectionChange}
              multiple={false}
              options={autocompleteData}
              isFetching={autocompleteIsFetching}
              textFieldProps={{
                placeholder: `Search by company, name or HSE card number`,
              }}
            />
          </FormControl>
        </Box>
      </Box>
      {timeRange === 4 && (
        <Box display="flex" alignItems="center" width="100%" mb={3}>
          <Box>
            <DatePicker
              label="From"
              id="date-picker-from"
              value={timeRangeFrom ? timeRangeFrom : null}
              onChange={handleTimeRangeFrom}
            />
          </Box>
          <Box pl={3}>
            <DatePicker
              label="To"
              id="date-picker-to"
              value={timeRangeTo ? timeRangeTo : null}
              onChange={handleTimeRangeTo}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

interface IProps {
  fetch(queryParams?: CrewListQueryParams): void
}

export default CrewListForm
