import React from 'react'

// Material-UI
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import SvgIcon from '@material-ui/core/SvgIcon'

//
import { IMachineCard } from './types'
import { useStyles } from './styles'
import { ReactComponent as MissingImageSvg } from 'sections/booking/utils/images/excavator.svg'
import ConditionalWrapper from 'components/common/ConditionalWrapper'

const MachineCard = (props: IMachineCard): React.ReactElement => {
  const { image, title, subtitle, content, onClick, actions } = props
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <ConditionalWrapper
        condition={!!onClick}
        wrapper={(children): React.ReactElement => {
          return (
            <CardActionArea
              className={classes.cardActionArea}
              onClick={onClick}
            >
              {children}
            </CardActionArea>
          )
        }}
      >
        {image ? (
          <CardMedia
            component="img"
            height="160"
            image={image}
            aria-hidden="true"
          />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={160}
          >
            <SvgIcon
              component={MissingImageSvg}
              style={{ fontSize: '4rem', opacity: 0.3 }}
            />
          </Box>
        )}
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="div"
            className={classes.subtitle}
          >
            {subtitle || ''}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            component="div"
            className={classes.content}
          >
            {content || ''}
          </Typography>
        </CardContent>
      </ConditionalWrapper>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  )
}

export default MachineCard
