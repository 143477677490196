import { useState, useCallback } from 'react'

//
import { ISiteList } from 'types/site'
import { formatSiteData } from 'state/formatters/site'
import * as db from 'utils/firestoreDB'

const useFetchPreApprovedCompanySites = (): Response => {
  const [sites, setSites] = useState<{
    [siteId: string]: IFirestoreSite
  } | null>(null)
  const [isListening, setIsListening] = useState(false)

  const fetch = useCallback((props: P) => {
    const { companyId } = props

    const unsubscribe =
      companyId && !isListening
        ? db
            .sites()
            .where('archived', '==', false)
            .where(
              'activePreApprovedSubContractorIds',
              'array-contains',
              companyId,
            )
            .onSnapshot(function (response) {
              const sites: ISiteList = {}
              if (!response.empty) {
                response.docs.forEach((item) => {
                  const site = item.data()
                  sites[site.id] = formatSiteData(site)
                })
              }
              setSites(sites)
              setIsListening(true)
            })
        : (): void => {
            // Do nothing
          }

    // Unsubscribe on unmount
    return (): void => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [fetch, { sites }]
}

export default useFetchPreApprovedCompanySites

type P = {
  companyId: string
}

type Response = [
  (props: P) => void,
  {
    sites: ISiteList | null
  },
]
