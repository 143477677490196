import React, { useCallback } from 'react'

// Material UI
import PersonIcon from '@material-ui/icons/Person'
import CompanyIcon from '@material-ui/icons/Apartment'

// Webtastic
import { IAutocompleteOption } from '@naevaas/webtastic/dist/components/Autocomplete'

//
import { useCloudFunction } from 'hooks/useCloudFunctions'
import * as db from 'utils/firestoreDB'

export const useFetchAutoCompleteData = () => {
  const [searchUser] = useCloudFunction()

  const fetch = useCallback(
    async (inputValue: string): Promise<IAutocompleteOption[]> => {
      const value = inputValue.trim().toLowerCase()
      if (!value) {
        return []
      } else {
        const usersRes = await searchUser('searchUser', { searchString: value })
        const usersData = usersRes?.data?.users

        const companiesData = await db
          .companies()
          .where('searchName', 'array-contains', value)
          .limit(5)
          .get()

        const usersOptions = usersData?.length
          ? usersData.map(
              (user): IAutocompleteOption => {
                return {
                  id: user.id,
                  avatarUrl: user.imageThumbUrl || '',
                  avatarIcon: <PersonIcon />,
                  label: user.name,
                  primaryText: user.name,
                  secondaryText: user.companyName,
                  __typename: 'USER',
                }
              },
            )
          : []
        const companiesOptions = companiesData
          ? companiesData.docs.map(
              (item): IAutocompleteOption => {
                const { name, orgNr, id } = item.data()

                return {
                  id: id,
                  avatarIcon: <CompanyIcon />,
                  label: name,
                  primaryText: name,
                  secondaryText: orgNr,
                  __typename: 'COMPANY',
                }
              },
            )
          : []
        return [...companiesOptions, ...usersOptions]
      }
    },
    [searchUser],
  )

  return fetch
}
