import React, { ReactElement } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import MuiListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import ViewAccessIcon from '@material-ui/icons/Visibility'
import ManageAccessIcon from '@material-ui/icons/Create'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'

//
import { IListItemText } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
}))

const ListItemText = (props: IListItemText): ReactElement => {
  const { employee } = props
  const classes = useStyles()
  return (
    <MuiListItemText
      primary={employee.userName}
      secondary={
        <Box width="100%" paddingRight={2}>
          {!!employee.companyPermission?.editor && (
            <Typography component="div" variant="body2" color="primary">
              Company admin
            </Typography>
          )}
          {employee.sitePermissions && !employee.companyPermission?.editor && (
            <>
              Site admin at{' '}
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
                style={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  hyphens: 'auto',
                  lineHeight: '2em',
                }}
              >
                {employee.sitePermissions.map(site => {
                  return (
                    <Chip
                      key={site.siteId}
                      className={classes.chip}
                      variant="outlined"
                      size="small"
                      label={site.siteName}
                      avatar={
                        <Avatar>
                          {!!site.roles.viewer ? (
                            <ViewAccessIcon style={{ fontSize: 14 }} />
                          ) : (
                            <ManageAccessIcon style={{ fontSize: 12 }} />
                          )}
                        </Avatar>
                      }
                    />
                  )
                })}
              </Typography>
            </>
          )}
        </Box>
      }
      secondaryTypographyProps={{ component: 'div' }}
    />
  )
}

export default ListItemText
