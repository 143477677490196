import React, { ReactElement, useEffect } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

//
import { IBookingRoot } from './types'
import Navigation from 'components/common/Navigation'
import { useAuthUserContext } from 'state/context/authUserContext'
import { Routes } from 'constants/routing'
import MenuDrawer from 'components/views/MenuDrawer'
import PageNavigation from 'sections/booking/components/PageNavigation'
import Machines from '../pages/Machines'
import MyBookings from '../pages/MyBookings'
import AuthLayout from 'components/layout/AuthLayout'
import { useBookPolicy } from 'hooks/policies/useBookPolicy'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import { ISiteList } from 'types/site'
import SelectedSiteContextProvider, {
  useSelectedSiteContext,
  useSelectedSiteActions,
} from 'state/context/selectedSiteContext'

const BookingRoot = (props: IBookingRoot): ReactElement => {
  const {
    match: {
      params: { siteId, page },
    },
    history,
  } = props

  const authUser = useAuthUserContext()
  const selectedSite = useSelectedSiteContext()
  const setSelectedSite = useSelectedSiteActions()
  const { bookPolicy } = useBookPolicy()
  const { sitePolicy } = useSitePolicy()

  const bookingSites: ISiteList = {}
  if (bookPolicy().accessBooking() && authUser?.sites) {
    Object.values(authUser.sites).forEach(site => {
      if (sitePolicy().allowBooking(site)) {
        bookingSites[site.id] = site
      }
    })
  }

  /**
   * Handle selected site
   */
  useEffect(() => {
    if (!siteId) {
      history.replace(
        `${Routes.BOOKING}/${Object.keys(bookingSites)[0]}/machines`,
      )
    } else if (siteId && siteId !== selectedSite?.id) {
      // Set site from params (router)
      const site = bookingSites[siteId] || undefined
      if (site) {
        localStorage.setItem('bookingSelectedSite', siteId)
        setSelectedSite(site)
      } else {
        // invalid site. Let the user select
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, selectedSite])

  const navigate = (path: string): void => {
    history.push(`${Routes.BOOKING}${path}`)
  }

  if (
    !Object.keys(bookingSites).length ||
    !Object.keys(bookingSites).includes(siteId)
  ) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
        width="100%"
      >
        <Box textAlign="center">
          <Typography variant="h1">404</Typography>
          <Box mb={3}>
            <Typography variant="h5">{`There's nothing here...`}</Typography>
          </Box>
          <Typography>
            {`Maybe the page you're looking for is not found or was never
            constructed.`}
          </Typography>
          <Box pt={3}>
            <Button
              color="primary"
              variant="contained"
              component={'a'}
              onClick={(): void => {
                history.replace('/booking')
              }}
            >
              Back to Booking
            </Button>
          </Box>
        </Box>
      </Box>
    )
  } else {
    let bgImageSrc = ''
    let bgMode = '' as 'dark' | 'light'
    if (selectedSite?.mapImageDarkModeUrl) {
      bgImageSrc = `${selectedSite.mapImageDarkModeUrl}&${selectedSite.updatedTimestamp}`
      bgMode = 'dark'
    } else if (selectedSite?.mapImageUrl) {
      bgImageSrc = `${selectedSite.mapImageUrl}&${selectedSite.updatedTimestamp}`
      bgMode = 'light'
    }

    return (
      <AuthLayout
        bgImageSrc={bgImageSrc}
        bgMode={bgMode || undefined}
        menuComponent={
          <MenuDrawer appSection={'booking'}>
            <div />
          </MenuDrawer>
        }
        contentComponent={
          <Box
            display="flex"
            flex="auto"
            flexDirection="column"
            alignItems="center"
            height="100vh"
            overflow="auto"
          >
            <Box
              display="flex"
              alignItems="flex-start"
              maxWidth="1000px"
              width="100%"
              px={1}
              py={3}
            >
              <Navigation
                allSites={bookingSites}
                selectedSite={selectedSite}
                onSelectSite={(siteId: string): void => {
                  navigate(`/${siteId}/${page ?? 'machines'}`)
                }}
              />
            </Box>

            <Box flex="auto" maxWidth="1000px" width="100%" px={2}>
              <PageNavigation
                page={page}
                onChange={(page): void => {
                  navigate(`/${siteId}/${page}`)
                }}
              />
              {page && (
                <>{page === 'machines' && <Machines siteId={siteId} />}</>
              )}
              {page && (
                <>{page === 'my-bookings' && <MyBookings siteId={siteId} />}</>
              )}
            </Box>
          </Box>
        }
      />
    )
  }
}

const BookingRootWithContext = (props: IBookingRoot): ReactElement => {
  return (
    <SelectedSiteContextProvider>
      <BookingRoot {...props} />
    </SelectedSiteContextProvider>
  )
}

export default BookingRootWithContext
