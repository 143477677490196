import React from 'react'

//
import AuthContextProvider from '../authContext'
import AuthUserContextProvider from '../authUserContext'
import AuthUserOwnDataContextProvider from '../authUserOwnDataContext'
//import ChatListContextProvider from '../chatListContext'
import MenuDrawerContextProvider from '../menuDrawerContext'
import CompanyContextContainer from './CompanyContextContainer'

function ContextProvider(props: P): React.ReactElement {
  const { children } = props

  return (
    <AuthContextProvider>
      <AuthUserContextProvider>
        <AuthUserOwnDataContextProvider>
          <CompanyContextContainer>
            {/* <ChatListContextProvider> */}
            <MenuDrawerContextProvider>{children}</MenuDrawerContextProvider>
            {/* </ChatListContextProvider> */}
          </CompanyContextContainer>
        </AuthUserOwnDataContextProvider>
      </AuthUserContextProvider>
    </AuthContextProvider>
  )
}

export default ContextProvider

type P = {
  children: React.ReactNode
}
