import React, { ReactElement, useEffect, useCallback } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import MeetingRoom from '@material-ui/icons/MeetingRoom'

//
import { IGateKeeper } from './types'
import useGateKeeperSubscription from './hooks/useGateKeeperSubscription'
import useUpdateWhitelist from './hooks/useUpdateWhitelist'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  switch: {
    marginRight: '-31px',
  },
}))

const GateKeeper = (props: IGateKeeper): ReactElement => {
  const { siteId, userIds, setIsUpdating } = props

  const [subscribe, { gates }] = useGateKeeperSubscription()
  const update = useUpdateWhitelist()
  const classes = useStyles()

  const handleToggle = useCallback(
    (props: { gateKeeperId: string; userIds: string[]; checked: boolean }) => {
      const { gateKeeperId, userIds, checked } = props
      setIsUpdating(true)
      update({
        gateKeeperId,
        userIds,
        action: checked ? 'ADD' : 'REMOVE',
      }).finally(() => {
        setIsUpdating(false)
      })
    },
    [setIsUpdating, update],
  )

  useEffect(() => {
    subscribe({ siteId })
  }, [subscribe, siteId])

  return (
    <Box flexDirection="column">
      {gates && Object.keys(gates).length ? (
        <List>
          {Object.values(gates).map((gate) => {
            // Checked = true if all users are present in whitelist
            const checked = userIds.every((v) => {
              return gate.whiteList.includes(v)
            })

            return (
              <ListItem key={gate.id}>
                <ListItemIcon>
                  <MeetingRoom />
                </ListItemIcon>
                <ListItemText
                  id={`switch-list-label-${gate.id}`}
                  primary={gate.displayName}
                />
                <ListItemSecondaryAction>
                  {gate.mode === 'siteAccess' ? (
                    <Switch
                      edge="end"
                      color="primary"
                      checked
                      disabled
                      classes={{ root: classes.switch }}
                    />
                  ) : (
                    <Switch
                      edge="end"
                      color="primary"
                      classes={{ root: classes.switch }}
                      onChange={() =>
                        handleToggle({
                          gateKeeperId: gate.id,
                          userIds,
                          checked: !checked,
                        })
                      }
                      checked={checked}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No doors with Gatekeeper found.
        </Typography>
      )}
    </Box>
  )
}

export default GateKeeper
