import React, { ReactElement } from 'react'

// Material-UI
import Button from '@material-ui/core/Button'

// Webtastic
import Dialog, {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@naevaas/webtastic/dist/components/Dialog'

//
import { IErrorDialog } from './types'
import { useContext, useContextDispatch } from '../context'

const ErrorDialog = (__props: IErrorDialog): ReactElement => {
  const { requestErrors } = useContext()
  const dispatch = useContextDispatch()

  const open = !!requestErrors

  const handleClose = () => {
    open && dispatch('CLEAR_REQUEST_ERRORS')
  }

  return (
    <Dialog open={open} fullHeight={false} maxWidth="sm" onClose={handleClose}>
      <DialogTitle>Error!</DialogTitle>
      <DialogContent>
        Could not save the site. Please make sure all required fields are filled
        in correctly and try again. If the problem continues, please contact
        support.
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
