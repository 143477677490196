import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import has from 'lodash/has'

// Material UI
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import BookingIcon from '@material-ui/icons/Event'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//
import {
  useMenuDrawerContext,
  useMenuDrawerContextActions,
} from 'state/context/menuDrawerContext'
import Logo from 'components/common/graphics/logo'
import LogoSmall from 'components/common/graphics/logoSmall'
import { Routes } from 'constants/routing'
import { useAuthUserContext } from 'state/context/authUserContext'
import { useStyles, logoHeight } from './styles'
import { useBookPolicy } from 'hooks/policies/useBookPolicy'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import { useSystemPolicy } from 'hooks/policies/useSystemPolicy'
import UserProfile from './UserProfile'

function MenuDrawer(props: TProps): React.ReactElement {
  const { children, appSection } = props

  // Context
  const { open } = useMenuDrawerContext()
  const { setOpen } = useMenuDrawerContextActions()
  const authUser = useAuthUserContext()

  // Hooks
  const { bookPolicy } = useBookPolicy()
  const { sitePolicy } = useSitePolicy()
  const { systemPolicy } = useSystemPolicy()

  const sm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const classes = useStyles()

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  return (
    <div>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        elevation={10}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box textAlign="center" mt={2} pl={!open ? '2px' : 'inherit'}>
            <div className={classes.logosOuterContainer}>
              <div
                className={`${classes.logoContainer} ${
                  !open ? classes.logoHide : classes.logoShow
                }`}
              >
                <Logo size={logoHeight} />
              </div>
              <div
                className={`${classes.logoContainer} ${
                  open ? classes.logoHide : classes.logoShow
                }`}
              >
                <LogoSmall size={logoHeight} />
              </div>
            </div>
          </Box>
          <Box pt={2}>
            <UserProfile />
            <Box my={1}>
              <List>
                {sitePolicy().accessSiteDashboard() && (
                  <Tooltip
                    title={!open ? 'Site Dashboard' : ''}
                    placement="left"
                    arrow={true}
                  >
                    <ListItem
                      button
                      component={Link}
                      to={Routes.SITE}
                      selected={appSection === 'site' ? true : undefined}
                      onClick={(): void => {
                        sm && handleDrawerClose()
                      }}
                    >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Site Dashboard'} />
                    </ListItem>
                  </Tooltip>
                )}

                {systemPolicy().accessSystemPreferences() && (
                  <Tooltip
                    title={!open ? 'System Dashboard' : ''}
                    placement="left"
                    arrow={true}
                  >
                    <ListItem
                      button
                      component={Link}
                      to={Routes.SYSTEM}
                      selected={appSection === 'system' ? true : undefined}
                      onClick={(): void => {
                        sm && handleDrawerClose()
                      }}
                    >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary={'System Dashboard'} />
                    </ListItem>
                  </Tooltip>
                )}

                {bookPolicy().accessBooking() && (
                  <Tooltip
                    title={!open ? 'Booking' : ''}
                    placement="left"
                    arrow={true}
                  >
                    <ListItem
                      button
                      component={Link}
                      to={`${Routes.BOOKING}${
                        has(
                          authUser?.sites,
                          localStorage.getItem('bookingSelectedSite') || '',
                        )
                          ? `/${localStorage.getItem(
                              'bookingSelectedSite',
                            )}/machines`
                          : ''
                      }`}
                      selected={appSection === 'booking' ? true : undefined}
                      onClick={(): void => {
                        sm && handleDrawerClose()
                      }}
                    >
                      <ListItemIcon>
                        <BookingIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Booking'} />
                    </ListItem>
                  </Tooltip>
                )}
              </List>
            </Box>
          </Box>
          <Divider />

          <Box
            flex="auto"
            className={`styled-scrollbar ${classes.mainSection}`}
            style={{ overflowY: 'hidden' }}
          >
            {children}
          </Box>

          <Box textAlign="right" paddingRight={'3.5px'}>
            <Divider />
            {open ? (
              <Tooltip title={'Minimize menu'} placement="right" arrow>
                <IconButton
                  aria-label="Minimize menu"
                  onClick={handleDrawerClose}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={'Open menu'} placement="left" arrow>
                <IconButton aria-label="Open menu" onClick={handleDrawerOpen}>
                  <ChevronRightIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}

type TProps = {
  children: React.ReactNode
  appSection: string
}

export default MenuDrawer
