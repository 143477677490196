import React from 'react'
import { Switch, Route } from 'react-router'
import { Routes } from 'constants/routing'

// views
import Dashboard from '../views/Dashboard'
import Salestool from '../views/Salestool'
import Companies from '../views/Companies'
import Categories from '../views/Categories'
import LayoutWrapper from '../views/SystemHome'

const Router = (): React.ReactElement => {
  return (
    <Switch>
      <Route exact path={Routes.SALESTOOL} component={Salestool} />
      <Route exact path={Routes.COMPANIES} component={Companies} />
      <Route exact path={Routes.CATEGORIES} component={Categories} />
      <Route
        path={Routes.SYSTEM}
        component={(props: any) => (
          <LayoutWrapper>
            <Dashboard {...props} />
          </LayoutWrapper>
        )}
      />
    </Switch>
  )
}

export default Router
