import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    activitiesBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#d0d0d0',
      fontSize: '10px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      marginBottom: '4px',
      textTransform: 'uppercase',
      whiteSpace: 'normal',
    },
    siteBlock: {
      color: '#8b8b8b',
      fontSize: '12px',
      letterSpacing: '0px',
      textTransform: 'capitalize',
      marginBottom: '14px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    userBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    userDescription: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '12px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    userCompany: {
      color: '#8b8b8b',
      fontSize: '12px',
      letterSpacing: '0px',
      textTransform: 'uppercase',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    userName: {
      color: '#f6f6f6',
      fontSize: '14px',
      letterSpacing: '0px',
    },
    dateBlock: {
      paddingLeft: '16px',
    },
  }
})
