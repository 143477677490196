import React, { useState, useRef, useEffect } from 'react'

// Material UI
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'

//
import { ISearchInput } from './types'
import { useStyles } from './styles'

const SearchInput = (props: ISearchInput): React.ReactElement => {
  const { value, onChange, InputBaseProps } = props

  const [collapsed, setCollapsed] = useState(true)

  const inputRef: React.RefObject<HTMLInputElement> = useRef(null)
  const classes = useStyles()

  const expand = (): void => {
    setCollapsed(false)
  }
  const collapse = (): void => {
    setCollapsed(true)
  }

  useEffect(() => {
    if (!collapsed) inputRef.current && inputRef.current.focus()
  }, [collapsed])

  const expanded = !collapsed ? 'expanded' : ''

  return (
    <div className={`${classes.search} ${expanded}`}>
      <Box overflow="hidden" display="flex" height="100%">
        <div className={`${classes.searchIcon} ${expanded}`}>
          <Tooltip title="Search" enterDelay={500}>
            <IconButton onClick={expand} aria-label="Search">
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </div>
        <InputBase
          type="search"
          placeholder="Search…"
          classes={{
            root: `${classes.inputRoot} ${expanded}`,
            input: `${classes.inputInput}`,
          }}
          inputProps={{ 'aria-label': 'search', ref: inputRef, tabIndex: -1 }}
          onBlur={(): void => {
            !value && collapse()
          }}
          value={value}
          onChange={onChange}
          {...InputBaseProps}
        />
      </Box>
    </div>
  )
}

export default SearchInput
