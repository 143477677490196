import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {},
    cardHeader: {
      alignItems: 'center',
      height: '100%',
    },
    avatar: {
      backgroundColor: 'transparent',
      width: theme.spacing(6),
      height: theme.spacing(6),
      color: 'inherit',
    },
  }
})
