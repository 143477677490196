import React, { ReactElement, useEffect, useCallback, useState } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

// Webtastic
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

//
import {
  useBookingContext,
  useBookingContextActions,
} from 'sections/booking/context/bookingContext/useBookingContext'
import { IBookingForm, IAvailability } from './types'
import DateTimePicker from 'components/common/DateTimePicker'
// import TimePicker from 'components/common/TimePicker'
import useDebounce from 'hooks/useDebounce'
import Availability from './Availability'

const BookingForm = (props: IBookingForm): ReactElement => {
  const { open, machine, booking, setFormIsValidated } = props
  const [period, setPeriod] = useState<{ from: Date | null; to: Date | null }>({
    from: null,
    to: null,
  })
  const [comment, setComment] = useState<string | null>(null)
  const [availability, setAvailability] = useState<IAvailability | null>(
    null /* {
    status: 'NOT_AVAILABLE',
    machinesAlreadyBooked: {
      MACHINEndbjqa20x1609755258393: [
        {
          bookingId: 'BOOKING0n2l8xizl1610704093190',
          utcMillisFrom: 1610704094786,
          utcMillisTo: 1610751300000,
        },
        {
          bookingId: 'BOOKING0n2l8xizl1610704093190',
          utcMillisFrom: 1610704094786,
          utcMillisTo: 1610751300000,
        },
      ],
      MACHINEndbjqa20x1609755258394: [
        {
          bookingId: 'BOOKING0n2l8xizl1610704093190',
          utcMillisFrom: 1610704094786,
          utcMillisTo: 1610751300000,
        },
      ],
    },
  } */,
  )

  // Context
  const bookingContext = useBookingContext()
  const bookingContextActions = useBookingContextActions()
  const bookingId = bookingContext?.doc?.id

  // Hooks
  const debouncedComment = useDebounce(comment, 500)

  /**
   * Create/initialize booking context
   */
  useEffect(() => {
    if (machine && open && !bookingId) {
      /**
       * New booking
       */
      if (booking && booking?.action === 'NEW') {
        bookingContextActions.createBooking({
          data: {
            siteId: machine?.siteId || '',
            machineId: machine.id,
          },
        })
        return
      }

      /**
       * Edit (replace) an already approved booking
       */
      if (booking && booking?.action === 'REPLACE') {
        if (!booking.id) window.furball.error('Error: Missing booking.id')

        bookingContextActions.createBooking({
          data: { replacesBookingId: booking.id },
        })

        return
      }

      /**
       * Edit an open booking
       */
      if (booking && booking?.action === 'EDIT' && booking?.id) {
        if (!booking.id) window.furball.error('Error: Missing booking.id')

        bookingContextActions.setBookingId(booking.id)
      }
    }
  }, [machine, open, booking, bookingId, bookingContextActions])

  /**
   * Update one or many fields
   */
  const updateBooking = useCallback(
    (props: { from?: number; to?: number; comment?: string }): Promise<any> => {
      const { from, to, comment } = props

      if (bookingContext?.doc?.id) {
        return bookingContextActions.updateBooking({
          data: {
            bookingId: bookingContext?.doc?.id,
            fromUtcMillis: from || undefined,
            toUtcMillis: to || undefined,
            comment: typeof comment === 'string' ? comment : undefined,
          },
        })
      }
      return new Promise(() => {
        // Nothing
      })
    },
    [bookingContext, bookingContextActions],
  )

  /**
   * Update period and
   * - set availability status
   * - form validation
   */
  const updatePeriod = useCallback(
    (params: { from?: number; to?: number }): void => {
      const {
        from = bookingContext?.doc?.fromTimestamp?.toMillis(),
        to = bookingContext?.doc?.toTimestamp?.toMillis(),
      } = params

      setFormIsValidated(false)

      if (from && to) {
        setAvailability({
          status: 'LOADING',
        })

        updateBooking({
          from,
          to,
        }).then(({ data }) => {
          if (data?.code === 'C2000') {
            setAvailability({
              status: 'AVAILABLE',
            })
            setFormIsValidated(true)
          } else if (data?.code === 'C4215') {
            setAvailability({
              status: 'NOT_AVAILABLE',
              alreadyBooked: data?.alreadyBooked || undefined,
            })
          } else {
            setAvailability({
              status: 'INVALID',
            })
          }
        })
      }
    },
    [setFormIsValidated, updateBooking, bookingContext],
  )

  /**
   * Period (from) onChange handler
   */
  const handleFrom = (v: Date): void => {
    if (!isNaN(v?.getTime())) {
      setPeriod(state => {
        return { ...state, from: v }
      })
    }
  }

  /**
   * Period (to) onChange handler
   */
  const handleTo = (v: Date): void => {
    if (!isNaN(v?.getTime())) {
      setPeriod(state => {
        return { ...state, to: v }
      })
    }
  }

  /**
   * Comment onChange handler
   */
  const handleComment = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value)
  }

  /**
   * Set default period states
   * - Setting directly to state (and not just default values)...
   * ...will trigger a db update and availability check
   */
  useEffect(() => {
    if (
      bookingContext?.doc?.fromTimestamp &&
      bookingContext?.doc?.toTimestamp &&
      period.from === null &&
      period.to === null
    ) {
      setPeriod(() => {
        return {
          to: bookingContext.doc.toTimestamp?.toDate() as Date,
          from: bookingContext.doc.fromTimestamp?.toDate() as Date,
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingContext?.doc, period])

  /**
   * Save comment to db
   */
  useEffect(() => {
    updateBooking({
      comment: debouncedComment,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedComment])

  /**
   * Save period to db
   */
  useEffect(() => {
    if (period.from && period.to) {
      updatePeriod({
        from: period.from.getTime(),
        to: period.to.getTime(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  if (!bookingContext) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
      <Box my={3} mx={2}>
        <Typography variant="body2" paragraph>
          Select period
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="From"
              id="booking-from-datetime-picker"
              value={
                period.from ||
                bookingContext?.doc?.fromTimestamp?.toDate() ||
                null
              }
              onChange={handleFrom}
              disabled={false}
              datetimePickerProps={{
                style: { width: '100%' },
                disablePast: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TimePicker
              label="To"
              id="booking-to-time-picker"
              value={period?.to || null}
              onChange={handleTo}
              disabled={!period?.from}
              timePickerProps={{ style: { width: '100%' } }}
            /> */}
            <DateTimePicker
              label="To"
              id="booking-to-datetime-picker"
              value={
                period.to || bookingContext?.doc?.toTimestamp?.toDate() || null
              }
              onChange={handleTo}
              disabled={false}
              datetimePickerProps={{
                style: { width: '100%' },
                disablePast: true,
              }}
            />
          </Grid>
        </Grid>
        <Box my={2}>
          {availability ? <Availability availability={availability} /> : null}
        </Box>
        <Typography variant="body2" paragraph>
          Usage
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs>
            <TextField
              id="machine-usage"
              label="I'm using it for..."
              placeholder=""
              multiline
              variant="outlined"
              style={{ width: '100%' }}
              value={
                comment !== null ? comment : bookingContext?.doc?.comment || ''
              }
              onChange={handleComment}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BookingForm
