import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import firebase, { auth } from 'firebase/app'
import { useHistory } from 'react-router-dom'

import firebaseConfig from 'firebaseConfig'
import { errorCodes } from 'constants/error'
import { Routes } from 'constants/routing'

interface IAuthActions {
  signInWithGoogle: () => Promise<string | null>
  signIn: (password: string, email: string) => Promise<string | null>
  logOut: () => Promise<void>
  signUp: (payload: any) => Promise<void>
}

interface IProvider {
  children: React.ReactNode
}

interface IAuthValues {
  isInitDataLoaded: boolean
  uid: string | null
  token: string | null
  isSignUpLoading: boolean
}

const signUpRequest = async (
  token: string | undefined,
  data: any,
): Promise<Response> =>
  await fetch(`${process.env.REACT_APP_API_BASE_URL}api/signUpSuperUser`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

const AuthContext = createContext<IAuthValues | null>(null)
const AuthActionsContext = createContext<any>(null)

export const useAuthContext = (): IAuthValues | null => useContext(AuthContext)
export const useAuthContextActions = (): IAuthActions =>
  useContext(AuthActionsContext)

const Provider = (props: IProvider): React.ReactElement => {
  const { children } = props
  // State
  const [uid, setUid] = useState<null | string>(null)
  const [token, setToken] = useState<null | string>(null)
  const [isInitDataLoaded, setIsInitDataLoaded] = useState(false)
  const [isSignUpLoading, setSignUpLoading] = useState(false)

  // Hooks
  const history = useHistory()

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      document.title = 'Staging Admin'
    }
    const app = firebase.initializeApp(firebaseConfig)

    let timerId: NodeJS.Timeout
    const getIdToken = async (): Promise<void> => {
      const currentUser = auth().currentUser
      if (currentUser) {
        const token = await currentUser.getIdToken(true)
        setToken(token)
      }
    }

    firebase.auth().onAuthStateChanged(async user => {
      await getIdToken()
      setUid(user?.uid || null)
      setIsInitDataLoaded(true)
      if (timerId) {
        clearInterval(timerId)
      }
      timerId = setInterval(getIdToken, 1000 * 60 * 59) // Refresh the token before it expires (every 1 h)
    })
    return (): void => {
      clearInterval(timerId)
      app.delete()
    }
  }, [])

  const signInWithGoogle = useCallback(async (): Promise<string | null> => {
    try {
      const provider = new auth.GoogleAuthProvider()
      const data = await auth().signInWithPopup(provider)
      const currentUid = data?.user?.uid || null
      setUid(currentUid)
      return currentUid
    } catch (e) {
      console.log('error', e)
      window.furball.error('Error: Please try again or contact support.')
      return null
    }
  }, [])

  const signIn = useCallback(async (password, email): Promise<
    string | null
  > => {
    try {
      await auth().signInWithEmailAndPassword(email, password)
      const userInfo = auth()?.currentUser
      const currentUid = userInfo?.uid || null
      setUid(currentUid)
      return currentUid
    } catch (e) {
      let message: string
      switch (e.code) {
        case errorCodes.INVALID_PASSWORD: {
          message = 'Wrong password'
          break
        }
        case errorCodes.MANY_REQUESTS: {
          message =
            'Too many unsuccessful login attempts. Please try again later.'
          break
        }
        case errorCodes.EMAIL_NOT_FOUND: {
          message = 'There is no user registered with that email address'
          break
        }
        default: {
          message = 'Something went wrong, please try again later'
        }
      }

      window.furball.error(message)
      console.error('Error: ', e)

      return null
    }
  }, [])

  const signUp = useCallback(
    async (payload: any): Promise<void> => {
      const {
        billingAddress,
        billingEmail,
        companyName,
        companyOrgnr,
        contactEmail,
        contactPerson,
        /* isBillingSame, */
        isMva,
        /* municipality, */
        password,
        postcode,
        /* retypePassword, */
        signupCode,
      } = payload

      const data = {
        name: contactPerson,
        phone: '',
        countryDialCode: '',
        email: contactEmail,
        company: {
          name: companyName,
          orgNr: companyOrgnr,
        },
        billingEmail,
        address: {
          zip: postcode,
          street: [billingAddress],
          county: '-',
        },
        country: '',
        mva: isMva,
        signupCode,
      }

      try {
        setSignUpLoading(true)

        try {
          await auth().createUserWithEmailAndPassword(contactEmail, password)
          const currentToken = await auth().currentUser?.getIdToken(true)

          const response = await signUpRequest(currentToken, data)
          const json = await response.json()
          if (json.code !== 'C2000') {
            throw json
          }
          setSignUpLoading(false)
          history.push(Routes.SITE)
        } catch (e) {
          if (e.code === 'auth/email-already-in-use') {
            // handle emails that were used for signin with google btn but user doesn't exist in database
            await fetch(
              `${process.env.REACT_APP_API_BASE_URL}api2/checkUserExists`,
              {
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + process.env.REACT_APP_API2_TOKEN,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: data.email }),
              },
            )
            await auth().createUserWithEmailAndPassword(data.email, password)
            const currentToken = await auth().currentUser?.getIdToken(true)

            const response = await signUpRequest(currentToken, data)
            const json = await response.json()
            if (json.code !== 'C2000') {
              throw json
            }
            history.push(Routes.SITE)
          } else {
            throw e
          }
        }
        setSignUpLoading(false)
      } catch (e) {
        let message: string

        if (e.code === 'auth/email-already-in-use') {
          message = 'This email address is already used by another user'
        } else if (e.code === 'C4006') {
          message = 'Invalid Signup code. Please request a new one.'
        } else if (e.message) {
          message = e.message
        } else {
          message = 'Something went wrong, please try again later'
        }

        console.error('error', e)
        window.furball.error(message)

        await auth().signOut()
        setSignUpLoading(false)
      }
    },
    [history],
  )

  const logOut = useCallback(async (): Promise<void> => {
    await auth().signOut()
    localStorage.removeItem('selectedSite')
    localStorage.removeItem('selectedCompany')
    setUid(null)

    window.location.reload()
  }, [])

  const actions = useMemo(() => {
    return {
      signInWithGoogle,
      signIn,
      logOut,
      signUp,
    }
  }, [signInWithGoogle, signIn, logOut, signUp])

  const value = useMemo(() => {
    return { isInitDataLoaded, uid, token, isSignUpLoading }
  }, [isInitDataLoaded, uid, token, isSignUpLoading])

  return (
    <AuthContext.Provider value={value}>
      <AuthActionsContext.Provider value={actions}>
        {children}
      </AuthActionsContext.Provider>
    </AuthContext.Provider>
  )
}

export default Provider
