import { useState, useCallback, useRef } from 'react'

//
import * as db from 'utils/firestoreDB'

const useUserPermissionsListener = (): Response => {
  const [userPermissions, setUserPermissions] =
    useState<IFirestoreUserPermission | null>(null)
  const [isListening, setIsListening] = useState(false)

  const unsubRef = useRef<Function | null>(null)

  const fetch = useCallback((props: IUseFetchUserPermissions) => {
    const { userId } = props

    // Unsubscribe previous listener
    unsubRef.current && unsubRef.current()

    if (userId && !isListening) {
      const query = db.userPermissions().doc(userId)

      const unsubscribe = query.onSnapshot(function (response) {
        if (response.exists) {
          const doc = response.data()

          doc && setUserPermissions(doc)
        }

        setIsListening(true)
      })

      unsubRef.current = unsubscribe
    }

    // Unsubscribe on unmount
    return (): void => {
      unsubRef.current && unsubRef.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [fetch, { userPermissions }]
}

export default useUserPermissionsListener

export interface IUseFetchUserPermissions {
  userId: string
}

type Response = [
  (props: IUseFetchUserPermissions) => void,
  {
    userPermissions: IFirestoreUserPermission | null
  },
]
