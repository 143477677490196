import React, { createContext, useState, useContext, useEffect } from 'react'
import { firestore } from 'firebase/app'

//
import { TSiteTimelineEvent } from 'types/site'
import { useContext as useSiteContext } from 'state/context/siteContext'

const TimeLineContext = createContext<TSiteTimelineEvent[]>([])

export const useTimeLineContext = (): TSiteTimelineEvent[] =>
  useContext(TimeLineContext)

const Provider = (props: P): React.ReactElement => {
  const { children } = props
  const [siteTimelineEvent, setSiteTimelineEvent] = useState<
    TSiteTimelineEvent[]
  >([])

  // Context
  const { selectedSite } = useSiteContext()

  /**
   * Firestore listener
   */
  useEffect(() => {
    let unsubscribe = (): void => {
      // Nothing
    }

    if (selectedSite?.id) {
      const query = firestore()
        .collection('timelines')
        .where('siteId', '==', selectedSite.id)
        .where('type', 'in', ['check', 'role change', 'access management'])
        .orderBy('timestamp', 'desc')
        .limit(20)
      unsubscribe = query.onSnapshot(function(response: any) {
        const formattedData: TSiteTimelineEvent[] = []

        if (!response?.empty) {
          response?.docs.forEach((event: any): void => {
            const data = event.data()

            formattedData.push({
              eventId: data.id,
              action: data.action,
              timestamp: data.timestamp.toDate(),
              checkType: data.checkType,
              user: {
                id: data.userId,
                name: data.userName,
                imageUrl: data.userImageUrl,
                imageThumbUrl: data.userImageThumbUrl,
                company: {
                  id: data.userCompanyId,
                  name: data.userCompanyName,
                },
              },
              displayText: data.displayText,
            })
          })
        }

        setSiteTimelineEvent(formattedData)
      })
    }

    // Unsubscribe on unmount
    return (): void => {
      unsubscribe()
    }
  }, [selectedSite])

  return (
    <TimeLineContext.Provider value={siteTimelineEvent}>
      {children}
    </TimeLineContext.Provider>
  )
}

type P = {
  children: React.ReactNode
}

export default Provider
