import React, { useState, useEffect, useCallback } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import UserIcon from '@material-ui/icons/Person'
import CompanyIcon from '@material-ui/icons/Business'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Autocomplete, {
  IAutocompleteOption,
} from '@naevaas/webtastic/dist/components/Autocomplete'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import { IAddAccess, TAddAccessTypename } from './types'
import useDebounce from 'hooks/useDebounce'
import useFindCompanies from 'hooks/useFindCompanies'
import { useCloudFunction } from 'hooks/useCloudFunctions'
import { ISitePermission } from 'types/site'

const useStyles = makeStyles((theme: Theme) => {
  return {
    selected: {
      border: `${theme.palette.primary.main} solid 2px`,
      borderRadius: theme.shape.borderRadius,
    },
    notSelected: {
      border: `${theme.palette.divider} solid 2px`,
      borderRadius: theme.shape.borderRadius,
    },
  }
})

const AddAccess = (props: IAddAccess): React.ReactElement => {
  const { onClose, onNext } = props

  const [selectedTypename, setSelectedTypename] = useState<
    ISitePermission['__typename'] | null
  >('USER')
  const [keyword, setKeyword] = useState<string | null>(null)
  const [selectedItems, setSelectedItems] = useState<IAutocompleteOption[]>([])
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    IAutocompleteOption[] | null
  >(null)
  const [autocompleteIsFetching, setAutocompleteIsFetching] = useState(false)

  const [fetchUsers] = useCloudFunction()
  const [findCompanies] = useFindCompanies()
  const debouncedKeyword = useDebounce(keyword, 250)

  const classes = useStyles()

  useEffect(() => {
    if (debouncedKeyword) {
      setAutocompleteIsFetching(true)
      if (selectedTypename === 'USER') {
        fetchUsers('searchUser', {
          searchString: debouncedKeyword.trim(),
          excludeSelf: false,
        })
          .then((response) => {
            const users = response?.data?.users
            const options = [] as IAutocompleteOption[]
            if (users) {
              users.forEach((user) => {
                options.push({
                  id: user.id,
                  avatarUrl: user.imageThumbUrl ?? '',
                  label: user.name,
                  primaryText: user.name,
                  secondaryText: user.companyName,
                  data: user,
                })
              })
            }

            setAutocompleteOptions(options.length ? options : null)
          })
          .finally(() => {
            setAutocompleteIsFetching(false)
          })
      } else if (selectedTypename === 'COMPANY') {
        findCompanies({
          keyword: debouncedKeyword,
        })
          .then((res) => {
            if (res) {
              const options = [] as IAutocompleteOption[]
              res.forEach((v) => {
                options.push({
                  id: v.id,
                  avatarUrl: '',
                  label: v.name,
                  primaryText: v.name,
                  secondaryText: v?.orgNr || '',
                  data: v,
                })
              })
              setAutocompleteOptions(options)
            }
          })
          .finally(() => {
            setAutocompleteIsFetching(false)
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypename, debouncedKeyword])

  const changeType = (typename: TAddAccessTypename): void => {
    setSelectedTypename(typename)
    setKeyword(null)
    setSelectedItems([])
    setAutocompleteOptions(null)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setKeyword(e.target.value)
  }

  const handleSelectionChange = (
    selection: IAutocompleteOption[],
    _reason: string,
  ): void => {
    setSelectedItems(selection)
  }

  const disableNextButton = useCallback(() => {
    return !selectedTypename || selectedItems.length < 1
  }, [selectedTypename, selectedItems.length])

  const handleNextButton = useCallback(() => {
    if (disableNextButton()) return

    const items: ISitePermission[] = selectedItems.map((item) => {
      return selectedTypename === 'USER'
        ? ({
            entryAllow: ['worker'],
            bookingAllow: ['machine'],
            machinesAllow: false,
            chargeAllow: false,
            editor: null,
            viewer: null,
            timeRestricted: false,
            timeRestrictionStart: null,
            timeRestrictionEnd: null,
            id: item.id,
            name: item.primaryText,
            userData: {
              id: item.id,
              name: item.primaryText,
              imageUrl: item.avatarUrl,
              imageThumbUrl: item.avatarUrl,
              companyId: item.data?.companyId,
              companyName: item.data?.companyName,
            },
            __typename: 'USER',
            __isValid: true,
          } as ISitePermission)
        : ({
            entryAllow: ['worker'],
            bookingAllow: ['machine'],
            machinesAllow: false,
            chargeAllow: false,
            editor: null,
            viewer: null,
            timeRestricted: false,
            timeRestrictionStart: null,
            timeRestrictionEnd: null,
            id: item.id,
            name: item.primaryText,
            companyData: {
              id: item?.id,
              name: item?.data?.name,
              orgNr: item?.data?.orgNr,
            },
            __typename: 'COMPANY',
            __isValid: true,
          } as ISitePermission)
    })

    selectedTypename && onNext(selectedTypename, items)
  }, [disableNextButton, selectedItems, selectedTypename, onNext])

  return (
    <Dialog open={true} onClose={onClose} fullHeight={false} maxWidth="sm">
      <DialogTitle>Create access for...</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          my={3}
        >
          <Box
            className={
              selectedTypename === 'USER'
                ? classes.selected
                : classes.notSelected
            }
            width="25%"
            minWidth="100px"
          >
            <Button
              color="default"
              style={{ width: '100%' }}
              onClick={(): void => {
                changeType('USER')
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <UserIcon style={{ fontSize: '3rem' }} />
                Users
              </Box>
            </Button>
          </Box>
          <Box>or</Box>
          <Box
            className={
              selectedTypename === 'COMPANY'
                ? classes.selected
                : classes.notSelected
            }
            width="25%"
            minWidth="100px"
          >
            <Button
              color="default"
              style={{ width: '100%' }}
              onClick={(): void => {
                changeType('COMPANY')
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CompanyIcon style={{ fontSize: '3rem' }} />
                Companies
              </Box>
            </Button>
          </Box>
        </Box>

        {selectedTypename && (
          <>
            <Box my={2}>
              <Divider />
            </Box>
            <Autocomplete
              onInputChange={handleInputChange}
              onSelectionChange={handleSelectionChange}
              multiple={true}
              options={autocompleteOptions || undefined}
              isFetching={autocompleteIsFetching}
              value={selectedItems}
              textFieldProps={{
                placeholder: `Search by name`,
                label: `Find ${
                  selectedTypename === 'USER' ? 'users' : 'companies'
                }`,
              }}
              filterOptions={(x: any) => x}
              
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          onClick={handleNextButton}
          disabled={disableNextButton()}
        >
          NEXT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddAccess
