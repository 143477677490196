import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

// Material-UI
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

// Webtastic
import useStyledScrollbar from '@naevaas/webtastic/dist/hooks/useStyledScrollbar'

//
import Actions from '../../components/Actions'
import Navigation from 'components/common/Navigation'
import { RouteComponentProps } from 'react-router'
import { Routes } from 'constants/routing'
import NavButtons from '../../components/NavButtons'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import {
  useContext as useSiteContext,
  useContextDispatch as useSiteDispatch,
} from 'state/context/siteContext'
import { ISiteList } from 'types/site'

function SiteDashboard(
  props: RouteComponentProps<{ siteId: string }>,
): React.ReactElement {
  const {
    match: {
      params: { siteId },
    },
  } = props

  // Context
  const { sites, selectedSite } = useSiteContext()
  const siteDispatch = useSiteDispatch()

  // Hooks
  const { sitePolicy } = useSitePolicy()
  const styledScrollbar = useStyledScrollbar()
  const history = useHistory()

  const handleInvalidSite = useCallback((): void => {
    history.replace(Routes.SITES)
  }, [history])

  useEffect(() => {
    if (sites && selectedSite?.id && sites[selectedSite.id] !== selectedSite) {
      siteDispatch('SET_SELECTED_SITE', { site: sites[selectedSite.id] })
    }
  }, [selectedSite, siteDispatch, sites])

  /**
   * Handle selected site
   */
  useEffect(() => {
    if (!sites) return

    if (!siteId) {
      if (Object.keys(sites).length) {
        if (
          Object.keys(sites).includes(
            localStorage.getItem('selectedSite') || '',
          )
        ) {
          history.replace(
            `${Routes.SITE}/${localStorage.getItem('selectedSite')}`,
          )
        } else {
          history.replace(`${Routes.SITE}/${Object.keys(sites)[0]}`)
        }
      } else {
        handleInvalidSite()
      }
    } else if (siteId && siteId !== selectedSite?.id) {
      // Set site from params (router)
      const site = sites[siteId] || undefined
      if (site) {
        siteDispatch('SET_SELECTED_SITE', { site })
      } else {
        handleInvalidSite()
      }
    }
  }, [sites, siteId, history, handleInvalidSite, selectedSite, siteDispatch])

  const changeSelectedSite = useCallback((): void => {
    if (!sites) return

    const filteredSites = Object.values(sites).filter(
      (item: IFirestoreSite) => item.id !== selectedSite?.id,
    )
    if (filteredSites.length) {
      history.push(`${Routes.SITE}/${filteredSites[0].id}`)
    } else {
      handleInvalidSite()
    }
  }, [handleInvalidSite, history, selectedSite, sites])

  const onSelectSite = (id: string): void => {
    history.push(`${Routes.SITE}/${id}`)
  }

  const filteredSiteList: ISiteList =
    (sites &&
      Object.fromEntries(
        Object.entries(sites).filter(
          (site) => !site[1].archived || site[1].id === selectedSite?.id,
        ),
      )) ||
    {}

  if (selectedSite?.id && !sitePolicy().allowAdmin(selectedSite, 'view')) {
    return <div>Access denied</div>
  }

  if (!sites) return <></>

  return (
    <>
      {selectedSite?.id ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          height="100vh"
        >
          <Box
            flex="auto"
            display="flex"
            alignItems="flex-end"
            maxWidth="1000px"
            width="100%"
            px={1}
            pt={3}
            pb={0}
          >
            <Navigation
              allSites={filteredSiteList}
              selectedSite={selectedSite}
              onSelectSite={onSelectSite}
              actions={
                <NavButtons
                  site={selectedSite}
                  changeSelectedSite={changeSelectedSite}
                />
              }
            />
          </Box>
          <Box
            flex="auto"
            p={3}
            className={styledScrollbar.styledScrollbar}
            width="100%"
            maxWidth="1000px"
            overflow="auto"
          >
            {selectedSite?.archived && (
              <Box pb={2}>
                <Alert severity="info" variant="outlined">
                  <AlertTitle>Archived</AlertTitle>
                  The site is archived and cannot be edited.
                </Alert>
              </Box>
            )}
            <Box py={1}>
              <Typography variant="h6" component="h2">
                Manage site
              </Typography>
            </Box>
            <Actions />
          </Box>
        </Box>
      ) : (
        <>
          {/* <p>No sites created yet</p>
          <Button className={__classes.createBtn} onClick={() => {console.log('navigate to create-new-site')}}>
            Create site
          </Button> */}
        </>
      )}
    </>
  )
}

export default SiteDashboard
