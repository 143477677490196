import React, { useState } from 'react'

// Material UI
import { Typography } from '@material-ui/core'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

export interface IDeleteDialog {
  onClose: () => void
  deleteItem(payload: any): void
  title: string
}

function DeleteDialog(props: IDeleteDialog): React.ReactElement {
  const { onClose, deleteItem, title } = props
  const [isOpen, toggleDialog] = useState(true)

  return (
    <Dialog
      open={isOpen}
      onClose={(): void => {
        toggleDialog(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      fullHeight={false}
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography variant="h5" component="h3">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="p">
          This can not be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={deleteItem}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(DeleteDialog)
