import React from 'react'

//import Box from '@material-ui/core/Box'

import AuthLayout from 'components/layout/AuthLayout'
import MenuDrawer from 'components/views/MenuDrawer'
//import ChatBubble from 'components/common/ChatBubble'

function SystemHome(props: ISystemHome): React.ReactElement {
  const { children } = props
  return (
    <AuthLayout
      menuComponent={
        <MenuDrawer appSection={'system'}>
          <div />
        </MenuDrawer>
      }
      contentComponent={children}
    >
      {/* <Box position="fixed" bottom="1vh" right="1vh">
        <ChatBubble />
      </Box> */}
    </AuthLayout>
  )
}

export default SystemHome

interface ISystemHome {
  children: React.ReactElement
}
