import React, { ReactElement, useEffect, useState } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import { Theme, makeStyles } from '@material-ui/core/styles'

//
import { IMyBookings } from './types'
import useFetchBookings from '../../hooks/useFetchBookings'
import { useAuthUserContext } from 'state/context/authUserContext'
import BookingDetailsDialog from '../../components/BookingDetailsDialog'
import MachineCard from '../../components/MachineCard'
import BookingDetails from '../../components/BookingDetails'
import getBookingStatus from '../../utils/getBookingStatus'
import {
  IMachineDialog,
  TBookingActions,
} from 'sections/booking/components/MachineDialog/types'
import MachineDialog from 'sections/booking/components/MachineDialog'
import useFetchMachines from '../../hooks/useFetchMachines'

const useStyles = makeStyles((theme: Theme) => ({
  chip: { float: 'right', marginTop: '-55px' },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
  },
}))

const MyBookings = (props: IMyBookings): ReactElement => {
  const { siteId } = props
  const [viewBooking, setViewBooking] = useState<
    { booking: IFirestoreMachineBooking } | undefined
  >(undefined)
  const [viewMachine, setViewMachine] = useState<
    | {
        machine: IMachineDialog['machine']
        booking: {
          action: Exclude<TBookingActions, 'NEW'>
          id: string
        }
        step: IMachineDialog['step']
      }
    | undefined
  >(undefined)

  // Context
  const authUser = useAuthUserContext()

  // Hooks
  const [fetch, { data }] = useFetchBookings()
  const [fetchMachines] = useFetchMachines()
  const classes = useStyles()

  //
  //const now = new Date()

  /**
   * Trigger fething of the bookings
   */
  useEffect(() => {
    if (authUser?.uid) {
      fetch({ filter: { createdBy: authUser?.uid, siteId, canceled: false } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, siteId])

  return (
    <Box my={4}>
      {data && Object.values(data).length ? (
        <Grid container spacing={3}>
          {Object.values(data).map(booking => {
            const machineId = booking?.machineId || null
            const image = booking.machineImageThumbUrl || undefined
            const title =
              booking.machineMake && booking.machineModel
                ? `${booking.machineMake} ${booking.machineModel}`
                : '(unkown)'
            const subtitle = booking.machineCategory || ''
            const status = getBookingStatus(booking)
            const allowEdit =
              status !==
              'CANCELED' /* &&
              differenceInMilliseconds(
                booking?.toTimestamp?.toDate() as Date,
                now,
              ) > 0 */

            const content = (
              <Box>
                <BookingDetails
                  booking={booking}
                  showFields={['PERIOD', 'USAGE']}
                />
                <Box mb={2}>
                  <Divider />
                </Box>
                <Typography variant="caption" color="textSecondary">
                  {booking.id}
                </Typography>
              </Box>
            )

            return (
              <Grid key={booking.id} item>
                <MachineCard
                  image={image}
                  title={
                    <>
                      {status === 'COMPLETED' && (
                        <Chip
                          className={`${classes.chip} ${classes.chipSuccess}`}
                          label="Completed"
                        />
                      )}
                      {status === 'DRAFT' && (
                        <Chip className={`${classes.chip}`} label="Draft" />
                      )}

                      {title}
                    </>
                  }
                  subtitle={<>{subtitle}</>}
                  content={<>{content}</>}
                  /* onClick={(): void => {
                    setViewBooking({ booking })
                  }} */
                  actions={
                    <>
                      {allowEdit && (
                        <Button
                          color="primary"
                          onClick={(): void => {
                            fetchMachines({
                              filter: {
                                machineId: machineId as string,
                              },
                            }).then(response => {
                              const machine = response?.[0]
                              console.log('machine', machine, status)

                              if (machine) {
                                setViewMachine({
                                  machine,
                                  booking: {
                                    action:
                                      status === 'DRAFT' ? 'EDIT' : 'REPLACE',
                                    id: booking.id,
                                  },
                                  step: 'FORM',
                                })
                              }
                            })
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </>
                  }
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Box mt={10}>
          <Grid container>
            <Grid item xs style={{ textAlign: 'center' }}>
              <Typography variant="h5" color="textSecondary">
                Found no bookings
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      <BookingDetailsDialog
        booking={viewBooking?.booking}
        /* step={viewItem?.step} */
        onClose={(): void => {
          setViewBooking(undefined)
        }}
      />

      <MachineDialog
        machine={viewMachine?.machine}
        booking={viewMachine?.booking}
        step={viewMachine?.step}
        onClose={(): void => {
          setViewMachine(undefined)
        }}
      />
    </Box>
  )
}

export default MyBookings
