import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    dropZone: {
      height: '100%',
      width: '100%',
      color: theme.palette.grey[500],
      borderRadius: '10px',
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      cursor: 'pointer',
    },
    dropZoneInput: {
      display: 'none',
    },
    dropZoneIcon: {
      color: theme.palette.primary.main,
      fontSize: '65px',
    },
  }
})
