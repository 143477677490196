import React from 'react'

import { Checkbox } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  container: {
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    color: '#55CC83',
  },
  labelSection: {
    color: '#fff',
  },
  input: {
    color: '#55CC83',
    '& .Mui-checked': {
      color: '#55CC83',
    },
  },
})

interface IFormCheckboxProps {
  label?: string
  [x: string]: any
}

function FormCheckbox(props: IFormCheckboxProps): React.ReactElement {
  const { theme, isValid, label, type, name, css, ...rest } = props

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.labelSection}>
        {label && <label htmlFor={name}>{label}</label>}
      </div>
      <Checkbox
        className={classes.input}
        color="default"
        name={name}
        id={name}
        {...rest}
      />
    </div>
  )
}

export default React.memo(FormCheckbox)
