import React, { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// Material-UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

// Webtastic
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

//
import { ICompleted } from './types'
import { useContext } from '../Details'
import { Routes } from 'constants/routing'

const Completed = (__props: ICompleted): ReactElement => {
  const { requestSuccess } = useContext()
  const history = useHistory()

  useEffect(() => {
    requestSuccess?.siteId &&
      setTimeout(() => {
        history.push(`${Routes.SITE}/${requestSuccess.siteId}`)
      }, 2000)
  }, [history, requestSuccess])

  return (
    <Box pt={5} pb={10} textAlign="center">
      <Typography variant="h5">Success!</Typography>
      <Box pt={5}>
        <Typography>{`Please wait while we're preparing the site...`}</Typography>
      </Box>
      <Box pt={5}>
        <CircularProgress />
      </Box>
    </Box>
  )
}

export default Completed
