import React, {
  createContext,
  useReducer,
  useContext as _useContext,
  useCallback,
  useEffect,
} from 'react'

//
import { IProvider, IState, IDispatch, TAction } from './types'
import { reducer } from './reducer'

const State = createContext<any>(null)
const Actions = createContext<any>(null)

export const useContext = (): IState => _useContext(State)
export const useContextDispatch = (): IDispatch => _useContext(Actions)

const defaultInitialState: IState = {
  sites: null,
  selectedSite: null,
}

export const Provider = (props: IProvider): React.ReactElement => {
  const { children, initialState } = props
  const [state, _dispatch] = useReducer(reducer, {
    ...defaultInitialState,
    ...initialState,
  })

  const selectedSiteId = state.selectedSite?.id

  useEffect(() => {
    selectedSiteId && localStorage.setItem('selectedSite', selectedSiteId)
  }, [selectedSiteId])

  // Exposed dispatch function
  const dispatch: IDispatch = useCallback(
    (type: string, action?: any): void => {
      const _action = { type, ...action } as TAction
      _dispatch(_action)
    },
    [],
  )

  return (
    <State.Provider value={state}>
      <Actions.Provider value={dispatch}>{children}</Actions.Provider>
    </State.Provider>
  )
}
