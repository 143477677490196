import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  container: {
    width: '150px',
  },
  labelSection: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '8px 12px',
    margin: '8px 0',
    display: 'inline-block',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: 'inherit',
  },
  disabled: {
    pointerEvents: 'none',
    background: '#cacaca',
  },
  errorLabel: {
    color: '#E36B5B',
    marginRight: '8px',
  },
  errorBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  autoCompleteBlock: {
    background: '#fff',
    width: '250px',
    marginTop: '-9px',
    padding: '5px',
    boxSizing: 'border-box',
    position: 'absolute',
    border: '1px solid #ccc',
    borderRadius: '0 0 4px 4px',
    zIndex: 2,
    textAlign: 'left',
    color: '#000',
    '& li': {
      margin: '5px 0',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      listStyle: 'none',
    },
    '& li:last-child': {
      marginBottom: 0,
    },
  },
  searchSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      width: '25px !important' as any,
      height: '25px !important' as any,
    },
  },
})
