import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { Box, Button, IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Routes } from 'constants/routing'

import { useStyles } from './styles'

function Navigation(props: RouteComponentProps): React.ReactElement {
  const { history } = props
  const classes = useStyles()

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <IconButton
        onClick={(): void => {
          history.push(Routes.SYSTEM)
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box display="flex" alignItems="flex-start" flexWrap="wrap">
        <Button
          className={classes.button}
          onClick={(): void => {
            history.push(Routes.SALESTOOL)
          }}
        >
          Products
        </Button>
        <Button
          className={classes.button}
          onClick={(): void => {
            history.push(Routes.COMPANIES)
          }}
        >
          Companies
        </Button>
        <Button
          className={classes.button}
          onClick={(): void => {
            history.push(Routes.CATEGORIES)
          }}
        >
          Categories
        </Button>
      </Box>
    </Box>
  )
}

export default withRouter(Navigation)
