import { useCallback } from 'react'

//
import { useAuthUserContext } from 'state/context/authUserContext'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'

export const useBookPolicy = (): IUseBookPolicy => {
  const authUser = useAuthUserContext()

  const { sitePolicy } = useSitePolicy()

  /**
   * Override policy if user is systemAdmin
   */
  const before = useCallback(() => {
    // TODO: add permission check
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser])

  const bookPolicy: IUseBookPolicy['bookPolicy'] = useCallback(() => {
    return {
      accessBooking: (): boolean => {
        if (before()) return true

        // Disable in production. Subject to chnange.
        if (window.location.hostname === 'admin.naeva.no') {
          console.warn('BOOKING IS DISABLED at admin.naeva.no')
          return false
        }

        // If the user belongs to a company
        //if (!!authUser?.company?.id) return true

        // If the user have booking permissions on any site
        if (authUser?.sites) {
          return Object.values(authUser.sites).some(site =>
            sitePolicy().allowBooking(site),
          )
        }

        return false
      },
      /* create: (): boolean => {
        // rules...
        return false
      },
      view: (item: any): boolean => {
        // rules...
        return false
      },
      update: (item: any): boolean => {
        // rules...
        return false
      },
      delete: (item: any): boolean => {
        // rules...
        return false
      }, */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser])

  return { bookPolicy }
}

interface IUseBookPolicy {
  bookPolicy: () => {
    accessBooking: () => boolean
  }
}
