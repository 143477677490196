export default function showBookingNotification(code?: string): void {
  switch (code) {
    case 'C4000': {
      window.furball.error('Error! Missing parameters.')
      break
    }
    case 'C4030': {
      window.furball.error('The user is not found.')
      break
    }
    case 'C4210': {
      window.furball.error('The booking is not found.')
      break
    }
    case 'C4211': {
      window.furball.error('The booking has already approved.')
      break
    }
    case 'C4212': {
      window.furball.error('The booking is cancelled.')
      break
    }
    case 'C4213': {
      window.furball.error('Booking is not ready for approval.')
      break
    }
    case 'C4214': {
      window.furball.error('Max number of machines exceeded.')
      break
    }
    case 'C4215': {
      /* window.furball.error(
        'Selected time range is booked for one or more machines.',
      ) */
      break
    }
    case 'C4217': {
      // Error is already displayed in Alert
      break
    }
    default: {
      window.furball.error(
        'Something went wrong. Please try again later or contact support.',
      )
    }
  }
}
