import React, { ReactElement } from 'react'

// Material-UI
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'

//
import ListItemText from '../ListItemText'
import { IList } from './types'

const List = (props: IList): ReactElement => {
  const { uid, employees, handleEditEmployee } = props

  return (
    <>
      <MuiList>
        {employees?.map(employee => {
          if (uid && uid === employee.userId) return null
          if (!employee.userId) return null

          return (
            <React.Fragment key={employee.userId}>
              <Divider />
              <ListItem
                alignItems="flex-start"
                button
                onClick={(): void => {
                  // nothing
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={employee.userName}
                    src={employee.userImageThumbUrl}
                  />
                </ListItemAvatar>
                <ListItemText employee={employee} />

                <ListItemSecondaryAction>
                  <Button
                    aria-label="delete"
                    onClick={(): void => {
                      handleEditEmployee(employee.userId)
                    }}
                  >
                    Edit
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          )
        })}
      </MuiList>
    </>
  )
}

export default List
