import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react'

//
import { useCloudFunction } from 'hooks/useCloudFunctions'
import { IUseBookingContextProvider, IActions, IBookingContext } from './types'
import showBookingNotification from 'sections/booking/utils/showNotification'
import * as db from 'utils/firestoreDB'

const BookingContext = createContext<any>(null)
const BookingActionsContext = createContext<any>(null)

export const useBookingContext = (): IBookingContext =>
  useContext(BookingContext)

export const useBookingContextActions = (): IActions =>
  useContext(BookingActionsContext)

const Provider = (props: IUseBookingContextProvider): React.ReactElement => {
  const { children } = props
  const [bookingId, setBookingId] = useState<string | null>(null)
  const [value, setValue] = useState<IBookingContext | null>(null)

  // Hooks
  const [cloudFunction, { loading }] = useCloudFunction()

  const resetState = useCallback((): void => {
    setBookingId(null)
    setValue(null)
  }, [])

  /**
   * Firestore listener
   */
  useEffect(() => {
    let unsubscribe = (): void => {
      // Nothing
    }

    if (bookingId) {
      console.log('listen to ', bookingId)

      const query = db.machineBookings().where('id', '==', bookingId)

      unsubscribe = query.onSnapshot(response => {
        if (!response.empty) {
          const doc = response.docs[0].data()

          setValue(state => {
            return {
              ...state,
              doc,
            }
          })
        }
      })
    }

    if (!bookingId) {
      unsubscribe()
    }

    // Unsubscribe on unmount
    return (): void => {
      unsubscribe()
    }
  }, [bookingId])

  /**
   * function to initialize Booking
   * - Backend will get a document ready and return the doc id (bookingId)
   * - On success a listener will be started
   */
  const createBooking: IActions['createBooking'] = useCallback(
    async params => {
      const { data } = params

      if (!loading) {
        return cloudFunction('createMachineBooking', {
          ...data,
        })
          .then((response: any) => {
            const r = response?.data || {}
            if (r.code === 'C2000') {
              const bookingId: string = r.bookingId
              console.log('successful init. setBookingId ' + bookingId)
              setBookingId(bookingId)
            } else {
              showBookingNotification(r.code)
            }
            return response
          })
          .catch((error: {}) => {
            window.furball.error(
              'Something went wrong. Could not initialize machine booking',
            )
            return error
          })
      }
    },
    [cloudFunction, loading],
  )

  /**
   * Function to update booking
   */
  const updateBooking: IActions[Partial<'updateBooking'>] = useCallback(
    async params => {
      const { data } = params

      console.log('update', data)

      return cloudFunction('updateMachineBooking', {
        ...data,
      })
        .then((response: any) => {
          const r = response?.data || {}
          if (r?.code === 'C2000') {
            console.log('successful update of ' + data.bookingId)
          } else {
            showBookingNotification(r.code)
          }
          return response
        })
        .catch((error: {}) => {
          window.furball.error(
            'Something went wrong. Could not initialize machine booking',
          )
          return error
        })
    },
    [cloudFunction],
  )

  const approveBooking: IActions['approveBooking'] = useCallback(
    async (bookingId): Promise<any> => {
      return cloudFunction('approveMachineBooking', {
        bookingId,
      })
        .then((response: any) => {
          const r = response?.data || {}
          if (r.code && r.code !== 'C2000') {
            showBookingNotification(r.code)
          }
          return response
        })
        .catch((error: {}) => {
          window.furball.error(
            'Something went wrong. Could not completes a machine booking',
          )
          console.error(error)

          return error
        })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [cloudFunction],
  )

  const actions: IActions = useMemo(() => {
    return {
      createBooking,
      updateBooking,
      approveBooking,
      resetState,
      setBookingId,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BookingContext.Provider value={value}>
      <BookingActionsContext.Provider value={actions}>
        {children}
      </BookingActionsContext.Provider>
    </BookingContext.Provider>
  )
}

export default Provider
