import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import MuiStepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Container from '@material-ui/core/Container'

//
import { useContext } from './context'

const useStyles = makeStyles((__theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    stepperRoot: {
      backgroundColor: 'transparent',
    },
  }),
)

export default function Stepper(): React.ReactElement {
  const classes = useStyles()

  const { steps, activeStep } = useContext()

  return (
    <Container className={classes.root} maxWidth="lg">
      <MuiStepper
        className={classes.stepperRoot}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((step, i) => (
          <Step key={i}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Container>
  )
}
