import React, { useLayoutEffect, useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Switch, Route } from 'react-router'

// Material-UI
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

//
import Router from 'components/router'
import { useStyles } from './styles'
import { Routes, AuthorizedRoutes } from 'constants/routing'
import './global.css'
import { useAuthUserContext } from 'state/context/authUserContext'
import {
  useAuthContextActions,
  useAuthContext,
} from 'state/context/authContext'
import { useBookPolicy } from 'hooks/policies/useBookPolicy'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import { useSystemPolicy } from 'hooks/policies/useSystemPolicy'

//
import Signin from 'components/views/SignIn'
import SignUp from 'components/views/SignUp'

export function App(): React.ReactElement {
  const classes = useStyles()

  const [accessAllowed, setAccessAllowed] = useState(false)

  // Context
  const authUser = useAuthUserContext()
  const authData = useAuthContext()
  const authActions = useAuthContextActions()

  // Hooks
  const location = useLocation()
  const history = useHistory()
  const { bookPolicy } = useBookPolicy()
  const { sitePolicy } = useSitePolicy()
  const { systemPolicy } = useSystemPolicy()

  const uid = authData?.uid
  const isInitDataLoaded = authData?.isInitDataLoaded
  /**
   * Function to check if auth user have app access
   */
  const hasAppAccess = useCallback(() => {
    if (
      authUser?.uid &&
      (sitePolicy().accessSiteDashboard() || bookPolicy().accessBooking())
    ) {
      return true
    }

    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser])

  /**
   * Redirect to the most appropriate app section
   */
  useLayoutEffect(() => {
    const path = location.pathname.split('/').filter(item => item)

    if (authUser?.uid) {
      if (hasAppAccess()) {
        setAccessAllowed(true)

        // If invalid app section (path[0])
        if (!AuthorizedRoutes.includes(`/${path[0]}`)) {
          if (sitePolicy().accessSiteDashboard()) {
            history.replace(Routes.SITE)
          } else if (systemPolicy().accessSystemPreferences()) {
            history.replace(Routes.SYSTEM)
          } else if (bookPolicy().accessBooking()) {
            history.replace(Routes.BOOKING)
          }
        }
      } else {
        authActions.logOut()
      }
    } else if (!uid && isInitDataLoaded && `/${path[0]}` !== Routes.SIGN_UP) {
      // Not authenticated and on root. Redirect to sign-in page
      history.replace(Routes.SIGN_IN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, hasAppAccess, uid, isInitDataLoaded])

  /**
   * Inform the user about which environment it's running on
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === 'staging' &&
    !sessionStorage.getItem('environment_info_alert')
  ) {
    const r = window.confirm('You are now on a staging environment')
    if (r) {
      sessionStorage.setItem('environment_info_alert', 'hide')
    }
  }

  return authUser?.uid && accessAllowed ? (
    <>
      <Fade in={true}>
        <div id="app" className={classes.appContainer}>
          <Route component={Router} />
        </div>
      </Fade>
    </>
  ) : (
    <>
      <Switch>
        <Route exact path={Routes.SIGN_UP} component={SignUp} />
        <Route exact path={Routes.SIGN_IN} component={Signin} />
        <Route
          component={() => {
            return (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress color="primary" />
              </Box>
            )
          }}
        />
      </Switch>
    </>
  )
}
