import { TMachineBookingStatus } from 'types/machineBooking'

function getBookingStatus(
  booking: IFirestoreMachineBooking,
): TMachineBookingStatus {
  const status = booking.completed
    ? 'COMPLETED'
    : booking.canceled
    ? 'CANCELED'
    : 'DRAFT'

  return status
}

export default getBookingStatus
