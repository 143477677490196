import { Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  avatarBadge: {
    backgroundColor: theme.palette.background.paper,
  },
  userProfileButton: {
    maxWidth: '100%',
    textAlign: 'left',
    textTransform: 'none',
    justifyContent: 'flex-start',
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  userProfileMenu: {
    marginTop: 30,
    marginLeft: 10,
    minWidth: 200,
  },
}))
