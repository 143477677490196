import { useState, useCallback } from 'react'
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
} from 'react-dropzone'

export default function useFileSelector(props?: FileSelectorProps): Response {
  const [files, setFiles] = useState<IFiles>({})
  const [initFiles, setInitFiles] = useState<File[]>([])
  const shouldReturnInitFiles = props?.shouldReturnInitFiles
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const readFiles = {} as IFiles
      if (shouldReturnInitFiles) {
        setInitFiles(acceptedFiles)
      }
      acceptedFiles.forEach((file: any, i: number) => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const dataURL = reader.result as string

          const timestamp = new Date().getTime()
          const randName = `${timestamp}_${Math.random()}.${file.name.substr(
            file.name.lastIndexOf('.') + 1,
          )}`

          readFiles[file.name] = {
            name: file.name,
            randName,
            size:
              Math.round(
                ((dataURL?.length - dataURL?.split(',')[0].length) * 3) / 4,
              ) / 1024,
            dataURL,
          }

          if (i === acceptedFiles.length - 1) {
            setFiles(readFiles)
          }
        }

        reader.readAsDataURL(file)
      })
    },
    [shouldReturnInitFiles],
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: false,
    noKeyboard: true,
    accept: 'image/jpeg, image/png, image/gif',
    onDrop,
    ...props,
  })

  return [
    open,
    {
      rootProps: getRootProps(),
      inputProps: getInputProps(),
      files,
      initFiles: props?.shouldReturnInitFiles ? initFiles : undefined,
    },
  ]
}

type FileSelectorProps = {
  accept?: string
  shouldReturnInitFiles?: boolean
}

export interface IFile {
  name: string
  randName: string
  size: number
  dataURL: string
  downloadURL?: string
  __data?: any
}

export type IFiles = {
  [key: string]: IFile
}

type Response = [
  () => void,
  {
    rootProps: DropzoneRootProps
    inputProps: DropzoneInputProps
    files: IFiles
    initFiles?: File[]
  },
]
