import React, { useState } from 'react'
import format from 'date-fns/format'

// Material UI
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { IconButton } from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
import DownloadIcon from '@material-ui/icons/Visibility'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'

import Button from '@naevaas/webtastic/dist/components/Button'

//
import { IDocumentListItem } from './types'
import { useFetch } from 'hooks/useFetch'

function DocumentListItem(props: IDocumentListItem): React.ReactElement {
  const { rdeId, rde, siteId } = props

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [isArchiving, setIsArchiving] = useState(false)

  const [fetch] = useFetch()

  const openConfirmDialog = (): void => {
    setConfirmDialogOpen(true)
  }

  const closeConfirmDialog = (): void => {
    setConfirmDialogOpen(false)
  }

  /**
   * Archive the document
   */
  const archiveButtonHandler = (title: string) => {
    setIsArchiving(true)
    fetch({
      apiName: 'API',
      endpoint: 'deactivateRdeTemplate',
      data: {
        siteId,
        rdeId,
      },
    })
      .then(() => {
        window.furball.success(`Archived «${title}»`)
      })
      .catch((err) => {
        console.error(err)
        window.furball.error('Error! Could not archive.')
      })
      .finally(() => {
        setIsArchiving(false)
      })
  }

  return (
    <>
      <ListItem>
        <ListItemText
          primary={rde.title}
          secondary={`Added on ${format(
            rde.createdTimestamp.seconds * 1000,
            'dd.MM.yyyy',
          )}`}
        />
        <ListItemSecondaryAction>
          <Tooltip title="See">
            <IconButton
              href={rde.templateUrl}
              target="_blank"
              aria-label={`Open ${rde.title}`}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>

          <>
            {rde.active && (
              <Tooltip title="Archive">
                <IconButton
                  aria-label={`Archive ${rde.title}`}
                  onClick={openConfirmDialog}
                >
                  <ArchiveIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <Dialog
        open={confirmDialogOpen}
        fullHeight={false}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={closeConfirmDialog}
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Archive the document «{rde.title}»?
          </DialogContentText>
          <DialogContentText>This can not be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog}>Cancel</Button>
          <Button
            color="primary"
            onClick={() => archiveButtonHandler(rde.title)}
            isWaiting={isArchiving}
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DocumentListItem
