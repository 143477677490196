export enum Routes {
  HOME = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  SETTINGS = '/settings',
  CHAT = '/chat',
  SITE = '/site',
  SITES = '/sites',
  SYSTEM = '/system',
  SALESTOOL = '/system/salestool',
  COMPANIES = '/system/salestool/companies',
  CATEGORIES = '/system/salestool/categories',
  BOOKING = '/booking',
}

export const routes = {
  SITES: {
    ROOT: '/sites',
    NEW: '/sites/new',
  },
}

export enum Modals {
  REQUIREMENTS = 'REQUIREMENTS',
  SITE_ACCESS = 'SITE_ACCESS',
  CREW_LIST = 'CREW_LIST',
  EDIT_SITE = 'EDIT_SITE',
  EMPLOYEE_ROLES = 'EMPLOYEE_ROLES',
  ARCHIVE_SITE = 'ARCHIVE_SITE',
  CONFIG_CODE = 'CONFIG_CODE',
  SWITCH_COMPANY = 'SWITCH_COMPANY',
}

export const AuthorizedRoutes: string[] = [
  //Routes.CHAT,
  Routes.SITE,
  Routes.SITES,
  Routes.SYSTEM,
  Routes.SALESTOOL,
  Routes.COMPANIES,
  Routes.CATEGORIES,
  Routes.BOOKING,
]
