import { useCallback, useState } from 'react'
import firebase from 'firebase/app'

//
import {} from 'types/machineBooking'
import { IFetch, TResponse } from './types'

export const useCloudFunction = (): TResponse => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{} | null>(null)
  const [error, setError] = useState<{} | null>(null)

  const fetch = useCallback<IFetch>(
    (actionName: string, requestParams?: any) => {
      setLoading(true)

      // Remove undefined
      const cleanedParams = Object.keys(requestParams)
        .filter(k => requestParams[k] !== undefined)
        .reduce((a, k) => ({ ...a, [k]: requestParams[k] }), {})

      const callable = firebase
        .app()
        .functions('europe-west1')
        .httpsCallable(actionName)

      return callable(cleanedParams || {})
        .then(response => {
          if (response?.data?.code === 'C2000') {
            setData(response)
          }
          return response
        })
        .catch(error => {
          setError(error)
          return error
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [],
  )

  const result = {
    loading,
    data,
    error,
  }

  return [fetch, result]
}
