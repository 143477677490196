import React, { ReactElement, useCallback, useEffect } from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'

// Webtastic
import TextFieldWithValidation, {
  useContext as useValidationContext,
} from '@naevaas/webtastic/dist/components/TextFieldValidation'

//
import { useContext, useContextDispatch } from '../context'
import { useContext as useGoogleMapContext } from 'components/common/GoogleMap'

const Address = (): ReactElement => {
  const { address } = useContext()
  const { errors } = useValidationContext()
  const dispatch = useContextDispatch()
  const { placeDetails } = useGoogleMapContext()

  /**
   * Set default values based on the selection in Google location search
   */
  useEffect(() => {
    let street = ''
    let city = ''
    let zip = ''
    let county = ''
    let country = ''

    if (placeDetails?.route !== '') {
      street = `${placeDetails?.route} ${placeDetails?.streetNumber}`
    }

    /**
     * Set city depending on how narrow the search is
     */
    if (placeDetails?.postalTown !== '') {
      city = `${placeDetails?.postalTown}`
    } else if (placeDetails?.city !== '') {
      city = `${placeDetails?.city}`
    } else if (placeDetails?.locality !== '') {
      city = `${placeDetails?.locality}`
    } else if (placeDetails?.colloquialArea !== '') {
      city = `${placeDetails?.colloquialArea}`
    }

    zip = `${placeDetails?.postalCode}`
    county = `${placeDetails?.county}`
    country = `${placeDetails?.country}`

    dispatch('SET_ADDRESS', {
      address: {
        street: [street],
        city,
        zip,
        county,
        country,
      },
    })
  }, [dispatch, placeDetails])

  const setAddress = useCallback(
    (
      propName: string,
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const value = e.target.value
      let newValue: string | string[] = value
      if (propName === 'street') {
        newValue = [value]
      }
      dispatch('SET_ADDRESS', {
        address: { ...address, [propName]: newValue },
      })
    },
    [address, dispatch],
  )

  return (
    <>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TextFieldWithValidation
            id="address-street"
            label="Address (street)"
            value={address?.street?.[0] || ''}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ): void => {
              setAddress('street', e)
            }}
            errors={errors?.['address.street'] ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <TextFieldWithValidation
            id="addrcty"
            label="City"
            value={address?.city || ''}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ): void => {
              setAddress('city', e)
            }}
            errors={errors?.['address.city'] ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextFieldWithValidation
            id="address-postal-code"
            label="Postal code"
            value={address?.zip || ''}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ): void => {
              setAddress('zip', e)
            }}
            errors={errors?.['address.zip'] ?? null}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextFieldWithValidation
            id="address-county"
            label="County/region"
            value={address?.county || ''}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ): void => {
              setAddress('county', e)
            }}
            required={false}
            errors={errors?.['address.county'] ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWithValidation
            id="address-country"
            label="Country"
            value={address?.country || ''}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ): void => {
              setAddress('country', e)
            }}
            errors={errors?.['address.country'] ?? null}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Address
