import React, { useState, useEffect } from 'react'

import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'

//
import { useStyles } from './styles'
import { TCheckType } from 'types/site'
import nameToInitials from 'utils/nameToInitials'

interface IActivityProps {
  action: string
  checkType: TCheckType
  site: string
  userName: string
  userAvatarUrl?: string
  company: string
  date: any
  displayText?: string
  animate: boolean
}

const checkedBy = {
  refero: 'refero',
  proxy: 'position',
  manual: 'manually',
  bssId: 'BSS',
}

function Activity(props: IActivityProps): React.ReactElement {
  const {
    action,
    checkType,
    site,
    userName,
    userAvatarUrl,
    company,
    date,
    displayText,
    animate,
  } = props

  const [show, setShow] = useState(!animate)

  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formatHours = hours < 10 ? `0${hours}` : hours
  const formatMinutes = minutes < 10 ? `0${minutes}` : minutes

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 100)
  }, [])

  return (
    <Collapse in={show} timeout={500}>
      <Paper elevation={2} className={classes.paper}>
        <Box padding={1}>
          <div>
            <div className={classes.activitiesBlock}>
              <span>
                {displayText
                  ? displayText
                  : `${action} ${checkType ? 'BY' + checkedBy[checkType] : ''}`}
              </span>
              <span
                className={classes.dateBlock}
              >{`${formatHours}:${formatMinutes}`}</span>
            </div>
          </div>
          <div className={classes.siteBlock}>{site}</div>
          <div className={classes.userBlock}>
            <Avatar alt={userName} src={userAvatarUrl}>
              {nameToInitials(userName)}
            </Avatar>
            <div className={classes.userDescription}>
              <span className={classes.userName}>{userName}</span>
              <span className={classes.userCompany}>
                <Tooltip title={company.length > 25 ? company : ''}>
                  <span>{company}</span>
                </Tooltip>
              </span>
            </div>
          </div>
        </Box>
      </Paper>
    </Collapse>
  )
}

export default React.memo(Activity)
