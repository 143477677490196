import React, { ReactElement, useEffect } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

// Common
import {
  LoadScript,
  IGoogleAutocompletePlace,
} from 'components/common/GoogleMap'
import {
  useContext,
  useContextDispatch,
} from 'components/common/GoogleMap/context'
import Search from 'components/common/GoogleMap/Search'
import {
  useContext as useStepperContext,
  useContextDispatch as useStepperContextActions,
} from 'components/common/Stepper/context'

//
import SelectSiteCenter from './SelectSiteCenter'

const lib = ['places']

const SelectLocation = (): ReactElement => {
  // Stepper context
  const { activeStep } = useStepperContext()
  const stepperDispatch = useStepperContextActions()

  // Map context
  const state = useContext()
  const polygonHistoryIndex = state.polygonHistory?.index
  const dispatch = useContextDispatch()

  const selectPlace = (place: IGoogleAutocompletePlace): void => {
    dispatch('SET_SELECTED_PLACE', { place })
    dispatch('RESET_POLYGON')
    stepperDispatch('SET_STEP_VALIDATION', { index: 0, validated: !!place })
  }

  /**
   * Set stepValidation of "Outline site" (step 2)
   */
  useEffect(() => {
    let validated = false
    if (polygonHistoryIndex) validated = true

    stepperDispatch('SET_STEP_VALIDATION', { index: 1, validated })
  }, [polygonHistoryIndex, stepperDispatch])

  return (
    <Box>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
        libraries={lib}
      >
        <Box display={activeStep === 0 ? 'block' : 'none'}>
          <Box mt={2}>
            <Typography color="textSecondary">
              Search for address or nearest known location for your project.
            </Typography>
          </Box>
          <Box my={2} maxWidth={500}>
            <Search selectPlace={selectPlace} />
          </Box>
        </Box>
        {state.selectedPlace ? (
          <SelectSiteCenter
            placeId={state.selectedPlace.place_id}
            enablePolygon={activeStep === 1}
          />
        ) : null}
      </LoadScript>
    </Box>
  )
}

export default SelectLocation
