import React from 'react'

// Material-UI
import PlusIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

//
import { ISelectFile } from './types'
import { useStyles } from './styles'
import useFileSelector from 'utils/fileSelector'
import { useEffect } from 'react'

function SelectFile(props: ISelectFile): React.ReactElement {
  const { setFile } = props

  const [
    ,
    {
      rootProps: fileSelectorRootProps,
      inputProps: fileSelectorInputProps,
      files: fileSelectorFiles,
    },
  ] = useFileSelector({
    accept: 'application/pdf',
  })

  const classes = useStyles()

  useEffect(() => {
    if (Object.keys(fileSelectorFiles)?.length) {
      const file = fileSelectorFiles[Object.keys(fileSelectorFiles)[0]]

      setFile(file)
    }
  }, [fileSelectorFiles, setFile])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      {...fileSelectorRootProps}
      className={classes.dropZone}
    >
      <label htmlFor="myFile">
        <PlusIcon className={classes.dropZoneIcon} />
      </label>
      <Typography variant="body1">
        <strong>Choose a PDF file</strong> or drag it here
      </Typography>
      <input {...fileSelectorInputProps} multiple={false} />
    </Box>
  )
}

export default SelectFile
