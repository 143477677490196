import { useCallback } from 'react'

//
import { useAuthUserContext } from 'state/context/authUserContext'

export const useConfigCodePolicy = (): IUseConfigCodePolicy => {
  const authUser = useAuthUserContext()

  /**
   * Override policy if user is systemAdmin
   */
  const before = useCallback(() => {
    // TODO: add permission check
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser])

  const configCodePolicy: IUseConfigCodePolicy['configCodePolicy'] = useCallback(() => {
    return {
      create: (): boolean => {
        if (before()) return true

        if (
          authUser?.isSuperUser ||
          authUser?.isCompanyAdmin ||
          authUser?.isSiteAdmin
        )
          return true

        return false
      },
      /*
      view: (item: any): boolean => {
        // rules...
        return false
      },
      update: (item: any): boolean => {
        // rules...
        return false
      },
      delete: (item: any): boolean => {
        // rules...
        return false
      }, */
    }
  }, [authUser, before])

  return { configCodePolicy }
}

interface IUseConfigCodePolicy {
  configCodePolicy: () => {
    create: () => boolean
  }
}
