import React, { ReactElement } from 'react'

// Material UI
import { makeStyles, Theme } from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

//
import { IAppBar } from './types'
import ElevationScroll from './ElevationScroll'

const useStyles = makeStyles<Theme, { prominent: boolean }>(theme => {
  return {
    root: {
      top: 0,
      position: 'sticky',
      backgroundColor: theme.palette.background.default,
    },
    upperContent: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    lowerContent: {
      minHeight: 0,
    },
  }
})

const AppBar = (props: IAppBar): ReactElement => {
  const {
    leftActions,
    title,
    actions,
    upperContent,
    upperContentProps: {
      classes: upperContentClasses,
      ...upperContentPropsRest
    } = {},
    lowerContent,
    children,
    ...rest
  } = props

  const prominent = !!lowerContent

  // Hooks
  const classes = useStyles({ prominent })

  return (
    <>
      <ElevationScroll>
        <MuiAppBar
          color="inherit"
          classes={{
            root: classes.root,
          }}
          {...rest}
        >
          <Toolbar
            classes={{
              root: `${classes.upperConent} ${upperContentClasses?.root}`,
            }}
            {...upperContentPropsRest}
          >
            {leftActions && (
              <Box mr={1}>
                {leftActions.map(action => {
                  return action
                })}
              </Box>
            )}

            {upperContent ? (
              <Box>{upperContent}</Box>
            ) : title ? (
              <>
                <Typography variant="h6" component="h1" noWrap>
                  {title}
                </Typography>
                <Box flex="auto" />
                {actions &&
                  actions.map(action => {
                    return action
                  })}
              </>
            ) : (
              ''
            )}
          </Toolbar>

          {lowerContent && (
            <Toolbar classes={{ root: classes.lowerContent }}>
              {lowerContent}
            </Toolbar>
          )}
        </MuiAppBar>
      </ElevationScroll>
    </>
  )
}

export default AppBar
