import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

//
import Root from './BookingRoot'
import { Routes } from 'constants/routing'

const BookingRouter = (): React.ReactElement => {
  return (
    <Switch>
      <Route
        exact
        path={[
          `${Routes.BOOKING}`,
          `${Routes.BOOKING}/:siteId/:page(machines|my-bookings)`,
        ]}
        component={Root}
      />

      <Redirect to={Routes.BOOKING} />
    </Switch>
  )
}

export default BookingRouter
