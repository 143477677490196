import React, { ReactElement, useState, useCallback, useEffect } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
} from '@naevaas/webtastic/dist/components/Dialog'
import Autocomplete, {
  IAutocompleteOption,
} from '@naevaas/webtastic/dist/components/Autocomplete'

//
import { IAddEmployee } from './types'
import { DialogActions } from '@material-ui/core'
import useDebounce from 'hooks/useDebounce'
import { useCloudFunction } from 'hooks/useCloudFunctions'
import { IUserProfile } from 'types/user'
import { ISearchUser } from 'hooks/useCloudFunctions/types/searchUser'

type _IAutoCompleteOption = IAutocompleteOption<
  ISearchUser['serverResponse']['data']['users'][0]
>

const AddEmployee = (props: IAddEmployee): ReactElement => {
  const { open, onClose, onAddUser, company } = props
  const [keyword, setKeyword] = useState<string | null>(null)
  const [selectedUser, setSelectedUser] = useState<IUserProfile | null>(null)
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    _IAutoCompleteOption[] | null
  >(null)
  const [isFetching, setIsFetching] = useState(false)

  const [fetch] = useCloudFunction()
  const debouncedKeyword = useDebounce(keyword, 250)

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setKeyword(e.target.value)
  }

  const handleSelectionChange = (
    selection: _IAutoCompleteOption[],
    reason: string,
  ): void => {
    if (reason === 'select-option') {
      const u = selection[0].data
      u &&
        setSelectedUser({
          id: u.id,
          name: u.name,
          imageThumbUrl: u.imageThumbUrl,
        })
    }
  }

  const handleNext = useCallback(() => {
    selectedUser && onAddUser(selectedUser)
  }, [selectedUser, onAddUser])

  useEffect(() => {
    if (debouncedKeyword) {
      setIsFetching(true)
      fetch('searchUser', {
        searchString: debouncedKeyword.trim(),
        employeeSearch: company.id,
      })
        .then(response => {
          const users = response?.data.users

          if (users) {
            const options: _IAutoCompleteOption[] = []
            users.forEach(user => {
              options.push({
                id: user.id,
                avatarUrl: user.imageThumbUrl ?? '',
                label: user.name,
                primaryText: user.name,
                secondaryText: undefined, //user.companyName, No point in showing companyName when using company filter in the query
                data: user,
              })
            })
            setAutocompleteOptions(options)
          }
        })
        .finally(() => {
          setIsFetching(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword])

  return (
    <Dialog open={open} onClose={onClose} fullHeight={false} maxWidth="sm">
      <DialogTitle>Find employee</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <Autocomplete
            onInputChange={handleInputChange}
            onSelectionChange={handleSelectionChange}
            multiple={false}
            options={autocompleteOptions || undefined}
            isFetching={isFetching}
            textFieldProps={{
              placeholder: `Search by name`,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!selectedUser} color="primary" onClick={handleNext}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddEmployee
