import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogTitle: {
      textAlign: 'center',
    },
    inputControlBlock: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    imageContainer: {
      marginBottom: theme.spacing(2),
    },
    radioControlBlock: {
      width: '100%',
      marginBottom: theme.spacing(2),
      alignItems: 'flex-start',
    },
    controlBlock: {
      marginBottom: theme.spacing(2),
      display: 'flex',
    },
    controlBlockWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    controlBlockDesc: {
      display: 'flex',
      alignItems: 'center',
    },
    controlBlockLabel: {
      marginRight: theme.spacing(),
    },
    input: {
      maxWidth: '60px',
      marginRight: theme.spacing(),
    },
    checkboxControlBlock: {
      width: '100%',
      alignItems: 'flex-start',
    },
    selectControlLabel: {
      marginBottom: theme.spacing(),
    },
    errorLabel: {
      fontSize: '12px',
      color: theme.palette.error.main,
    },
  }
})
