import { useReducer as _useReducer, useCallback } from 'react'

import { IState, TAction, IDispatch } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_DEFAULT': {
      return {
        ...state,
        defaultCompany: {
          company: action.company,
        },
      }
    }

    case 'SET_SELECTED': {
      return {
        ...state,
        selectedCompany: {
          company: action.company,
          //companyPermissions: action.companyPermissions,
        },
      }
    }

    case 'SELECT_DEFAULT': {
      return {
        ...state,
        selectCompanyById: state.defaultCompany?.company.id,
      }
    }

    case 'SELECT_COMPANY_BY_ID': {
      return {
        ...state,
        selectCompanyById: action.id,
      }
    }

    default:
      return state
  }
}

export const useReducer = (initialState: IState): [IState, IDispatch] => {
  const [state, _dispatch] = _useReducer(reducer, initialState)

  const dispatch: IDispatch = useCallback(
    (type: string, action?: any): void => {
      const _action = { type, ...action } as TAction
      _dispatch(_action)
    },
    [_dispatch],
  )

  return [state, dispatch]
}
