import React, { ReactElement } from 'react'

// Material-UI
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

// Webtastic
import { DialogContentText } from '@naevaas/webtastic/dist/components/Dialog'

//
import { IMachineDetails } from './types'

function createData(name: string, value = ''): { name: string; value: string } {
  return { name, value }
}

const MachineDetails = (props: IMachineDetails): ReactElement => {
  const { machine } = props

  const rows = [
    createData('Make', (machine?.make || '') as string),
    createData('Model', (machine?.model || '') as string),
    createData('Owner (company)', (machine?.ownerCompanyName || '') as string),
    createData('Needs charging', machine?.needsCharging ? 'yes' : 'no'),
  ]

  return (
    <>
      {(machine?.imageThumbUrl || machine?.imageUrl) && (
        <CardMedia
          component="img"
          height="350"
          image={machine?.imageUrl || machine?.imageThumbUrl || undefined}
          aria-hidden="true"
        />
      )}

      <Box my={3} mx={2}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DialogContentText />
      </Box>
    </>
  )
}

export default MachineDetails
