import { IState, TAction } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_ACTIVE_STEP': {
      const newActiveStep = action.index

      return {
        ...state,
        activeStep: newActiveStep,
        completed: newActiveStep === state.steps.length,
      }
    }

    case 'SET_STEP_VALIDATION': {
      const newSteps = [...state.steps]
      newSteps[action.index] = {
        ...newSteps[action.index],
        validated: action.validated,
      }

      return {
        ...state,
        steps: newSteps,
      }
    }

    default:
      return state
  }
}
