import React, { useCallback, useState } from 'react'

// Material-UI
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import ArchiveIcon from '@material-ui/icons/MoveToInbox'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import { useContext as useSiteContext } from 'state/context/siteContext'
import useArchiveSite from 'hooks/useArchiveSite'
import { IArchiveSiteDialog } from './types'
import { Typography } from '@material-ui/core'

function ArchiveSiteWarning(props: IArchiveSiteDialog): React.ReactElement {
  const {
    onClose = (): void => {
      // do nothing
    },
    changeSelectedSite,
  } = props

  const [openState, setOpenState] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)

  // Context
  const { selectedSite } = useSiteContext()
  const [archiveSite] = useArchiveSite()

  const handleValueChange = useCallback(e => {
    setInputValue(e.target.value)
  }, [])

  const handleArchiveSite = (): void => {
    setShowSpinner(true)
    selectedSite &&
      archiveSite({ siteId: selectedSite.id, forceCheckOut: true }).then(() => {
        setShowSpinner(false)
        onClose()
        changeSelectedSite()
      })
  }

  if (!selectedSite) return <></>

  return (
    <Dialog
      open={openState}
      onClose={(): void => {
        setOpenState(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      titleIcon={<ArchiveIcon />}
      fullHeight={false}
      maxWidth="sm"
    >
      <DialogTitle>Archive site</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Alert severity="error" variant="outlined">
            <AlertTitle>This action CANNOT be undone.</AlertTitle>
            This will permanently archive the site{' '}
            <strong style={{ whiteSpace: 'nowrap' }}>
              {selectedSite.name}
            </strong>{' '}
            and check out all workers.
          </Alert>
          <br />

          <Typography>
            Please type in the name of the site to confirm.
          </Typography>
          <br />
        </DialogContentText>
        <TextField
          error={!!inputValue && selectedSite.name !== inputValue}
          id="archive-input"
          label="Site name"
          variant="outlined"
          onChange={handleValueChange}
          style={{ width: '100%' }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={handleArchiveSite}
          disabled={selectedSite.name !== inputValue || showSpinner}
          isWaiting={showSpinner}
        >
          Archive the site
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ArchiveSiteWarning
