import { useCloudFunction } from 'hooks/useCloudFunctions'
import { useCallback } from 'react'

const useUpdateWhiteList = () => {
  const [cloudFunction] = useCloudFunction()

  const update: TUpdate = useCallback(
    async ({ gateKeeperId, userIds, action }) => {
      const req =
        action === 'ADD'
          ? cloudFunction('updateGateKeeperConfig', {
              gateKeeperId,
              addUserIdsToWhiteList: userIds,
            })
          : cloudFunction('updateGateKeeperConfig', {
              gateKeeperId,
              removeUserIdsFromWhiteList: userIds,
            })

      req
        .then((response) => {
          if (response.data.code === 'C2000') {
            window.furball.success('The door was updated')
          } else {
            window.furball.error('Error! the door was not updated')
          }
        })
        .catch(() => {
          window.furball.error('Error! The door was not updated')
        })

      return req
    },
    [cloudFunction],
  )

  return update
}

interface IUpdateProps {
  gateKeeperId: string
  userIds: string[]
  action: 'ADD' | 'REMOVE'
}

type TUpdate = (props: IUpdateProps) => Promise<any>

export default useUpdateWhiteList
