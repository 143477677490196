import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'

import home from './home'
import user from './user'
import layout from 'state/reducers/layout'

const foo = (history: any) =>
  combineReducers({
    home,
    router: connectRouter(history),
    user,
    layout,
  })
export default foo
