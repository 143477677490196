import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    image: {
      height: '100%',
      display: 'block',
      margin: '0 auto',
    },
    imageContainer: {
      height: '200px',
      maxWidth: '400px',
      overflow: 'hidden',
      margin: `${theme.spacing()}px auto`,
    },
    cardsContainer: {
      marginTop: theme.spacing(2),
      overflowY: 'auto',
    },
    wrap: {
      height: '100%',
    },
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    editButton: {
      margin: 'auto 0 0 0',
    },
    createButton: {
      alignSelf: 'center',
    },
    pageTitle: {
      margin: `${theme.spacing()}px 0`,
      textTransform: 'uppercase',
    },
  }
})
