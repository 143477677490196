import { IState, TAction, IPolygonState } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_SELECTED_PLACE': {
      return { ...state, selectedPlace: action.place }
    }

    case 'SET_CENTER_POSITION': {
      return {
        ...state,
        centerPosition: action.position,
      }
    }

    case 'SET_ZOOM': {
      return {
        ...state,
        zoom: action.zoom,
      }
    }

    case 'SET_PLACE_DETAILS': {
      return {
        ...state,
        placeDetails: action.details,
      }
    }

    case 'SET_POLYGON_PATH': {
      const newPath = action.path
      const polygonHistoryState = state?.polygonHistory
      const snapshots = polygonHistoryState?.snapshots
        ? [...polygonHistoryState?.snapshots]
        : []
      const historyIndex = polygonHistoryState?.index || 0

      const newPolygonState = {
        ...state?.polygon,
        path: newPath,
      }

      // Remove future states from `history`
      if (historyIndex < snapshots.length - 1)
        snapshots.splice(historyIndex + 1)
      const splicedSnapshots = snapshots

      const newSnapshots = splicedSnapshots
        ? [...splicedSnapshots, newPolygonState]
        : [newPolygonState]

      return {
        ...state,
        polygon: newPolygonState,
        polygonHistory: {
          snapshots: newSnapshots,
          index: newSnapshots.length - 1,
        },
      }
    }

    case 'SET_BUFFER_DISTANCE': {
      const polygonHistoryState = state?.polygonHistory
      const snapshots = polygonHistoryState?.snapshots
        ? [...polygonHistoryState?.snapshots]
        : []
      const historyIndex = polygonHistoryState?.index || 0

      const newPolygonState = {
        ...state?.polygon,
        bufferDistance: action.distance,
      } as IPolygonState

      // Remove future states from `history`
      if (historyIndex < snapshots.length - 1)
        snapshots.splice(historyIndex + 1)
      const splicedSnapshots = snapshots

      const newSnapshots = splicedSnapshots
        ? [...splicedSnapshots, newPolygonState]
        : [newPolygonState]

      return {
        ...state,
        polygon: newPolygonState,
        polygonHistory: {
          snapshots: newSnapshots,
          index: newSnapshots.length - 1,
        },
      }
    }

    case 'RESET_POLYGON': {
      return {
        ...state,
        polygon: undefined,
        polygonHistory: undefined,
      }
    }

    case 'POLYGON_HISTORY_BACK': {
      const polygonHistoryState = state?.polygonHistory
      const snapshots = polygonHistoryState?.snapshots
        ? [...polygonHistoryState?.snapshots]
        : []
      const historyIndex = polygonHistoryState?.index || 0

      // New history index
      let newHistoryIndex = historyIndex - 1
      if (newHistoryIndex < 0) newHistoryIndex = 0

      // Get snapshot from the new history index
      const snapshot = snapshots?.[newHistoryIndex]

      // Set path from snapshot
      if (snapshot) {
        return {
          ...state,
          polygon: snapshot,
          polygonHistory: {
            snapshots,
            index: newHistoryIndex,
          },
        }
      }

      return {
        ...state,
      }
    }

    case 'POLYGON_HISTORY_FORWARD': {
      const polygonHistoryState = state?.polygonHistory
      const snapshots = polygonHistoryState?.snapshots
        ? [...polygonHistoryState?.snapshots]
        : []
      const historyIndex = polygonHistoryState?.index || 0

      // New history index
      let newHistoryIndex = historyIndex + 1
      if (newHistoryIndex > snapshots.length - 1)
        newHistoryIndex = snapshots.length - 1

      // Get snapshot from the new history index
      const snapshot = snapshots?.[newHistoryIndex]

      // Set path from snapshot
      if (snapshot) {
        return {
          ...state,
          polygon: snapshot,
          polygonHistory: {
            snapshots,
            index: newHistoryIndex,
          },
        }
      }

      return {
        ...state,
      }
    }

    case 'RESET_POLYGON_HISTORY': {
      const polygonHistoryState = state?.polygonHistory
      const lastSnapshot = polygonHistoryState?.snapshots?.pop()
      const snapshots = lastSnapshot ? [lastSnapshot] : []

      return {
        ...state,
        polygonHistory: {
          snapshots,
          index: 0,
        },
      }
    }

    default:
      return state
  }
}
