import React, { useState } from 'react'

// Material-UI
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'

//
import { useStyles } from './styles'
import { Modals } from 'constants/routing'
import SiteAccessDialog from 'sections/siteAdmin/components/SiteAccessDialog'
import EditSiteDialog from 'sections/siteAdmin/components/EditSiteDialog'
import RequirementsDialog from 'sections/siteAdmin/components/RequirementsDialog'
import CrewListDialog from 'sections/siteAdmin/components/CrewListDialog'

import siteActions, { ISiteAction } from './siteActions'

function Actions(): React.ReactElement {
  const [openDialog, setOpenDialog] = useState<Modals | null>(null)

  const classes = useStyles()

  function closeDialog(): void {
    setOpenDialog(null)
  }

  return (
    <>
      <Grid container spacing={3}>
        {siteActions.map((item: ISiteAction, i: number) => {
          return (
            <Grid key={i} item xs={12} md={6} lg={4}>
              <Card elevation={2} style={{ height: '100%' }}>
                <CardActionArea
                  component="button"
                  onClick={(): void => {
                    setOpenDialog(item.key)
                  }}
                  style={{ height: '100%' }}
                >
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <Avatar
                        variant="square"
                        aria-hidden="true"
                        className={classes.avatar}
                      >
                        {item.icon}
                      </Avatar>
                    }
                    title={item.title}
                    subheader={item.description}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>

      {openDialog === Modals.SITE_ACCESS && (
        <SiteAccessDialog onClose={closeDialog} />
      )}

      {openDialog === Modals.EDIT_SITE && (
        <EditSiteDialog onClose={closeDialog} />
      )}
      {openDialog === Modals.REQUIREMENTS && (
        <RequirementsDialog onClose={closeDialog} />
      )}
      {openDialog === Modals.CREW_LIST && (
        <CrewListDialog onClose={closeDialog} />
      )}
    </>
  )
}

export default Actions
