import React, { useEffect, useState } from 'react'

// Material UI
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CrewListIcon from '@material-ui/icons/FormatListBulleted'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@naevaas/webtastic/dist/components/Dialog'

//
import { CrewListData } from 'state/actions/site'
import useGetCrewList from 'hooks/useGetCrewList'
import CrewMember from './CrewMember'
import Form from './Form'
import { ICrewList, ICrewMember } from './types'
import { ICrewMembersDialog } from './types'
import { useContext as useSiteContext } from 'state/context/siteContext'

function CrewListDialog(props: ICrewMembersDialog): React.ReactElement {
  const {
    open = true,
    onClose = (): void => {
      // do nothing
    },
  } = props

  const [openState, setOpenState] = useState(true)

  // Context
  const { selectedSite } = useSiteContext()

  // Hooks
  const [fetch, { data: crewListData }] = useGetCrewList()

  const formattedCrewListData = {} as ICrewList

  crewListData.forEach((v: CrewListData, _i: number) => {
    const uid = v.data.user.id

    if (!formattedCrewListData[uid]) {
      formattedCrewListData[uid] = {
        user: v.data.user as ICrewMember,
        checkedInDuration: 0 as number,
        sessions: [],
      }
    }
    formattedCrewListData[uid].sessions.push(v)
    formattedCrewListData[uid].checkedInDuration =
      formattedCrewListData[uid].checkedInDuration +
      (v?.data?.totalTimeSeconds || 0) * 1000
  })

  useEffect(() => {
    setOpenState(open)
  }, [open])

  if (!selectedSite) return <></>

  return (
    <Dialog
      open={openState}
      onClose={(): void => {
        setOpenState(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      titleIcon={<CrewListIcon fontSize="large" />}
    >
      <DialogTitle>Crew List</DialogTitle>
      <DialogContent>
        <DialogContentText>Site: {selectedSite.name} </DialogContentText>
        <DialogContentText>{`List of crew members check in/out from site`}</DialogContentText>
        <Form fetch={fetch} />

        {/* <div className={clsx(classes.listContainer, 'styled-scrollbar')} onScroll={handleScroll}> */}

        {Object.keys(formattedCrewListData).length > 0 ? (
          <>
            <Box textAlign="right" mb={1} mr={'53px'}>
              <Typography
                variant="caption"
                color="textSecondary"
                component="div"
              >
                Time checked in
              </Typography>
            </Box>
            {Object.keys(formattedCrewListData).map(i => {
              return <CrewMember key={i} data={formattedCrewListData[i]} />
            })}
          </>
        ) : (
          <Box textAlign="center" p={2}>
            <Box pt={3}>
              <Typography variant="h5" color="textSecondary">
                No matching check-ins
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography color="textSecondary">
                Try another Time range or keyword
              </Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CrewListDialog
