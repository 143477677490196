import React, { useEffect, useState } from 'react'

// Material-UI
//import Box from '@material-ui/core/Box'

//
import RecentActivity from 'sections/siteAdmin/components/RecentActivity'
//import ChatBubble from 'components/common/ChatBubble'
import MenuDrawer from 'components/views/MenuDrawer'

import AuthLayout from 'components/layout/AuthLayout'
import {
  Provider as SiteContextProvider,
  useContext as useSiteContext,
} from 'state/context/siteContext'
import SiteDashboard from '../pages/SiteDashboard'
import { IAllSites, ISiteAdminRoot } from './types'
import { Routes } from 'constants/routing'
import AllSites from '../pages/AllSites'
import { useSiteContextHandler } from './useSiteContextHandler'

function SiteAdminRoot(props: ISiteAdminRoot | IAllSites): React.ReactElement {
  const [bgImageSrc, setBgImageSrc] = useState<string | null>(null)
  const [bgMode, setBgMode] = useState<'dark' | 'light' | null>(null)

  useSiteContextHandler()
  const { selectedSite } = useSiteContext()

  const section = `/${
    props.location.pathname.split('/').filter((item) => item)[0]
  }`

  useEffect(() => {
    if (section === Routes.SITE) {
      if (selectedSite?.mapImageDarkModeUrl) {
        setBgImageSrc(
          `${selectedSite.mapImageDarkModeUrl}&${selectedSite.updatedTimestamp}`,
        )
        setBgMode('dark')
      } else if (selectedSite?.mapImageUrl) {
        setBgImageSrc(
          `${selectedSite.mapImageUrl}&${selectedSite.updatedTimestamp}`,
        )
        setBgMode('light')
      }
    } else {
      setBgImageSrc(null)
      setBgMode(null)
    }
  }, [section, selectedSite])

  return (
    <AuthLayout
      bgImageSrc={bgImageSrc || undefined}
      bgMode={bgMode || undefined}
      menuComponent={
        <MenuDrawer appSection={section === Routes.SITE ? 'site' : ''}>
          {section === Routes.SITE ? <RecentActivity /> : <div />}
        </MenuDrawer>
      }
      contentComponent={
        section === Routes.SITE ? (
          <SiteDashboard {...(props as ISiteAdminRoot)} />
        ) : (
          <AllSites {...(props as IAllSites)} />
        )
      }
    >
      {/* <Box position="fixed" bottom="1vh" right="1vh">
        <ChatBubble />
      </Box> */}
    </AuthLayout>
  )
}

const SiteAdminRootWithContext = (
  props: ISiteAdminRoot,
): React.ReactElement => {
  return (
    <SiteContextProvider>
      <SiteAdminRoot {...props} />
    </SiteContextProvider>
  )
}

export default SiteAdminRootWithContext
