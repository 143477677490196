import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    pagination: {
      marginTop: 'auto',
    },
    selectedRow: {
      '&.MuiTableRow-root, &.MuiTableRow-root:hover': {
        backgroundColor: `${theme.palette.primary.main}30`,
      },
    },
    toolbar: {
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      paddingRight: '40px',
    },
  }
})
