import { useCallback, useState } from 'react'
import firebase from 'firebase/app'

import { serverErrors } from 'constants/error'
import { useAuthContext } from 'state/context/authContext'

export const useFetch = (): Response => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  // Auth context
  const authData = useAuthContext()
  const token = authData?.token

  const request = useCallback(
    async (props: IRequest): Promise<void> => {
      setLoading(true)
      const { apiName, endpoint, data } = props

      let currentTokenId = token
      let url = ''
      if (!apiName || apiName === 'API')
        url = `${process.env.REACT_APP_API_BASE_URL}api`
      else if (apiName === 'API2') {
        url = `${process.env.REACT_APP_API_BASE_URL}api2`
        currentTokenId = process.env.REACT_APP_API2_TOKEN
      } else if (apiName === 'MACHINE_BOOKING') {
        url = `${process.env.REACT_APP_API_BASE_URL}apiMachineBooking`
      }

      // API2 is public. don't check auth
      // Other APIs need auth check
      const preRequest =
        apiName === 'API2'
          ? new Promise((resolve, _reject) => {
              resolve('foo')
            })
          : firebase
              .auth()
              ?.currentUser?.getIdToken(true)
              .then((_res: any) => {
                // Authentication is confimed
                console.log('Authentication confirmed')
              })
              .catch(error => {
                console.log('Authentication failed', error)
              })

      const response = await preRequest?.then(async () => {
        try {
          const res = await fetch(`${url}/${endpoint}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              Authorization: 'Bearer ' + currentTokenId,
              'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
          })
          const json = await res.json()

          setLoading(false)
          setData(json)

          switch (res.status) {
            case 200: {
              return json
            }
            case 400: {
              throw serverErrors.BAD_REQUEST
            }
            case 401: {
              throw serverErrors.UNAUTHORIZED
            }
            case 404: {
              throw serverErrors.NOT_FOUND
            }
            case 500: {
              throw serverErrors.INTERNAL_ERROR
            }
          }

          return json
        } catch (error) {
          setLoading(false)
          setError(error)
          throw error
        }
      })

      return response
    },
    [token],
  )

  const result = {
    loading,
    data,
    error,
  }

  return [request, result]
}

interface IRequest {
  /** Name of API. Optionally add more */
  apiName?: 'API' | 'API2' | 'MACHINE_BOOKING'
  /** Endpoint e.g.: addChatMessage */
  endpoint: string
  /** The data to send */
  data: object
}

type Response = [
  (props: IRequest) => Promise<any>,
  { loading: boolean; data: object | null; error: object | null },
]
