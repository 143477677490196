import { firestore } from 'firebase/app'
import { IFirestoreGateKeeper } from '@naevaas/backend/dist/types'

const converter = <T>(): firestore.FirestoreDataConverter<T> => ({
  toFirestore: (data: Partial<T>): Partial<T> => data,
  fromFirestore: (snapshot): T => snapshot.data() as T,
})

const dataPoint = <T>(
  collectionPath: string,
): firestore.CollectionReference<T> =>
  firestore().collection(collectionPath).withConverter(converter<T>())

/**
 * Company permissions
 */
export const companyPermissions = () => {
  return dataPoint<IFirestoreCompanyPermission>('companyPermissions')
}

/**
 * Machines
 */
export const machines = () => {
  return dataPoint<IFirestoreMachine>('machines')
}

/**
 * Machine booking
 */
export const machineBookings = () => {
  return dataPoint<IFirestoreMachineBooking>('machineBookings')
}

/**
 * Sites
 */
export const sites = () => {
  return dataPoint<IFirestoreSite>('sites')
}

/**
 * CheckSessions
 */
export const checkSessions = () => {
  return dataPoint<IFirestoreCheckSession>('checkSessions')
}

/**
 * Chats
 */
export const chats = () => {
  return dataPoint<IFirestoreChat>('chats')
}

/**
 * Chat messages
 */
export const chatMessages = (chatId: string) => {
  return dataPoint<IFirestoreChatMessage>(`chats/${chatId}/messages`)
}

/**
 * Companies
 */
export const companies = () => {
  return dataPoint<IFirestoreCompany>(`companies`)
}

/**
 * Users
 */
export const users = () => {
  return dataPoint<IFirestoreUser>(`users`)
}

/**
 * User permissions
 */
export const userPermissions = () => {
  return dataPoint<IFirestoreUserPermission>(`userPermissions`)
}

/**
 * Site permissions
 */
export const sitePermissions = () => {
  return dataPoint<IFirestoreSitePermission>(`sitePermissions`)
}

/**
 * SCPSS Info
 */
export const scpssInfo = () => {
  return dataPoint<IFirestoreScpssCategoryList>(`scpssInfo`)
}

/**
 * GateKeeper
 */
export const gateKeepers = () => {
  return dataPoint<IFirestoreGateKeeper>(`gateKeepers`)
}
