import React, { ReactElement } from 'react'
import format from 'date-fns/format'

// Material-UI
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Box from '@material-ui/core/Box'
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt'

// Webtastic
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

//
import { IAvailabilityProps } from './types'

const Availability = (props: IAvailabilityProps): ReactElement => {
  const {
    availability: { status, alreadyBooked },
  } = props

  if (status === 'LOADING') {
    return (
      <Alert
        severity="info"
        variant="outlined"
        icon={<CircularProgress size={'1em'} />}
      >
        Checking availability
      </Alert>
    )
  } else if (status === 'AVAILABLE') {
    return (
      <Alert severity="success" variant="outlined">
        Available
      </Alert>
    )
  } else if (status === 'NOT_AVAILABLE') {
    return (
      <Alert severity="error" variant="outlined">
        <AlertTitle>Not available</AlertTitle>
        The selected period is overlapping existing reservations. Please change
        the period.
        {alreadyBooked ? (
          <Box mt={2}>
            Reservations
            {alreadyBooked.map((r, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                >
                  <Box pr={2}>
                    {r.utcMillisFrom &&
                      format(r.utcMillisFrom, 'dd.MM.yyyy HH:mm')}
                  </Box>

                  <ArrowRightIcon />

                  <Box pl={2}>
                    {r.utcMillisTo && format(r.utcMillisTo, 'dd.MM.yyyy HH:mm')}
                  </Box>
                </Box>
              )
            })}
          </Box>
        ) : null}
      </Alert>
    )
  } else if (status === 'INVALID') {
    return (
      <Alert severity="error" variant="outlined">
        Invalid time range
      </Alert>
    )
  }

  return <div />
}

export default Availability
