export const siteTypes = {
  site0: 'Enebolig/fritidsbolig',
  site1: 'Veianlegg',
  site2: 'Forretnings-/Nærings-/Industribygg',
  site3: 'Kai-/Moloanlegg',
  site4: 'Boligblokk/rekkehus/feltutbygging',
  site5: 'Bergverk/tunnel/Grøfter/Annet Grunnarbeide',
  site6: 'Ombygging/Tilbygg/Riving/Sanering av bolig og næringsbygg',
  site7: 'Kraft-/Energianlegg/TV-radio el. annet kommunikasjonsanlegg',
}

export const defaultCard = {
  archived: false,
  name: '',
  description: '',
  populatedArea: false,
  nrOfWorkers: 0,
  durationYears: 0,
  siteTypes: {
    [siteTypes.site0]: false,
    [siteTypes.site1]: false,
    [siteTypes.site2]: false,
    [siteTypes.site3]: false,
    [siteTypes.site4]: false,
    [siteTypes.site5]: false,
    [siteTypes.site6]: false,
    [siteTypes.site7]: false,
  },
  imageUrl: '',
  imageUrlThumb: '',
  squareMeters: 10000,
  productPrice: 1000,
  override: false,
  providerId: '',
  category: [],
}

export const formErrors = {
  required: 'Required field',
  incorrectNumValue: 'Value should be a number',
  emptyImg: 'Required upload company image',
}

export const numberRegExp = /^[0-9]+$/
