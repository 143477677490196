import React, { useState, useCallback } from 'react'
import Select from 'react-select'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// Material UI
import {
  Typography,
  TextField,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
  FormControl,
  Box,
  Select as MuiSelect,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import ImageArea from 'sections/systemAdmin/components/common/ImageArea'
import {
  ICategory,
  ICompany,
  IProduct,
} from 'sections/systemAdmin/state/types/saletool'
import {
  formErrors,
  numberRegExp,
  siteTypes,
} from 'sections/systemAdmin/constants/salestool'
import { useStyles } from './styles'
export interface IEditProductDialog {
  onClose: () => void
  categories: ICategory[]
  product: IProduct
  saveProduct(product: IProduct, file?: File): void
  companies: ICompany[]
}

function ProductDialog(props: IEditProductDialog): React.ReactElement {
  const { onClose, product, saveProduct, companies } = props
  const [isOpen, toggleDialog] = useState(true)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [imageUrl, setImageUrl] = useState(product.imageUrl || '')
  const [fileError, setFileError] = useState('')
  const classes = useStyles()
  const submitForm = (values: any): void => {
    if (!imageUrl) {
      setFileError(formErrors.emptyImg)
    } else {
      const updatedCart = JSON.parse(JSON.stringify(product))
      updatedCart.name = values.name
      updatedCart.description = values.description
      updatedCart.providerId = values.company
      updatedCart.populatedArea = JSON.parse(values.populatedArea)
      updatedCart.override = JSON.parse(values.override)
      updatedCart.nrOfWorkers = values.nrOfWorkers
      updatedCart.durationYears = values.durationYears
      updatedCart.squareMeters = values.squareMeters
      updatedCart.productPrice = values.productPrice
      updatedCart.productPrice = values.productPrice
      updatedCart.category = values.category.map(
        (item: ICategory): string => item.value,
      )
      updatedCart.siteTypes = {
        [siteTypes.site0]: values.site0,
        [siteTypes.site1]: values.site1,
        [siteTypes.site2]: values.site2,
        [siteTypes.site3]: values.site3,
        [siteTypes.site4]: values.site4,
        [siteTypes.site5]: values.site5,
        [siteTypes.site6]: values.site6,
        [siteTypes.site7]: values.site7,
      }

      saveProduct(updatedCart, file)
      onClose()
    }
  }

  const isEdit = product.id || product.imageUrl

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    handleChange,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      name: product.name,
      description: product.description,
      company: product.providerId || '',
      populatedArea: '' + product.populatedArea,
      override: '' + product.override,
      nrOfWorkers: product.nrOfWorkers,
      durationYears: product.durationYears,
      squareMeters: product.squareMeters,
      productPrice: product.productPrice,
      site0: product.siteTypes[siteTypes.site0],
      site1: product.siteTypes[siteTypes.site1],
      site2: product.siteTypes[siteTypes.site2],
      site3: product.siteTypes[siteTypes.site3],
      site4: product.siteTypes[siteTypes.site4],
      site5: product.siteTypes[siteTypes.site5],
      site6: product.siteTypes[siteTypes.site6],
      site7: product.siteTypes[siteTypes.site7],
      category: product.category
        .map((item: string) => {
          return props.categories.find((ct: ICategory) => ct.label === item)
        })
        .filter((item: any) => !!item),
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(formErrors.required),
      description: Yup.string().trim(),
      company: Yup.string().trim(),
      populatedArea: Yup.string().required(formErrors.required),
      override: Yup.string().required(formErrors.required),
      nrOfWorkers: Yup.string()
        .trim()
        .matches(numberRegExp, formErrors.incorrectNumValue)
        .required(formErrors.required),
      durationYears: Yup.string()
        .trim()
        .matches(numberRegExp, formErrors.incorrectNumValue)
        .required(formErrors.required),
      squareMeters: Yup.string()
        .trim()
        .matches(numberRegExp, formErrors.incorrectNumValue)
        .required(formErrors.required),
      productPrice: Yup.string()
        .trim()
        .matches(numberRegExp, formErrors.incorrectNumValue)
        .required(formErrors.required),
      site0: Yup.boolean(),
      site1: Yup.string(),
      site2: Yup.string(),
      site3: Yup.string(),
      site4: Yup.string(),
      site5: Yup.string(),
      site6: Yup.string(),
      site7: Yup.string(),
      category: Yup.array(),
    }),
    onSubmit: (values: any) => submitForm(values),
  })

  const setImage = useCallback((imageFile: File | undefined) => {
    setFile(imageFile)
    setImageUrl(imageFile ? URL.createObjectURL(imageFile) : '')
    setFileError(imageFile ? '' : formErrors.emptyImg)
  }, [])

  const deleteProduct = useCallback(() => {
    const updatedCart = JSON.parse(JSON.stringify(product))
    updatedCart.archived = true
    updatedCart.productId = updatedCart.id
    saveProduct(updatedCart)
    onClose()
  }, [product, onClose, saveProduct])

  return (
    <Dialog
      open={isOpen}
      onClose={(): void => {
        toggleDialog(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      fullHeight={false}
      maxWidth="md"
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle} variant="h5" component="h3">
          {isEdit ? values.name : 'New product'}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <FormControl className={classes.inputControlBlock}>
            <TextField
              id="name"
              name="name"
              label="Name of the product"
              type="text"
              value={values.name}
              onChange={(v: any): void => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              error={!!(touched.name && errors.name)}
              helperText={(touched.name && errors.name) || ''}
            />
          </FormControl>
          <div className={classes.imageContainer}>
            <ImageArea imageUrl={imageUrl} setFile={setImage} />
            {fileError && (
              <Typography className={classes.errorLabel}>
                {fileError}
              </Typography>
            )}
          </div>
          <FormControl className={classes.radioControlBlock}>
            <FormLabel>Populated Area?</FormLabel>
            <RadioGroup
              id="populatedArea"
              name="populatedArea"
              value={values.populatedArea}
              onChange={handleChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          <FormControl className={classes.radioControlBlock}>
            <FormLabel>Always visible?</FormLabel>
            <RadioGroup
              id="override"
              name="override"
              value={values.override}
              onChange={handleChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          <FormControl className={classes.controlBlock}>
            <Box className={classes.controlBlockWrapper}>
              <FormLabel className={classes.controlBlockLabel}>
                Estimate the number of workers the product should be recommended
                for:
              </FormLabel>
              <Box className={classes.controlBlockDesc}>
                <TextField
                  id="nrOfWorkers"
                  name="nrOfWorkers"
                  type="text"
                  onChange={(v: any): void => {
                    handleBlur(v)
                    handleChange(v)
                  }}
                  onBlur={handleBlur}
                  value={values.nrOfWorkers}
                  className={classes.input}
                  error={!!(touched.nrOfWorkers && errors.nrOfWorkers)}
                />
                <Typography>workers</Typography>
              </Box>
            </Box>
            {touched.nrOfWorkers && errors.nrOfWorkers && (
              <Typography
                variant="body1"
                component="p"
                className={classes.errorLabel}
              >
                {errors.nrOfWorkers}
              </Typography>
            )}
          </FormControl>
          <FormControl className={classes.controlBlock}>
            <Box className={classes.controlBlockWrapper}>
              <FormLabel className={classes.controlBlockLabel}>
                Estimate the length of the project for which the product should
                be recommended:
              </FormLabel>
              <Box className={classes.controlBlockDesc}>
                <TextField
                  id="durationYears"
                  name="durationYears"
                  type="text"
                  onChange={(v: any): void => {
                    handleBlur(v)
                    handleChange(v)
                  }}
                  onBlur={handleBlur}
                  value={values.durationYears}
                  className={classes.input}
                  error={!!(touched.durationYears && errors.durationYears)}
                />
                <Typography>year(s)</Typography>
              </Box>
            </Box>
            {touched.durationYears && errors.durationYears && (
              <Typography
                variant="body1"
                component="p"
                className={classes.errorLabel}
              >
                {errors.durationYears}
              </Typography>
            )}
          </FormControl>
          <FormControl className={classes.controlBlock}>
            <Box className={classes.controlBlockWrapper}>
              <FormLabel className={classes.controlBlockLabel}>
                Estimate the size of worksite the product should be recommended:
              </FormLabel>
              <Box className={classes.controlBlockDesc}>
                <TextField
                  id="squareMeters"
                  name="squareMeters"
                  type="text"
                  onChange={(v: any): void => {
                    handleBlur(v)
                    handleChange(v)
                  }}
                  onBlur={handleBlur}
                  value={values.squareMeters}
                  className={classes.input}
                  error={!!(touched.squareMeters && errors.squareMeters)}
                />
                <Typography>
                  m<span>2</span>
                </Typography>
              </Box>
            </Box>
            {touched.squareMeters && errors.squareMeters && (
              <Typography
                variant="body1"
                component="p"
                className={classes.errorLabel}
              >
                {errors.squareMeters}
              </Typography>
            )}
          </FormControl>
          <FormControl className={classes.controlBlock}>
            <Box className={classes.controlBlockWrapper}>
              <FormLabel className={classes.controlBlockLabel}>
                Estimate the monthly cost of the product:
              </FormLabel>
              <Box className={classes.controlBlockDesc}>
                <TextField
                  id="productPrice"
                  name="productPrice"
                  type="text"
                  value={values.productPrice}
                  className={classes.input}
                  error={!!(touched.productPrice && errors.productPrice)}
                  onChange={(v: any): void => {
                    handleBlur(v)
                    handleChange(v)
                  }}
                  onBlur={handleBlur}
                />
                <Typography>kr</Typography>
              </Box>
            </Box>
            {touched.productPrice && errors.productPrice && (
              <Typography
                variant="body1"
                component="p"
                className={classes.errorLabel}
              >
                {errors.productPrice}
              </Typography>
            )}
          </FormControl>
          <FormControl className={classes.inputControlBlock}>
            <TextField
              id="description"
              name="description"
              label="Product description"
              type="text"
              onChange={(v: any): void => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.description}
              multiline
              rowsMax={4}
              error={!!(touched.description && errors.description)}
              helperText={(touched.description && errors.description) || ''}
            />
          </FormControl>
          <FormControl className={classes.inputControlBlock}>
            <InputLabel id="companyLabel">Providing company</InputLabel>
            <MuiSelect
              labelId="companyLabel"
              id="company"
              name="company"
              value={values.company}
              onChange={(value: any): void => {
                handleChange(value)
              }}
            >
              <MenuItem value="">None</MenuItem>
              {companies.map((company: ICompany) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
          <FormControl className={classes.inputControlBlock}>
            <FormLabel className={classes.selectControlLabel}>
              Product category
            </FormLabel>
            <Select
              closeMenuOnSelect={false}
              onChange={(value: any): any => {
                setFieldValue('category', value || [])
              }}
              defaultValue={values.category}
              isMulti
              options={props.categories}
              styles={{
                control: (provided: object): object => ({
                  ...provided,
                  backgroundColor: '#121212',
                }),
                option: (provided: any, state: any): object => ({
                  ...provided,
                  color: '#fff',
                  backgroundColor:
                    state.isFocused || state.isSelected ? '#f5821f' : '#1e1e1e',
                  ':active': {
                    ...provided[':active'],
                    backgroundColor: '#f5821f',
                  },
                  textTransform: 'capitalize',
                }),
                multiValue: (styles: object): object => ({
                  ...styles,
                  backgroundColor: '#f5821f',
                }),
                multiValueLabel: (styles: object): object => ({
                  ...styles,
                  color: '#fff',
                }),
              }}
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site0"
                  name="site0"
                  checked={values.site0}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site0}
              labelPlacement="end"
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site1"
                  name="site1"
                  checked={values.site1}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site1}
              labelPlacement="end"
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site2"
                  name="site2"
                  checked={values.site2}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site2}
              labelPlacement="end"
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site3"
                  name="site3"
                  checked={values.site3}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site3}
              labelPlacement="end"
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site4"
                  name="site4"
                  checked={values.site4}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site4}
              labelPlacement="end"
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site5"
                  name="site5"
                  checked={values.site5}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site5}
              labelPlacement="end"
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site6"
                  name="site6"
                  checked={values.site6}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site6}
              labelPlacement="end"
            />
          </FormControl>
          <FormControl className={classes.checkboxControlBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  id="site7"
                  name="site7"
                  checked={values.site7}
                  onChange={handleChange}
                />
              }
              label={siteTypes.site7}
              labelPlacement="end"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          {isEdit && (
            <IconButton onClick={deleteProduct}>
              <DeleteIcon />
            </IconButton>
          )}
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default React.memo(ProductDialog)
