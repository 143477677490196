import React, { ReactElement, useCallback } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// Webtastic
import Button from '@naevaas/webtastic/dist/components/Button'

//
import { ICards } from './types'
import RequirementItem from '../RequirementItem'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import {
  DocumentsDialog,
  useContext as useDocumentsContext,
  useContextDispatch as useDocumentsDispatch,
} from '../../DocumentsDialog'

const useStyles = makeStyles((theme: Theme) => {
  return {
    gridRoot: {
      marginTop: theme.spacing(3),
    },
    gridItem: {},
  }
})

const Cards = (props: ICards): ReactElement => {
  const { selectedSite, changeSiteRequirement } = props

  // Hooks
  const { sitePolicy } = useSitePolicy()
  const policyCanUpdate = selectedSite && sitePolicy().update(selectedSite)
  const classes = useStyles()

  // Context
  const documentsContext = useDocumentsContext()
  const documentsDispatch = useDocumentsDispatch()

  /**
   * Get the number of active RDE documents
   */
  const getActiveDocsCount = useCallback((): number => {
    const rde = selectedSite?.rde
    if (rde) {
      const count = Object.values(rde).filter((v) => v.active).length
      return count
    }
    return 0
  }, [selectedSite?.rde])

  return (
    <Grid container className={classes.gridRoot} spacing={3}>
      {/* <RequirementItem
      name="RDE"
      description="Required document for entrance"
      value={workerRequirements.rde}
      requirement="rde"
      changeSiteRequirement={changeSiteRequirement}
      noAccess={noAccess}
    /> */}

      <RequirementItem
        name="HSE Card"
        description="All personnel must have approved HSE card (construction site)"
        value={selectedSite.workerRequirements.validHseCard}
        requirement="validHseCard"
        changeSiteRequirement={changeSiteRequirement}
        disabled={!policyCanUpdate}
      />

      <RequirementItem
        name="Guest user"
        description="Allow guests to access without a HSE card"
        value={selectedSite.workerRequirements.guestUsers}
        requirement="guestUsers"
        changeSiteRequirement={changeSiteRequirement}
        disabled={!policyCanUpdate}
      />

      {/* <RequirementItem
      name="COMPANY PRE-APPROVAL"
      description="Pre-approve workers for site access on company level"
      value={workerRequirements.companyPreApproval}
      requirement="companyPreApproval"

      changeSiteRequirement={changeSiteRequirement}
      noAccess={noAccess}
    /> */}
      {/* <RequirementItem
      name="WORKER PRE-APPROVAL"
      description="Pre-approve workers for site access on worker level"
      value={workerRequirements.workerPreApproval}
      requirement="workerPreApproval"
      changeSiteRequirement={changeSiteRequirement}
      noAccess={noAccess}
    /> */}
      {/* <RequirementItem
      name="EMPLOYEE PRE-APPROVAL"
      description="Employees must have worker pre-approval to access site"
      value={workerRequirements.ownEmployeePreApproval}
      requirement="ownEmployeePreApproval"
      changeSiteRequirement={changeSiteRequirement}
      noAccess={noAccess}
    /> */}

      {/* <RequirementItem
      name="HSE Game"
      description="All personnel must have completed HSE game"
      value={workerRequirements.hseGame}
      requirement="hseGame"
      changeSiteRequirement={changeSiteRequirement}
      noAccess={noAccess}
    /> */}
      <RequirementItem
        name="Check in/out by position"
        description="Naeva app will check in users based on geofence"
        value={selectedSite.checkSettings.checkInByProxy}
        requirement="checkInByProxy"
        changeSiteRequirement={changeSiteRequirement}
        disabled={!policyCanUpdate}
      />
      <RequirementItem
        name="Check in/out by Refero"
        description="Check in and out by Refero. Optionally check out by position (in addition to Refero)"
        value={selectedSite.checkSettings.checkInByRefero}
        requirement="checkInByRefero"
        changeSiteRequirement={changeSiteRequirement}
        disabled={!policyCanUpdate}
      >
        {selectedSite.checkSettings.checkInByRefero && (
          <RequirementItem
            name=""
            description="Check out by position"
            value={selectedSite.checkSettings.checkOutByProxy}
            requirement="checkOutByProxy"
            changeSiteRequirement={changeSiteRequirement}
            gridProps={{ md: 12 }}
            disabled={!policyCanUpdate}
          />
        )}
      </RequirementItem>
      {/*<RequirementItem name="RDE" description="Required document for entrance"></RequirementItem>*/}
      <RequirementItem
        name="Documents"
        changeSiteRequirement={(): null => null}
        description={`Documents workers need to read an sign in the Naeva app.${
          getActiveDocsCount()
            ? ` Currently ${getActiveDocsCount()} active documents.`
            : ' Currently no active documents.'
        }`}
        requirement="checkDocuments"
        altAction={
          <Button
            onClick={() => {
              documentsDispatch('SET_DIALOG_IS_OPEN', { open: true })
            }}
          >
            View
          </Button>
        }
      >
        {documentsContext.dialogIsOpen ? (
          <DocumentsDialog site={selectedSite} />
        ) : null}
      </RequirementItem>
    </Grid>
  )
}

export default Cards
