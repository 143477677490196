import React, { ReactElement, useCallback } from 'react'
import { Polygon as RgmPolygon } from '@react-google-maps/api'

//
import { IPolygon } from './types'
import { useContext, useContextDispatch } from '../context'
import { calculateBufferPath } from '../utils/calculateBufferPath'

let _polygonElement: any

const Polygon = (props: IPolygon): ReactElement => {
  const { readonly, showBuffer = false } = props

  // context
  const state = useContext()
  const dispatch = useContextDispatch()

  /**
   * Update state.polygon if it's changed by user
   */
  const onPathChanged = useCallback(() => {
    if (readonly) return true

    const coordinatePath = _polygonElement.getPath()

    const newPath = []
    for (let i = 0; i < coordinatePath.getLength(); i++) {
      const xy = coordinatePath.getAt(i)
      newPath.push({
        lat: xy.lat(),
        lng: xy.lng(),
      })
    }

    dispatch('SET_POLYGON_PATH', { path: newPath })
  }, [dispatch, readonly])

  return (
    <>
      {state.polygon?.path ? (
        <>
          {showBuffer ? (
            <RgmPolygon
              paths={calculateBufferPath(
                state.polygon.path,
                state.polygon.bufferDistance || 20,
              )}
              options={{
                strokeColor: 'transparent',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#333',
                fillOpacity: 0.1,
              }}
              editable={false}
            />
          ) : null}
          <RgmPolygon
            onLoad={(polygon): void => {
              _polygonElement = polygon
            }}
            onMouseUp={onPathChanged}
            paths={state.polygon.path}
            draggable={!readonly}
            editable={!readonly}
            options={{
              fillColor: '#E88B3A',
              fillOpacity: 0.35,

              strokeColor: '#E88B3A',
              strokeWeight: 3,
              strokeOpacity: 1,
            }}
          />
        </>
      ) : null}
    </>
  )
}

export default Polygon
