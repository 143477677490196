import React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'

interface IDatePicker {
  id?: string
  label?: string
  onChange(val: any): any
  disabled?: boolean
  value: number | null
  keyboardDatePickerProps?: KeyboardDatePickerProps
}

const DatePicker = (props: IDatePicker): React.ReactElement => {
  const {
    id = 'date-picker',
    label = 'Date',
    disabled = false,
    value,
    onChange,
    keyboardDatePickerProps,
  } = props
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd.MM.yyyy"
        id={id}
        label={label}
        value={value ? new Date(value) : null}
        onChange={onChange}
        disabled={disabled}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...keyboardDatePickerProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
