import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    contentBox: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'calc(100% - 55px)',
      },
    },
  }
})
