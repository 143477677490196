import { useState } from 'react'

//
import * as db from 'utils/firestoreDB'

const useFetchMachines = (): Response => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IFirestoreMachine[]>([])
  const [error, setError] = useState<IFirestoreMachine[] | null>(null)

  const fetch: TFetch = async props => {
    const { filter } = props

    const collection = db.machines()
    let query = collection.where('siteId', '!=', '')

    // filters
    if (filter?.siteId) {
      query = collection.where('siteId', '==', filter.siteId)
    }
    if (filter?.machineId) {
      query = collection.where('id', '==', filter.machineId)
    }

    setLoading(true)
    try {
      const res = await query.get()

      const machineList = res.docs.map(doc => {
        const machine = doc.data()

        machine.name =
          machine.make && machine.model
            ? `${machine.make} ${machine.model}`
            : '(unkown)'

        return machine
      })

      setLoading(false)
      setData(machineList)
      return machineList
    } catch (e) {
      setError(e)
      setLoading(false)
      setData([])
      return []
    }
  }

  const result = {
    loading,
    data: [...data],
    error,
  }

  return [fetch, result]
}

interface IFilter {
  siteId?: string
  machineId?: string
}

type TProps = {
  filter?: IFilter
}

type TFetch = (props: TProps) => Promise<IFirestoreMachine[]>

type Response = [
  TFetch,
  {
    loading: boolean
    data: IFirestoreMachine[]
    error: any
  },
]

export default useFetchMachines
