import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'

export default function msToDuration(duration: number): string {
  const _diffInDays = differenceInDays(duration, 0)
  const _diffInHours = differenceInHours(duration, 0)
  const _diffInMinutes = differenceInMinutes(duration, 0)

  const hours = _diffInHours - _diffInDays * 24
  const minutes = _diffInMinutes - _diffInDays * 1440 - hours * 60

  //return `${_diffInDays > 0 ? _diffInDays + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm' : ''}`;
  return `${_diffInHours}:${minutes < 10 ? '0' + minutes : minutes}h`
}
