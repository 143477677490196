import React, { ReactElement, useEffect } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import SuccessIcon from '@material-ui/icons/CheckCircleOutline'

// Webtastic
import EmptyState from '@naevaas/webtastic/dist/components/EmptyState'

//
import { useContextDispatch } from '../../context'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.success.main,
  },
}))

const Success = (): ReactElement => {
  const dispatch = useContextDispatch()
  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => {
      dispatch('SET_VIEW', { view: 'ACTIVE' })
    }, 2000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <EmptyState
      primaryText="The file was saved"
      icon={<SuccessIcon classes={{ root: classes.icon }} />}
    />
  )
}

export default Success
