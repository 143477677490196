import React from 'react'

// Material UI
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'

//
import { Routes } from 'constants/routing'
import { useStyles } from './styles'

interface IDashboard {
  history: any
}

function Dashboard(props: IDashboard): React.ReactElement {
  const classes = useStyles()
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxWidth="1000px" padding="24px" width="100%">
        <Box py={1}>
          <Typography variant="h6" component="h2">
            System Dashboard
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid key={1} item xs={12} md={6} lg={4}>
            <Card elevation={2} style={{ height: '100%' }}>
              <CardActionArea
                component="button"
                style={{ height: '100%' }}
                onClick={(): void => {
                  props.history.push(Routes.SALESTOOL)
                }}
              >
                <CardHeader
                  className={classes.cardHeader}
                  avatar={
                    <Avatar
                      variant="square"
                      aria-hidden="true"
                      className={classes.avatar}
                    >
                      <DesktopWindowsIcon fontSize="large" />
                    </Avatar>
                  }
                  title="Naeva salestools"
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Dashboard
