import React, { useCallback, useState } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import RequirementsIcon from '@material-ui/icons/ToggleOn'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@naevaas/webtastic/dist/components/Dialog'

//
import { IRequirementsDialog } from './types'
import { useUpdateRequirement } from './useUpdateRequirement'
import { useContext as useSiteContext } from 'state/context/siteContext'
import { Provider as DocumentsProvider } from '../DocumentsDialog'

import Cards from './Cards'

const RequirementsDialog = (props: IRequirementsDialog): React.ReactElement => {
  const {
    onClose = (): void => {
      // do nothing
    },
  } = props

  const [openState, setOpenState] = useState(true)
  const [loading, setLoading] = useState(false)

  // Context
  const { selectedSite } = useSiteContext()

  // Hooks
  const update = useUpdateRequirement()

  const changeSiteRequirement = useCallback(
    (fieldName: string, fieldValue: boolean): void => {
      if (selectedSite) {
        setLoading(true)
        update({
          site: selectedSite,
          fieldName,
          fieldValue,
        })
          .then(() => {
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }
    },
    [update, selectedSite],
  )

  if (!selectedSite) return <></>

  return (
    <Dialog
      open={openState}
      onClose={(): void => {
        setOpenState(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      titleIcon={<RequirementsIcon fontSize="large" />}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          Worker requirements{' '}
          {loading ? (
            <CircularProgress size={20} style={{ marginLeft: 10 }} />
          ) : (
            ''
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Site: {selectedSite.name} </DialogContentText>

        {/* <div>
          <Button color="default" disabled={noAccess}>
            Rde documents
          </Button>
        </div> */}

        <Cards
          selectedSite={selectedSite}
          changeSiteRequirement={changeSiteRequirement}
        />
      </DialogContent>
    </Dialog>
  )
}

export const RequirementsDialogWrapper = (props: IRequirementsDialog) => {
  return (
    <DocumentsProvider
      initialState={{
        dialogIsOpen: false,
        view: 'ACTIVE',
      }}
    >
      <RequirementsDialog {...props} />
    </DocumentsProvider>
  )
}
