import React, { useEffect, useState, useCallback } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import isEqual from 'lodash/isEqual'
import clsx from 'clsx'
import noop from 'lodash/noop'

// Material UI
import { Button, Box, Link } from '@material-ui/core'

//
import FormInput from 'components/common/FormInput'
import Checkbox from 'components/common/Checkbox'
import FormInputWithAutocomplete from 'components/common/FormInputAutocomplete'
import { useStyles } from 'components/views/SignUp/styles'

import { numberRegExp } from 'constants/autocomplete'
import { useFetch } from 'hooks/useFetch'
import useDebounce from 'hooks/useDebounce'

const minTextValueLength = 3
const signUpCodeLength = 5
const minPasswordLength = 6
const companyIdLength = 9
const signCodePattern = /^[a-zA-Z0-9_]+$/

interface IAutocompleteOption {
  companyName: string
  mva: boolean
  postNumber: string
  billingAddress: string
  orgNumber: string
  municipality: string
}

interface ISignupFormProps {
  submitForm(data: any): void
}

function SignUpForm(props: ISignupFormProps): React.ReactElement {
  const { submitForm } = props

  const [keyword, setCodeKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    IAutocompleteOption[]
  >([])
  const [
    selectedAutocompleteOption,
    setSelectedAutocompleteOption,
  ] = useState<IAutocompleteOption | null>(null)

  const {
    values,
    validateForm,
    setFieldTouched,
    errors,
    setFieldValue,
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
  } = useFormik({
    initialValues: {
      contactPerson: '',
      contactEmail: '',
      password: '',
      retypePassword: '',
      companyName: '',
      companyOrgnr: '',
      billingEmail: '',
      isBillingSame: true,
      isMVA: false,
      billingAddress: '',
      postcode: '',
      municipality: '',
      signupCode: '',
    },
    validationSchema: Yup.object({
      contactPerson: Yup.string()
        .min(minTextValueLength)
        .trim()
        .required('Required'),
      contactEmail: Yup.string()
        .email()
        .required('Required'),
      password: Yup.string()
        .min(minPasswordLength)
        .required('Required'),
      retypePassword: Yup.string()
        .min(minPasswordLength)
        .required('Required'),
      companyName: Yup.string().required('Required'),
      companyOrgnr: Yup.string()
        .min(companyIdLength)
        .matches(numberRegExp)
        .required('Required'),
      billingEmail: Yup.string()
        .email()
        .required('Required'),
      isBillingSame: Yup.boolean(),
      isMVA: Yup.boolean(),
      billingAddress: Yup.string().required('Required'),
      postcode: Yup.string().required('Required'),
      municipality: Yup.string(),
      signupCode: Yup.string()
        .length(signUpCodeLength)
        .matches(signCodePattern)
        .required('Required'),
    }),
    onSubmit: submitForm,
  })

  const classes = useStyles()
  const [post] = useFetch()
  const debouncedKeyword = useDebounce(keyword, 400)

  useEffect(() => {
    let postNumber
    let billingAddress
    let companyName
    let orgNumber
    let municipality
    let mva
    if (selectedAutocompleteOption) {
      ;({
        postNumber,
        billingAddress,
        companyName,
        orgNumber,
        municipality,
        mva,
      } = selectedAutocompleteOption)
    } else {
      postNumber = ''
      billingAddress = ''
      companyName = ''
      orgNumber = ''
      municipality = ''
      mva = false
    }

    if (
      !isEqual(
        { postNumber: values.postcode, billingAddress: values.billingAddress },
        { postNumber, billingAddress },
      )
    ) {
      if (mva) {
        setFieldValue('companyName', companyName)
        setFieldValue('companyOrgnr', orgNumber)
        setFieldValue('postcode', postNumber)
        setFieldValue('billingAddress', billingAddress)
        setFieldValue('isMVA', mva)
        setFieldValue('municipality', municipality)
      } else {
        setFieldValue('companyName', companyName)
        setFieldValue('companyOrgnr', orgNumber)
        setFieldValue('postcode', postNumber)
        setFieldValue('billingAddress', billingAddress)
        setFieldValue('municipality', municipality)
      }
      setFieldTouched('companyOrgnr', true)
      setFieldTouched('companyName', true)
      setTimeout(() => {
        validateForm().then(noop)
      }, 0)
    }
  }, [selectedAutocompleteOption]) // eslint-disable-line

  const setBillingEmail = (e: any): void => {
    setFieldValue(
      'billingEmail',
      e.target.checked ? values.contactEmail : '',
      true,
    )
  }

  const changeContactEmail = (e: any): void => {
    if (values.isBillingSame) {
      setTimeout(() => setFieldValue('billingEmail', e.target.value, true))
    }
  }

  const getAutocompleteOptions = useCallback(
    async (payload: string): Promise<void> => {
      const searchBody = numberRegExp.test(payload)
        ? { orgNr: payload }
        : { companyName: payload }
      try {
        const response = await post({
          apiName: 'API2',
          endpoint: 'searchCompany',
          data: searchBody,
        })
        if (response.code !== 'C2000') {
          throw response
        }
        if (!response.companies.length) {
          window.furball.info(
            "Can't seem to find this company. Contact Naeva support.",
          )
        }
        const formattedOptions = response.companies.length
          ? response.companies.map((item: any) => {
              const { mva, name, address, orgNr } = item
              let postNumber
              let billingAddress
              let municipality
              if (address) {
                postNumber = address.zip
                billingAddress =
                  address.street.length === 0 ? '-' : address.street[0]
                municipality = address.county
              } else {
                postNumber = '-'
                billingAddress = '-'
                municipality = '-'
              }
              return {
                companyName: name,
                mva,
                postNumber,
                billingAddress,
                orgNumber: orgNr,
                municipality,
              }
            })
          : []
        if (searchBody.orgNr) {
          setAutocompleteOptions([])
          setSelectedAutocompleteOption(
            formattedOptions.length ? formattedOptions[0] : null,
          )
        } else {
          setAutocompleteOptions(formattedOptions)
        }
      } catch (e) {
        console.error(e)
        window.furball.error('Something went wrong, please try again later')
      }
    },
    [post],
  )

  useEffect(() => {
    if (debouncedKeyword) {
      setLoading(true)
      getAutocompleteOptions(debouncedKeyword).then(() => {
        setLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword])

  const searchCompanyInfoByName = (e: any): void => {
    const value = e.target.value.trim()
    if (!value) {
      setCodeKeyword('')
      setSelectedAutocompleteOption(null)
    } else {
      setCodeKeyword(value)
    }
  }

  const searchCompanyInfoByOrg = (e: any): void => {
    const value = e.target.value.trim()
    if (!isNaN(value) && value.length === 9) {
      setCodeKeyword(value)
    } else {
      setCodeKeyword('')
      setSelectedAutocompleteOption(null)
    }
  }

  const selectCompanyByName = useCallback((payload: IAutocompleteOption) => {
    setSelectedAutocompleteOption(payload)
    setAutocompleteOptions([])
  }, [])

  const clearAutocompleteOptions = useCallback((): void => {
    setAutocompleteOptions([])
  }, [])

  return (
    <form onSubmit={handleSubmit} className={classes.formContainer}>
      <div className={classes.sections}>
        <div className={classes.section}>
          <h5 className={classes.sectionHeader}>Contact info</h5>
          <div className={clsx([classes.sectionLine, classes.shortInput])}>
            <FormInput
              id="contactPerson"
              name="contactPerson"
              label="Contact person"
              type="text"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.contactPerson}
              isValid={!errors.contactPerson}
              isFieldTouched={touched.contactPerson}
            />
            <FormInput
              id="contactEmail"
              name="contactEmail"
              label="Contact email"
              type="email"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
                changeContactEmail(v)
              }}
              onBlur={handleBlur}
              value={values.contactEmail}
              isValid={!errors.contactEmail}
              isFieldTouched={touched.contactEmail}
              autoComplete="off"
            />
          </div>
          <div className={clsx(classes.sectionLine, classes.longInput)}>
            <FormInput
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.password}
              isValid={!errors.password}
              isFieldTouched={touched.password}
              autoComplete="off"
            />
          </div>
          <div className={clsx(classes.sectionLine, classes.longInput)}>
            <FormInput
              id="retypePassword"
              name="retypePassword"
              label="Retype password"
              type="password"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.retypePassword}
              isValid={
                !errors.retypePassword &&
                values.retypePassword === values.password
              }
              isFieldTouched={touched.retypePassword}
              autoComplete="off"
            />
          </div>
        </div>
        <div className={classes.section}>
          <h5 className={classes.sectionHeader}>Company info</h5>
          <div className={clsx(classes.sectionLine, classes.shortInput)}>
            <FormInputWithAutocomplete
              id="companyName"
              name="companyName"
              options={autocompleteOptions}
              label="Company name"
              type="text"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
                searchCompanyInfoByName(v)
              }}
              onBlur={handleBlur}
              value={values.companyName}
              isValid={!errors.companyName}
              isConnectedFieldsEmpty={
                selectedAutocompleteOption &&
                selectedAutocompleteOption.municipality &&
                !selectedAutocompleteOption.billingAddress
              }
              isFieldTouched={touched.companyName}
              selectOption={selectCompanyByName}
              clearOptions={clearAutocompleteOptions}
              isSearching={loading}
            />
            <FormInput
              id="companyOrgnr"
              name="companyOrgnr"
              label="Company org.nr"
              type="text"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
                searchCompanyInfoByOrg(v)
              }}
              onBlur={handleBlur}
              value={values.companyOrgnr}
              isValid={!errors.companyOrgnr}
              isFieldTouched={touched.companyOrgnr}
            />
          </div>
          <div className={classes.sectionLine}>
            <div
              className={clsx([classes.inputWithCheckbox, classes.shortInput])}
            >
              <FormInput
                id="billingEmail"
                name="billingEmail"
                label="Billing email"
                type="email"
                onChange={(v: any) => {
                  handleBlur(v)
                  handleChange(v)
                }}
                onBlur={handleBlur}
                value={values.billingEmail}
                isValid={!errors.billingEmail}
                isFieldTouched={touched.billingEmail}
                disabled={
                  values.isBillingSame === undefined
                    ? true
                    : values.isBillingSame
                }
                autoComplete="off"
              />
              <Checkbox
                id="isBillingSame"
                name="isBillingSame"
                label="Same as contact email?"
                checked={values.isBillingSame}
                onChange={(v: any) => {
                  handleBlur(v)
                  handleChange(v)
                  setBillingEmail(v)
                }}
              />
            </div>
            <div className={classes.checkboxLine}>
              <Checkbox id="isMVA" name="isMVA" label="MVA?" />
            </div>
          </div>
          <div className={clsx(classes.sectionLine, classes.shortInput)}>
            <FormInput
              id="billingAddress"
              name="billingAddress"
              label="Billing address"
              type="text"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.billingAddress}
              isValid={!errors.billingAddress}
              isFieldTouched={touched.billingAddress}
              hideStateIcon
              disabled
            />
            <FormInput
              id="postcode"
              name="postcode"
              label="Postcode"
              type="text"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.postcode}
              isValid={!errors.postcode}
              isFieldTouched={touched.postcode}
              hideStateIcon
              autoComplete="new-password"
              disabled
            />
          </div>
          <div
            className={clsx([
              classes.sectionLine,
              classes.shortInput,
              classes.inputToUpperCase,
              !errors.signupCode && values.signupCode && classes.inputHighlight,
            ])}
          >
            <FormInput
              id="municipality"
              name="municipality"
              label="Municipality"
              type="text"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.municipality}
              isValid={!errors.municipality}
              isFieldTouched={touched.municipality}
              disabled={true}
              hideStateIcon
              autoComplete="new-password"
            />
            <FormInput
              id="signupCode"
              name="signupCode"
              label="Signup code"
              type="string"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.signupCode}
              isValid={!errors.signupCode}
              isFieldTouched={touched.signupCode}
              autoComplete="new-password"
            />
          </div>
        </div>
      </div>
      <Box my={2}>
        By signing up you agree to{' '}
        <Link
          href="https://firebasestorage.googleapis.com/v0/b/naeva-b3e6c.appspot.com/o/Naeva_Data_Privacy_Notice_Refero_%2B_HSE_v1.0_18.03.19.pdf?alt=media&token=f137edd4-1407-4990-b41c-405e88a46592"
          target="_blank"
          rel="noopener"
        >
          NAEVA – Data Privacy Notice and Terms of Use
        </Link>
      </Box>
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
      >
        Create account
      </Button>
    </form>
  )
}

export default SignUpForm
