import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    cardsContainer: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
      overflowY: 'auto',
    },
    pageTitle: {
      margin: `${theme.spacing()}px 0`,
      textTransform: 'uppercase',
    },
    controlPanel: {
      alignSelf: 'center',
      display: 'flex',
    },
    createButton: {
      '&:not(:only-child)': {
        marginRight: theme.spacing(),
      },
    },
  }
})
