import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

//
import Root from './SiteAdminRoot'
import { Routes, routes } from 'constants/routing'
import NewSite from 'sections/siteAdmin/pages/CreateSite'

const SiteAdminRouter = (): React.ReactElement => {
  return (
    <Switch>
      <Route exact path={routes.SITES.NEW} component={NewSite} />

      <Route
        exact
        path={[Routes.SITE, `${Routes.SITE}/:siteId`]}
        component={Root}
      />
      <Route
        path={Routes.SITE}
        component={() => {
          return <Redirect to={Routes.SITE} />
        }}
      />

      <Route
        exact
        path={`${Routes.SITES}/:page(active|archive)`}
        component={Root}
      />
      <Route
        path={Routes.SITES}
        component={() => {
          return <Redirect to={`${Routes.SITES}/active`} />
        }}
      />
    </Switch>
  )
}

export default SiteAdminRouter
