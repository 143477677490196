import { Record, RecordOf } from 'immutable'
import { UserActionTypes } from 'state/types/user'
import { UserOwnData } from 'state/actions/user'
import { IAuthUser } from 'types/user'

export interface IUserReducer {
  email: string
  uid: string | null
  tokenId: string | null
  authedData: IAuthUser
  ownData: UserOwnData
}

const initialStateObject = {
  email: '',
  uid: '',
  tokenId: '',
  authedData: {
    uid: null,
    name: '',
    company: {
      id: '',
      name: '',
    },
    roles: [],
    isSuperUser: false,
    isCompanyAdmin: false,
    isSiteAdmin: false,
    imageUrl: '',
  },
  ownData: { preferredWorkingHours: {}, autoCheck: false },
}

const CompanyFactory: Record.Factory<Partial<IUserReducer>> =
  Record<Partial<IUserReducer>>(initialStateObject)

const initialState: RecordOf<Partial<IUserReducer>> = CompanyFactory()

export default function user(state = initialState, { type, payload }: any) {
  switch (type) {
    case UserActionTypes.NEW_USER_STATE:
    case UserActionTypes.USER_INFO: {
      return state.merge(payload)
    }
    case UserActionTypes.UPDATE_USER_AUTHED_DATA: {
      return state.merge({ authedData: payload })
    }
    case UserActionTypes.UPDATE_USER_OWN_DATA: {
      return state.merge({ ownData: payload })
    }
    case UserActionTypes.SAVE_TOKEN: {
      return state.set('tokenId', payload)
    }
    case UserActionTypes.LOG_OUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}
