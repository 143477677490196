import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// Material-UI
import { Button, Grid } from '@material-ui/core'

// Webtastic
import GoogleLogo from '@naevaas/webtastic/dist/icons/GoogleLogo'

import FormInput from 'components/common/FormInput'
import { useStyles } from 'components/views/SignIn/styles'

interface ISignupFromProps {
  submitForm(data: any): void
  signInWithGoogle(payload: any): void
}

function SignInForm(props: ISignupFromProps): React.ReactElement {
  const { signInWithGoogle, submitForm } = props

  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Required'),
      email: Yup.string()
        .email()
        .required('Required'),
    }),
    onSubmit: (values: any) => submitForm(values),
  })

  const classes = useStyles()

  return (
    <div className={classes.formContainer}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ width: '100vw', maxWidth: 600 }}>
          <Grid item xs={12} sm>
            <FormInput
              id="email"
              name="email"
              label="Email"
              type="email"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.email}
              isValid={!errors.email}
              isFieldTouched={touched.email}
              autoComplete="username"
            />
          </Grid>
          <Grid item xs={12} sm>
            <FormInput
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={(v: any) => {
                handleBlur(v)
                handleChange(v)
              }}
              onBlur={handleBlur}
              value={values.password}
              isValid={!errors.password}
              isFieldTouched={touched.password}
              autoComplete="current-password"
            />
          </Grid>
        </Grid>
        <Button
          className={classes.submitBtn}
          color="primary"
          variant="contained"
          type="submit"
        >
          Sign in
        </Button>
      </form>
      <Button
        className={classes.signInWithGoogle}
        color="default"
        onClick={signInWithGoogle}
      >
        <GoogleLogo />
        <span>Sign in with Google</span>
      </Button>
    </div>
  )
}

export default SignInForm
