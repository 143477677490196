import React from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePickerProps,
} from '@material-ui/pickers'

interface IDateTimePicker {
  id?: string
  label?: string
  onChange(val: any): any
  disabled?: boolean
  value: Date | null
  datetimePickerProps?: Partial<KeyboardDateTimePickerProps>
}

const Picker = (props: IDateTimePicker): React.ReactElement => {
  const {
    id = 'datetime-picker',
    label = 'Date & time',
    disabled = false,
    value,
    onChange,
    datetimePickerProps,
  } = props
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        variant="dialog"
        inputVariant="outlined"
        format="dd.MM.yyyy HH:mm"
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ampm={false}
        minutesStep={5}
        {...datetimePickerProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default Picker
