import { auth } from 'firebase'

//
import { ICategory } from '../state/types/saletool'
import * as db from 'utils/firestoreDB'

export const deleteCategories = async (categories: string[]): Promise<any> => {
  try {
    const token = await auth().currentUser?.getIdToken(true)
    const body = JSON.stringify({
      categories,
    })
    await fetch(
      process.env.REACT_APP_API_BASE_URL +
        'apiAdmin/deleteScpssProductCategory',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body,
      },
    )
  } catch (e) {
    window.furball.error(
      'Something went wrong. Could not delete the categories',
    )
    console.log(e)
  }
}

export const createCategory = async (category: any): Promise<any> => {
  const body = {
    name: category.name,
    nameLanguages: {
      NO: category.norwegianName,
    },
  }
  try {
    const token = await auth().currentUser?.getIdToken(true)
    await fetch(
      process.env.REACT_APP_API_BASE_URL + 'apiAdmin/addScpssProductCategory',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    )
  } catch (e) {
    window.furball.error('Something went wrong. Could not create the category')
    console.log(e)
  }
}

export const getCategories = async (): Promise<ICategory[]> => {
  try {
    const collectionRef = db.scpssInfo().doc('scpssData')
    const querySnapshot = await collectionRef.get()
    const data = querySnapshot.data()
    const categoriesList = data ? Object.values(data.categories) : []
    const formattedCategories = categoriesList.map(item => ({
      value: item.id,
      label: item.name,
    }))
    return formattedCategories
  } catch (e) {
    window.furball.error('Something went wrong. Could not get categories')
    console.log(e)
    return [] as ICategory[]
  }
}
