import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

// Material UI
import Box from '@material-ui/core/Box'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

// Common
import FindUserOrCompany, {
  IAutocompleteOption,
} from 'components/common/FindUserOrCompany'

//
import { ISwitchCompanyDialog } from './types'
import * as db from 'utils/firestoreDB'
import { useContextDispatch as useCompanyDispatch } from 'state/context/companyContext'
import { Routes } from 'constants/routing'

export function SwitchCompanyDialog(
  props: ISwitchCompanyDialog,
): React.ReactElement {
  const {
    onClose = (): void => {
      // do nothing
    },
  } = props

  const [openState, setOpenState] = useState(true)
  const [selected, setSelected] = useState<IAutocompleteOption | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  // Context
  const companyDispatch = useCompanyDispatch()

  // Hooks
  const history = useHistory()

  /**
   * Close
   */
  const closeDialog = useCallback(() => {
    setOpenState(false)
    setTimeout(() => {
      onClose()
    }, 300)
  }, [onClose])

  /**
   * Selected company (in Autocomplete)
   */
  const selectedCompany = useCallback(
    (selection: IAutocompleteOption[], reason: string) => {
      if (selection[0].data)
        if (reason === 'clear') {
          setSelected(null)
          return
        }

      if (!selection[0].data?.existingCompany) {
        window.furball.error('Invalid company (not registered)')
        return
      }

      console.log('selection, reason', selection, reason)
      setSelected(selection[0])
    },
    [],
  )

  /**
   * Fetch the company and switch company in state/context
   */
  const switchCompany = useCallback(async () => {
    setIsLoading(true)
    const res = await db
      .companies()
      .doc(selected?.id)
      .get()

    if (res.exists) {
      const company = res.data()
      company && companyDispatch('SELECT_COMPANY_BY_ID', { id: company.id })

      history.push(Routes.SITES)
      return
    }

    setIsLoading(false)
  }, [companyDispatch, history, selected])

  return (
    <Dialog
      open={openState}
      onClose={closeDialog}
      titleIcon={undefined}
      fullHeight={false}
      maxWidth={'sm'}
    >
      <DialogTitle>Manage other company</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Find and select a company you would like to manage
        </DialogContentText>
        <Box mb={4}>
          <FindUserOrCompany
            typename="COMPANY"
            onSelectionChange={selectedCompany}
            autocompleteProps={{
              multiple: false,
            }}
            companyFilter={{
              existingCompany: true,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!selected || isLoading}
          onClick={switchCompany}
          isWaiting={isLoading}
        >
          Manage
        </Button>
      </DialogActions>
    </Dialog>
  )
}
