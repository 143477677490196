import React from 'react'
import format from 'date-fns/format'

// Material UI
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'

//
import { ICrewMemberSessions } from '../types'
import msToDuration from 'utils/msToDuration'
import nameToInitials from 'utils/nameToInitials'

interface IProps {
  data: ICrewMemberSessions
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    lens: {
      color: theme.palette.primary.main,
    },
    greenLens: {
      color: theme.palette.success.main,
    },
    accordionDetails: {
      display: 'block',
    },
  }),
)

function CrewMember(props: IProps): React.ReactElement {
  const {
    data: { user, sessions, checkedInDuration },
  } = props

  const classes = useStyles()

  const rand = Math.random()

  return user ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${rand}-content`}
        id={`panel-${rand}-header`}
      >
        <Box display="flex" alignItems="center" width="100%">
          <Box>
            <Avatar
              src={user.imageThumbUrl || user.imageUrl}
              alt={user.name}
              checkedIn={sessions.length ? sessions[0].open : false}
            >
              {nameToInitials(user.name)}
            </Avatar>
          </Box>
          <Box flex="auto" pl={2}>
            <Typography variant="body1">{user.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {user.company?.name} | {user.company?.orgNr}
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography variant="h5" component="div" color="primary">
              {checkedInDuration > 0 && <>{msToDuration(checkedInDuration)}</>}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        {user.dateOfBirth && (
          <Box>
            <Typography variant="caption">{`Date of birth: ${user.dateOfBirth}`}</Typography>
          </Box>
        )}
        {user.hseCardIds && (
          <Box>
            <Typography variant="caption">
              HSE card id:{' '}
              {user.hseCardIds.map((cardId, i) => {
                return `${i > 0 ? ', ' : ''}${cardId}`
              })}
            </Typography>
          </Box>
        )}
        <List style={{ width: '100%' }}>
          {sessions.map((session, i) => {
            return (
              <React.Fragment key={session?.data.id || 'current'}>
                {i !== 0 && <Divider component="li" />}

                <ListItem>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        <Icon
                          className={
                            session?.data?.timestampOut
                              ? classes.lens
                              : classes.greenLens
                          }
                          style={{ fontSize: 14 }}
                        >
                          lens
                        </Icon>

                        <Box pl={1}>
                          {session?.data?.timestampIn &&
                            format(
                              session.data.timestampIn.toMillis(),
                              'dd.MM.yyyy HH:mm',
                            )}
                        </Box>

                        {session?.data?.timestampOut && (
                          <>
                            <Box display="flex" alignItems="center" px={2}>
                              <ArrowRightIcon />
                            </Box>
                            <Icon
                              className={classes.lens}
                              style={{ fontSize: 14 }}
                            >
                              lens
                            </Icon>

                            <Box pl={1} pr={4}>
                              {format(
                                session.data.timestampOut.toMillis(),
                                'dd.MM.yyyy HH:mm',
                              )}
                            </Box>
                          </>
                        )}
                        <Box flex="auto" textAlign="right" pr={'20px'}>
                          {session?.data?.totalTimeSeconds ? (
                            <Typography
                              variant="h6"
                              component="div"
                              color="primary"
                            >
                              {msToDuration(
                                session.data.totalTimeSeconds * 1000,
                              )}
                            </Typography>
                          ) : (
                            <Typography variant="h6" component="div">
                              --:--h
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            )
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  ) : (
    <div />
  )

  /* return (
    <React.Fragment>
      <div className={classes.userContainer}>
        <Avatar userAvatarUrl={imageThumbUrl || imageUrl || '/broken-image.jpg'} status={open ? 'online' : 'offline'} userName={name} />
        <div className={classes.userName}>
          <p>
            {name} <span>{moment(timestampIn).format('HH:mm/DD.MM.YYYY')}</span>
          </p>
          <span>{companyName}</span> | <span>NO {orgNr}</span>
        </div>
      </div>
      <Divider className={classes.divider} />
    </React.Fragment>
  ); */
}

export default React.memo(CrewMember)
