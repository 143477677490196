import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      textAlign: 'center',
    },
    imageContainer: {
      margin: `0 auto ${theme.spacing()}px`,
      maxWidth: '500px',
      position: 'relative',
      textAlign: 'center',
    },
    removeButton: {
      position: 'absolute',
      right: 0,
    },
    image: {
      display: 'block',
      maxWidth: '500px',
      width: '100%',
    },
  }
})
