import { useState, useCallback, useRef } from 'react'

//
import * as db from 'utils/firestoreDB'

const useCompanyPermissionsListener = (): Response => {
  const [companyPermissions, setCompanyPermissions] =
    useState<IFirestoreCompanyPermission | null>(null)
  const [isListening, setIsListening] = useState(false)

  const unsubRef = useRef<Function | null>(null)

  const fetch = useCallback((props: IUseCompanyPermissionsListener) => {
    const { companyId } = props

    // Unsubscribe previous listener
    unsubRef.current && unsubRef.current()

    if (companyId && !isListening) {
      const query = db.companyPermissions().doc(companyId)

      const unsubscribe = query.onSnapshot(function (response) {
        if (response.exists) {
          const doc = response.data()

          doc && setCompanyPermissions(doc)
        }

        setIsListening(true)
      })

      unsubRef.current = unsubscribe
    }

    // Unsubscribe on unmount
    return (): void => {
      unsubRef.current && unsubRef.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [fetch, { companyPermissions }]
}

export default useCompanyPermissionsListener

export interface IUseCompanyPermissionsListener {
  companyId: string
}

type Response = [
  (props: IUseCompanyPermissionsListener) => void,
  {
    companyPermissions: IFirestoreCompanyPermission | null
  },
]
