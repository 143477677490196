import React from 'react'

interface IProps {
  size?: string
}

function GraphicNaevaLogo({ size = '50px' }: IProps): React.ReactElement {
  return (
    <svg
      height={size}
      viewBox="0 0 4924 956"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" fill="#EA8C2E">
          <polygon
            id="Rectangle"
            points="3.93491092e-13 22.5263884 711.652917 458.1934 711.652917 22.5263884 782.516819 22.5263884 782.516819 584.041963 69.7767494 148.655516 69.7767494 937.053005 5.68434189e-14 937.053005"
          ></polygon>
          <polygon
            id="Rectangle"
            points="1380.1026 0 1919.1646 937.045803 1830.29555 937.045803 1380.1026 153.161235 924.272176 937.045803 841.880469 937.045803"
          ></polygon>
          <polygon
            id="Rectangle"
            points="2145.81438 19.0458028 2752.79824 19.0458028 2752.79824 85.0356286 2217.03465 85.0356286 2217.03465 441.468699 2752.79824 441.468699 2752.79824 505.875884 2217.03465 505.875884 2217.03465 872.472391 2752.79824 872.472391 2752.79824 937.045803 2145.81438 937.045803"
          ></polygon>
          <polygon
            id="Rectangle"
            points="2970.81438 19.0458028 3057.94163 19.0458028 3507.60564 802.533465 3963.66526 19.0458028 4046.81438 19.0458028 3508.81438 956.045803"
          ></polygon>
          <polygon
            id="Rectangle"
            points="4385.81438 1.04580284 4923.81438 936.045803 4835.63039 936.045803 4385.81438 153.322302 3930.21176 936.045803 3847.81438 936.045803"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}

export default GraphicNaevaLogo
