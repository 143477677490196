export const calculatePath = ({ center, zoom }: any) => {
  const centerLat = center.lat()
  const centerLng = center.lng()
  let polygonSide = 0.0005
  if (zoom <= 14) polygonSide = 0.01
  if (zoom === 15) polygonSide = 0.005
  if (zoom === 16) polygonSide = 0.002
  if (zoom === 17) polygonSide = 0.001
  if (zoom === 19) polygonSide = 0.00025
  if (zoom === 20) polygonSide = 0.0001
  return [
    { lat: centerLat + polygonSide / 1.7, lng: centerLng - polygonSide },
    { lat: centerLat + polygonSide / 1.7, lng: centerLng + polygonSide },
    { lat: centerLat - polygonSide / 1.7, lng: centerLng + polygonSide },
    { lat: centerLat - polygonSide / 1.7, lng: centerLng - polygonSide },
  ]
}
