import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      '&:not(last-child)': {
        marginRight: theme.spacing(),
      },
    },
  }
})
