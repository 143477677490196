import { makeStyles, Theme, fade } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    search: {
      position: 'relative',
      width: 48, // icon button width
      height: 48, // icon button height
      backgroundColor: 'transparent',
      transition: theme.transitions.create(['width', 'background-color'], {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.easeInOut,
      }),
      '&.expanded': {
        width: 200,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        transition: theme.transitions.create(['width', 'background-color'], {
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    searchIcon: {
      position: 'absolute',
      zIndex: 1,
      '&.expanded': {
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    inputRoot: {
      display: 'inline-flex',
      height: '100%',
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.leavingScreen,
      }),
      '&.expanded': {
        opacity: 1,
        transition: theme.transitions.create(['opacity'], {
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,

      [theme.breakpoints.up('md')]: {
        width: '10ch',
      },
      '&::-webkit-search-cancel-button': {
        '-webkit-appearance': 'none',
      },
    },
  }
})
