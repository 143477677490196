import React, { ReactElement, useState, useEffect } from 'react'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'

//
import { IBookingDetailsDialog } from './types'
import { useStyles } from './styles'

const BookingDetailsDialog = (props: IBookingDetailsDialog): ReactElement => {
  const { onClose, booking } = props
  const [open, setOpen] = useState(false)

  // Hooks
  const classes = useStyles()

  useEffect(() => {
    setOpen(!!booking)
  }, [booking])

  /** Wrapper function to to close the dialog */
  const closeDialog = (): void => {
    onClose()
  }

  return (
    <Dialog
      open={!!open}
      onClose={closeDialog}
      fullHeight={false}
      maxWidth="sm"
    >
      <DialogTitle>Title...</DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        CONTENT
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default BookingDetailsDialog
