import React from 'react'

interface IProps {
  size?: string
}

function GraphicNaevaLogoSmall({ size = '50px' }: IProps): React.ReactElement {
  return (
    <svg
      height={size}
      viewBox="0 0 27 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Pages"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-HD"
          transform="translate(-17.000000, -14.000000)"
          fill="#EA8C2E"
        >
          <polygon
            id="Rectangle"
            points="17 14 41.5549083 29.2443287 41.5549083 14 44 14 44 33.6478682 19.4075805 18.4133566 19.4075805 46 17 46"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}

export default GraphicNaevaLogoSmall
