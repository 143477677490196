import React from 'react'
import { Link } from 'react-router-dom'

// Material UI
import Button from '@material-ui/core/Button'

//
import { routes } from 'constants/routing'

const CreateSiteButton = (): React.ReactElement => {
  return (
    <Button
      component={Link}
      to={routes.SITES.NEW}
      color="primary"
      variant="contained"
    >
      Create site
    </Button>
  )
}

export default CreateSiteButton
