import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '70px',
      paddingBottom: 50,
    },
    logo: {
      marginBottom: '16px',
    },
    subLogo: {
      color: '#fff',
      paddingBottom: 30,
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sections: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    sectionHeader: {
      fontSize: '20px',
      fontWeight: 400,
      color: '#fff',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      width: '530px',
      marginLeft: '30px',
      marginRight: '30px',
    },
    sectionLine: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10px',
      marginBottom: '10px',
    },
    inputWithCheckbox: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkboxLine: {
      display: 'flex',
      margin: '24px 0 42px',
    },
    submitButton: {
      minWidth: '300px',
      alignSelf: 'center',
      marginTop: '10px',
      marginBottom: '32px',
    },
    searchSpinner: {
      position: 'absolute',
      left: 'calc(50% - 80px)',
      top: 'calc(50% - 46px)',
      textAlign: 'center',
    },
    shortInput: {
      '& > div': {
        width: '250px',
      },
    },
    longInput: {
      '& > div': {
        width: '530px',
      },
    },
    inputToUpperCase: {
      '& > div:last-child input': {
        textTransform: 'uppercase',
      },
    },
    inputHighlight: {
      '& > div:last-child input': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    inputValid: {
      '& > div #contactEmail, #billingEmail': {
        backgroundColor: '#55CC83',
        borderColor: '#55CC83',
      },
    },
  }
})
