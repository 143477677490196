import React, { ReactElement, useCallback } from 'react'
import format from 'date-fns/format'

// Material-UI
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import DialogContentText from '@material-ui/core/DialogContentText'
import Box from '@material-ui/core/Box'
import CompanyIcon from '@material-ui/icons/Business'

// Webtastic
import Avatar from '@naevaas/webtastic/dist/components/Avatar'

//
import { IList } from './types'
import { ISitePermission } from 'types/site'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import nameToInitials from 'utils/nameToInitials'
import { formatUsers, formatCompanies, filterList } from '../helpers'

const List = (props: IList): ReactElement => {
  const { site, status, sitePermissions, handleEditItem, onCreateAccess } =
    props

  // Context
  const { sitePolicy } = useSitePolicy()

  const userPermissions = sitePermissions
    ? formatUsers(sitePermissions.userSitePermissions)
    : []

  const companyPermissions = sitePermissions
    ? formatCompanies(sitePermissions.companySitePermissions)
    : []

  const filteredUsers = filterList(status, userPermissions)
  const filteredCompanies = filterList(status, companyPermissions)

  const secondaryText = useCallback(
    (item: ISitePermission) => {
      const isCompany = item.__typename === 'COMPANY'
      const secondaryText = [] as string[]
      const timestampNow = Date.now()
      const timeRestrictionStart = item?.timeRestrictionStart?.toMillis() || 0
      const timeRestrictionEnd = item?.timeRestrictionEnd?.toMillis() || 0

      // Company name
      if (!isCompany) {
        if (item?.companyData?.id !== sitePermissions?.ownedByCompanyId)
          secondaryText.push(
            `${item?.userData?.companyName} ${
              /*(${item?.userData?.companyOrgNr || ''})*/ ''
            }`,
          )
      }

      // Org nr
      if (isCompany) {
        secondaryText.push(`Org.nr. ${item?.companyData?.orgNr}`)
      }

      // Guest
      if (item?.entryAllow?.includes('guest')) secondaryText.push('Guest')

      // Operate machines
      if (item?.machinesAllow) secondaryText.push('Operate machines')

      // Time restriction
      if (
        item.timeRestricted &&
        timeRestrictionStart &&
        timeRestrictionEnd &&
        item.__isValid
      ) {
        // Has expired
        if (timestampNow > timeRestrictionEnd) {
          secondaryText.push(
            `Expired ${format(timeRestrictionEnd, 'dd.MM.yyyy')}`,
          )
        }

        // Will expire
        else if (
          timestampNow > timeRestrictionStart &&
          timestampNow < timeRestrictionEnd
        ) {
          secondaryText.push(
            `Expires ${format(timeRestrictionEnd, 'dd.MM.yyyy')}`,
          )
        }
        // Will enable
        else if (timestampNow < timeRestrictionStart) {
          secondaryText.push(
            `Valid from  ${format(timeRestrictionStart, 'dd.MM.yyyy')}`,
          )
        }
      }

      // Disabled
      if (!item.__isValid) secondaryText.push('Disabled')

      return secondaryText
    },
    [sitePermissions],
  )

  /**
   * Users
   */
  const userList = filteredUsers?.map((item, i) => {
    if (!item.id) return null

    return (
      <React.Fragment key={item.id}>
        <Divider />
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt={item?.name} src={item.userData?.imageThumbUrl}>
              {nameToInitials(item.name)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={item.name}
            secondary={secondaryText(item).join(' | ')}
          />

          {sitePolicy().update(site) && (
            <ListItemSecondaryAction>
              <Button
                aria-label="edit"
                onClick={(): void => {
                  handleEditItem(item)
                }}
                disabled={false}
              >
                Edit
              </Button>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </React.Fragment>
    )
  })

  /**
   * Companies
   */
  const companyList = filteredCompanies?.map((item) => {
    if (!item.id) return null

    return (
      <React.Fragment key={item.id}>
        <Divider />
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt={item.name}>
              <CompanyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={item.name}
            secondary={secondaryText(item).join(' | ')}
          />

          {sitePolicy().update(site) && (
            <ListItemSecondaryAction>
              <Button
                aria-label="edit"
                onClick={(): void => {
                  handleEditItem(item)
                }}
              >
                Edit
              </Button>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </React.Fragment>
    )
  })

  return (
    <>
      {userList?.length > 0 || companyList?.length > 0 ? (
        <MuiList>
          {userList}
          {companyList}
        </MuiList>
      ) : (
        <Box textAlign="center" mt={10}>
          {status === 'VALID' ? (
            <>
              <DialogContentText>No accesses are given yet</DialogContentText>
              <Box mt={3}>
                <Button color="primary" onClick={onCreateAccess}>
                  Create access
                </Button>
              </Box>
            </>
          ) : (
            <DialogContentText>No invalid accesses</DialogContentText>
          )}
        </Box>
      )}
    </>
  )
}

export default List
