import { useState, useCallback, useRef } from 'react'

//
import * as db from 'utils/firestoreDB'

const useFetchBooking = (): Response => {
  const [data, setData] = useState<IBookingList | null>(null)
  const unsubRef = useRef<Function | null>(null)

  const fetch = useCallback((props: P) => {
    const { filter } = props

    if (!filter.createdBy) return false

    // Unsubscribe previous listener
    unsubRef.current && unsubRef.current()

    //
    let query = db.machineBookings().where('createdBy', '==', filter?.createdBy)

    if (filter?.siteId) query = query.where('siteId', '==', filter.siteId)
    if (filter['canceled'] !== undefined)
      query = query.where('canceled', '==', filter.canceled)

    const unsubscribe = query.onSnapshot((response) => {
      const bookings: IBookingList = {}
      if (!response.empty) {
        response.docs.forEach((item) => {
          const booking = item.data()
          bookings[booking.id] = booking
        })
      }

      setData(bookings)
    })

    unsubRef.current = unsubscribe

    // Unsubscribe on unmount
    return (): void => {
      unsubscribe()
    }
  }, [])

  const response = {
    data,
  }

  return [fetch, response]
}

export default useFetchBooking

type P = {
  filter: {
    createdBy: string
    siteId?: string
    canceled?: boolean
  }
}

interface IBookingList {
  [bookingId: string]: IFirestoreMachineBooking
}

type Response = [
  (props: P) => void,
  {
    data: IBookingList | null
  },
]
