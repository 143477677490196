import { TStep } from './types'

export const steps: { name: TStep; title: string }[] = [
  { name: 'DETAILS', title: 'Machine details' },
  { name: 'FORM', title: 'Booking' },
  { name: 'CONFIRM', title: 'Confirm' },
]

/**
 * Get the step name
 */
export const stepName = (index: number): TStep | 'NONE' => {
  if (index < 0) return 'NONE'
  return steps[index].name
}

/**
 * Get step index by name
 */
export const stepIndexByName = (stepName?: TStep): number => {
  if (!stepName) return 0

  const key = steps.findIndex(({ name }: { name: TStep }) => name === stepName)

  return key || 0
}
