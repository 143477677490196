import React, { ReactElement, useEffect, useMemo } from 'react'

// Material UI
import CompanyIcon from '@material-ui/icons/Business'

// Webtastic
import {
  IAutocomplete,
  IAutocompleteOption,
} from '@naevaas/webtastic/dist/components/Autocomplete'

import {
  useContext as useValidationContext,
  useValidationHelper,
} from '@naevaas/webtastic/dist/components/TextFieldValidation'

import { useContext as useCompanyContext } from 'state/context/companyContext'
import FindUserOrCompany from 'components/common/FindUserOrCompany'

//
import { ISiteOwner } from './types'
import { useContextDispatch } from '../context'
import { useAuthUserContext } from 'state/context/authUserContext'

const SiteOwner = (__props: ISiteOwner): ReactElement => {
  // Context
  const authUser = useAuthUserContext()
  const dispatch = useContextDispatch()
  const companyContext = useCompanyContext()

  const { errors } = useValidationContext()

  const validationHelper = useValidationHelper({
    errors: errors?.siteOwner ?? null,
  })

  const initialCompany = useMemo(() => {
    return companyContext
      ? {
          id: companyContext?.selectedCompany?.company.id ?? '',
          label: companyContext?.selectedCompany?.company.name ?? '',
          primaryText: companyContext?.selectedCompany?.company.name ?? '',
        }
      : null
  }, [companyContext])

  // Companies where auth user have edit/owner priveleges
  const companyPermissions = authUser?.userPermissions?.userCompanyPermissions
  const options: IAutocompleteOption[] = []
  if (companyPermissions) {
    Object.values(companyPermissions).forEach((company) => {
      options.push({
        __typename: '',
        id: company.companyId,
        label: company.companyName,
        primaryText: company.companyName,
        avatarIcon: <CompanyIcon />,
      })
    })
  }

  // Prefill with users current company
  useEffect(() => {
    if (initialCompany) {
      dispatch('SET_SITE_OWNER', { companyId: initialCompany.id })
    }
  }, [dispatch, initialCompany])

  const onSelectionChange: IAutocomplete['onSelectionChange'] = (
    selection,
    reason,
  ): void => {
    if (reason === 'clear') {
      dispatch('SET_SITE_OWNER', { companyId: '' })
    } else {
      dispatch('SET_SITE_OWNER', { companyId: selection[0].id })
    }
    validationHelper.handleFocus()
    validationHelper.handleBlur()
  }

  return (
    <>
      <FindUserOrCompany
        typename="COMPANY"
        initialSelection={initialCompany ? [initialCompany] : undefined}
        onSelectionChange={onSelectionChange}
        autocompleteProps={{
          options: options,
          disabled: initialCompany ? true : false,
          multiple: false,
          textFieldProps: {
            onFocus: validationHelper.handleFocus,
            onBlur: validationHelper.handleBlur,
            helperText: validationHelper.helperText,
            error: validationHelper.isError,
            required: true,
          },
        }}
      />
    </>
  )
}

export default SiteOwner
