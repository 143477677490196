import React, { useState } from 'react'

import Logo from 'components/common/graphics/logo'
import { Link } from '@material-ui/core'
import SignUpForm from './SignUpForm'

import { useStyles } from 'components/views/SignUp/styles'
import ColorCircularProgress from 'components/common/ColorCircularProgress'
import { Routes } from 'constants/routing'
import { useAuthContextActions } from 'state/context/authContext'

interface ISignupProps {
  history: any
}

function SignUp(props: ISignupProps): React.ReactElement {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const authActions = useAuthContextActions()

  const handleRedirect = (): void => {
    props.history.push(Routes.SIGN_IN)
  }

  const submitForm = (payload: any): void => {
    setLoading(true)
    authActions.signUp(payload).then(() => {
      setLoading(false)
    })
  }

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.searchSpinner}>
          <p>We are creating your user</p>
          <ColorCircularProgress />
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.subLogo}>
            Already have an account?{' '}
            <Link onClick={handleRedirect} className={classes.link} type="link">
              Sign In
            </Link>
          </div>

          <SignUpForm submitForm={submitForm} />
        </React.Fragment>
      )}
    </div>
  )
}

export default SignUp
