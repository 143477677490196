import inRange from 'lodash/inRange'

//
import { ISitePermission } from 'types/site'

export const isValid = (permission: P): boolean => {
  const timeRestrictionStart = permission?.timeRestrictionStart?.toMillis() || 0
  const timeRestrictionEnd = permission?.timeRestrictionEnd?.toMillis() || 0

  const invalidTimerange = permission.timeRestricted
    ? !inRange(
        Date.now(),
        (timeRestrictionStart || 0) as number,
        (timeRestrictionEnd || 0) as number,
      )
    : false

  if (
    (permission.bookingAllow ||
      permission.entryAllow ||
      permission.machinesAllow) &&
    !invalidTimerange
  ) {
    return true
  }

  return false
}

type P = {
  bookingAllow: ISitePermission['bookingAllow']
  entryAllow: ISitePermission['entryAllow']
  machinesAllow: ISitePermission['machinesAllow']
  timeRestricted: ISitePermission['timeRestricted']
  timeRestrictionStart: ISitePermission['timeRestrictionStart']
  timeRestrictionEnd: ISitePermission['timeRestrictionEnd']
}
