import { createStore, applyMiddleware, compose } from 'redux'
import Immutable from 'immutable'
import { apiMiddleware } from 'redux-api-middleware'

import installDevTools from 'immutable-devtools'

// router
import { routerMiddleware } from 'connected-react-router/immutable'

// middlewares
import createSagaMiddleware from 'redux-saga'

// helpers
import rootReducer from 'state/reducers'
//import rootSaga from 'state/sagas'
import history from './history'

const enhancers = []

const sagaMiddleware = createSagaMiddleware()
const middleware = [routerMiddleware(history), apiMiddleware, sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }

  ;(installDevTools as any)(Immutable)
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

export const store = createStore(rootReducer(history), composedEnhancers)

//sagaMiddleware.run(rootSaga)

export default store
