import React, { ReactElement } from 'react'

// Material-UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

//
import { ISiteListProps } from './types'
import SiteCard from '../SiteCard'

const SiteList = (props: ISiteListProps): ReactElement => {
  const { sites } = props

  return (
    <Box my={4}>
      <Grid container spacing={4}>
        {Object.values(sites).map(site => {
          return (
            <Grid key={site.id} item xs={6} sm={4} md={3} lg={2}>
              <SiteCard site={site} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default SiteList
