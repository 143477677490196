import { useState, useCallback } from 'react'

//
import { useFetch } from './useFetch'

const checkKeyword = (
  keyword: string,
): { type: 'text' | 'orgNr'; formattedKeyword: string } => {
  // Remove all whitespaces
  const trimmedKeyword = keyword.replace(/ /g, '')

  // Is it an orgNr?
  const type =
    trimmedKeyword.match(/^\d+$/) && trimmedKeyword.length === 9
      ? 'orgNr'
      : 'text'
  const formattedKeyword = type === 'orgNr' ? trimmedKeyword : keyword

  return {
    type,
    formattedKeyword,
  }
}

const useFindCompanies = (): Response => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IFirestoreCompany[]>([])
  const [error, setError] = useState<string | null>(null)
  const [post] = useFetch()

  const find = useCallback(
    async (props: IFetch): Promise<IFirestoreCompany[]> => {
      const { keyword, limit, existingCompany } = props
      try {
        setLoading(true)
        let companies = [] as IFirestoreCompany[]
        const { type, formattedKeyword } = checkKeyword(keyword)

        const res = await post({
          apiName: 'API2',
          endpoint: 'searchCompany',
          data: {
            companyName: type === 'text' ? formattedKeyword : '',
            orgNr: type === 'orgNr' ? formattedKeyword : undefined,
            limit,
            existingCompany,
          },
        })

        if (res.companies) {
          companies = res.companies.map((v: any) => {
            return v
          })
        }

        setLoading(false)
        setData(companies)
        return companies
      } catch (e) {
        setError(e)
        setLoading(false)
        setData([])
        return []
      }
    },
    [post],
  )

  const result = {
    loading,
    data: [...data],
    error,
  }

  return [find, result]
}
interface IFetch {
  keyword: string
  limit?: number
  existingCompany?: boolean
}

type Response = [
  (props: IFetch) => Promise<IFirestoreCompany[]>,
  {
    loading: boolean
    data: IFirestoreCompany[]
    error: any
  },
]

export default useFindCompanies
