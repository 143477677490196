import React, { ReactElement } from 'react'

// Material-UI
import { Theme, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

// Webtastic
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}))

const FullScreenLoading = (): ReactElement => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    </Paper>
  )
}

export default FullScreenLoading
