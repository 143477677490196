import { ISitePermission } from 'types/site'
import { isValid } from './'

export const formatUsers = (
  permissions: IFirestoreSitePermission['userSitePermissions'],
): ISitePermission[] => {
  const users: ISitePermission[] = Object.values(permissions).map(p => {
    return {
      entryAllow: p.entryAllow,
      bookingAllow: p.bookingAllow,
      machinesAllow: p.machinesAllow,
      chargeAllow: p.chargeAllow,
      editor: p.editor,
      viewer: p.viewer,
      timeRestricted: p.timeRestricted,
      timeRestrictionStart: p.timeRestrictionStart,
      timeRestrictionEnd: p.timeRestrictionEnd,

      id: p.userId,
      name: p.userName,

      userData: {
        id: p.userId,
        name: p.userName,
        imageUrl: p.userImageUrl,
        imageThumbUrl: p.userImageThumbUrl,
        companyId: p.userCompanyId,
        companyName: p.userCompanyName,
      },

      __typename: 'USER',
      __isValid: isValid(p),
    }
  })

  return users
}

export const formatCompanies = (
  permissions: IFirestoreSitePermission['companySitePermissions'],
): ISitePermission[] => {
  const companies: ISitePermission[] = Object.values(permissions).map(p => {
    return {
      entryAllow: p.entryAllow,
      bookingAllow: p.bookingAllow,
      machinesAllow: p.machinesAllow,
      chargeAllow: false,
      editor: null,
      viewer: null,
      timeRestricted: p.timeRestricted,
      timeRestrictionStart: p.timeRestrictionStart,
      timeRestrictionEnd: p.timeRestrictionEnd,

      id: p.companyId,
      name: p.companyName,

      companyData: {
        id: p.companyName,
        name: p.companyName,
        orgNr: p.companyOrgNr,
      },

      __typename: 'COMPANY',
      __isValid: isValid(p),
    }
  })
  return companies
}
