import React, { useEffect } from 'react'

import { Box, Button, IconButton } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import RemoveIcon from '@material-ui/icons/Close'

import useFileSelector from 'utils/fileSelector'

import { useStyles } from './styles'

export interface IImageArea {
  setFile: (image: File | undefined) => void
  imageUrl: string
}

function ImageArea(props: IImageArea): React.ReactElement {
  const { imageUrl, setFile } = props
  const classes = useStyles()
  const [
    openFileSelector,
    {
      rootProps: fileSelectorRootProps,
      inputProps: fileSelectorInputProps,
      initFiles,
    },
  ] = useFileSelector({ shouldReturnInitFiles: true })
  useEffect(() => {
    if (initFiles?.length) {
      const currentFile = Object.values(initFiles)[0] || undefined
      setFile(currentFile)
    }
  }, [initFiles, setFile])

  return (
    <Box className={classes.container}>
      {!!imageUrl && (
        <Box className={classes.imageContainer}>
          <IconButton
            className={classes.removeButton}
            onClick={(): void => {
              setFile(undefined)
            }}
            aria-label="Remove file"
          >
            <RemoveIcon />
          </IconButton>
          <img src={imageUrl} alt="" className={classes.image} />
        </Box>
      )}
      <Button
        color="primary"
        endIcon={<ImageIcon />}
        onClick={(): void => {
          openFileSelector()
        }}
      >
        Upload Image
      </Button>
      <div {...fileSelectorRootProps}>
        <input {...fileSelectorInputProps} />
      </div>
    </Box>
  )
}

export default React.memo(ImageArea)
