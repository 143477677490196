import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogTitle: {
      textAlign: 'center',
    },
    inputControlBlock: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  }
})
