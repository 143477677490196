import { Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    paddingTop: '100%' /* 1:1 Aspect Ratio */,
    position: 'relative' /* If you want text inside of it */,

    backgroundRepeat: 'no-repeat',
    backgroundSize: '250% 250%',
    backgroundPosition: 'center',
  },
  actionArea: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center',
    backdropFilter: 'blur(1px)',
  },

  siteName: {
    textShadow: '0 0 5px #000000',
  },
  chip: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
}))
