import { useEffect } from 'react'
import sortBy from 'lodash/sortBy'
import keyBy from 'lodash/keyBy'

//
import { useAuthUserContext } from 'state/context/authUserContext'
import { useContext as useCompanyContext } from 'state/context/companyContext'
import { useContextDispatch as useSiteDispatch } from 'state/context/siteContext'
import useFetchCompanySites from 'hooks/fetchSites/useFetchCompanySites'
import { useCompanyPolicy } from 'hooks/policies/useCompanyPolicy'

export const useSiteContextHandler = (): void => {
  const authUser = useAuthUserContext()
  const { selectedCompany } = useCompanyContext()
  const [fetch, { sites }] = useFetchCompanySites()

  const siteDispatch = useSiteDispatch()
  const { companyPolicy } = useCompanyPolicy()

  /**
   * Fetch sites in the selected company
   */
  useEffect(() => {
    if (authUser && selectedCompany) {
      // Owner: Subscribe to all sites
      if (companyPolicy().isOwner(selectedCompany.company)) {
        fetch({ companyId: selectedCompany.company.id })
      } else if (companyPolicy().isEditor(selectedCompany.company)) {
        // Company Editor: Subscribe to all sites
        fetch({ companyId: selectedCompany.company.id })
      } else {
        // Site editor/viewer

        fetch({
          companyId: selectedCompany.company.id,
          filter: {
            siteRoleWorkerId: authUser.uid as string,
          },
        })
      }
    }
  }, [authUser, companyPolicy, fetch, selectedCompany, siteDispatch])

  /**
   * Assign sites to context
   */
  useEffect(() => {
    if (sites) {
      // Sort by `name`
      // Sort by `archived`
      // Set object keys by `id`
      const sorted = keyBy(
        sortBy(
          sortBy(sites, s => s.name.toLowerCase()),
          ['archived'],
        ),
        'id',
      )

      siteDispatch('SET_SITES', { sites: sorted })
    } else {
      siteDispatch('RESET')
    }
  }, [siteDispatch, sites])
}
