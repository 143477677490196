import { useState } from 'react'

import { sortBy } from 'utils/sortBy'
import { ICompanyUserPermission } from 'types/company'

import * as db from 'utils/firestoreDB'

const useGetEmployeeRoles = (props: IProps): Response => {
  const { companyId } = props

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ICompanyUserPermission[]>([])

  /**
   * Fetch company admins, site admins and site viewers
   */
  const fetch: TFetch = async () => {
    setLoading(true)

    const companyPermissionsRes = await db
      .companyPermissions()
      .where('id', '==', companyId)
      .get()

    setLoading(false)

    const companyAdmins: ICompanyUserPermission[] = []
    const siteAdmins: ICompanyUserPermission[] = []

    if (!companyPermissionsRes.empty) {
      const doc = companyPermissionsRes.docs[0].data()

      /**
       * Company admins
       */
      doc?.userCompanyPermissions &&
        Object.values(doc.userCompanyPermissions).forEach(p => {
          const {
            userId,
            userName,
            userImageThumbUrl,
            userCompanyId,
            userCompanyName,
            editor,
            owner,
          } = p

          if (editor) {
            companyAdmins.push({
              userId,
              userName,
              userImageThumbUrl,
              userCompanyId,
              userCompanyName,
              companyPermission: {
                editor,
                owner,
              },
              sitePermissions: [],
            })
          }
        })

      /**
       * Site admins
       */

      const userSitePermissions: {
        [userId: string]: ICompanyUserPermission
      } = {}

      doc?.userSitePermissions &&
        Object.values(doc.userSitePermissions).forEach(s => {
          if (s.userPermissions) {
            Object.values(s.userPermissions).forEach(u => {
              const {
                userId,
                userName,
                userImageThumbUrl,
                userCompanyId,
                userCompanyName,
                editor,
                viewer,
              } = u
              if (editor || viewer) {
                userSitePermissions[userId] = {
                  ...userSitePermissions[userId],
                  userId,
                  userName,
                  userImageThumbUrl,
                  userCompanyId,
                  userCompanyName,
                  companyPermission: null,
                  sitePermissions: [
                    ...(userSitePermissions[userId]?.sitePermissions.length
                      ? userSitePermissions[userId]?.sitePermissions
                      : []),
                    ...(editor || viewer
                      ? [
                          {
                            siteId: s.siteId,
                            siteName: s.siteName,
                            roles: {
                              editor,
                              viewer,
                            },
                          },
                        ]
                      : []),
                  ],
                }
              }
            })
          }
        })

      /**
       * Sort sites alphabetically
       * Add to `siteAdmins` ...
       * ...or assign userSitePermission to user in `companyAdmins`
       */
      Object.values(userSitePermissions).forEach(u => {
        const isCompanyAdmin = companyAdmins.find(sa => sa.userId === u.userId)

        // sort alphabetically
        if (u?.sitePermissions) {
          u.sitePermissions.sort(sortBy('siteName'))
        }

        if (isCompanyAdmin) {
          // add site permissions the companyAdmin
          companyAdmins.forEach(ca => {
            if (ca.userId === u.userId) {
              ca.sitePermissions = u.sitePermissions
            }
          })
        } else {
          if (u?.sitePermissions) {
            siteAdmins.push(u)
          }
        }
      })
    }

    const merged = [...companyAdmins, ...siteAdmins]
    const sorted = merged.sort(sortBy('userName'))

    setData(sorted)

    return sorted
  }

  const result = {
    loading,
    data: data,
  }

  return [fetch, result]
}

interface IProps {
  companyId: string
}

type TFetch = () => Promise<ICompanyUserPermission[]>

type Response = [
  TFetch,
  {
    loading: boolean
    data: ICompanyUserPermission[]
  },
]

export default useGetEmployeeRoles
