import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

// Material-UI
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Chip } from '@material-ui/core'

//
import { ISiteCard } from './types'
import { useStyles } from './styles'
import { useAuthUserContextActions } from 'state/context/authUserContext'
import { Routes } from 'constants/routing'

/**
 * Card component with 1:1 aspect ration
 * Followed the guide from this example at w3schools:
 * https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
 */
const SiteCard = (props: ISiteCard): ReactElement => {
  const { site } = props

  // Hooks
  const classes = useStyles()
  const history = useHistory()
  const authUserActions = useAuthUserContextActions()

  const clickHandler = () => {
    if (site.archived) {
      authUserActions.addSite(site)
    }

    history.push(`${Routes.SITE}/${site.id}`)
  }

  return (
    <Card
      variant="outlined"
      className={classes.root}
      style={{
        backgroundImage: `url(${site.mapImageDarkModeThumbUrl})`,
      }}
    >
      <CardActionArea
        classes={{
          root: classes.actionArea,
        }}
        onClick={clickHandler}
        role="link"
        aria-label={`${site.name}`}
      >
        <CardContent>
          <Typography
            className={classes.siteName}
            variant="button"
            component="h2"
          >
            {site.name}
          </Typography>
          {site.archived && (
            <Chip className={classes.chip} size="small" label="Archived" />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default SiteCard
