import { TUserRole } from 'types/user'

export type CompanyInfo = {
  id?: string
  orgNr: string
  name: string
  mva: boolean
  existingCompany: boolean
  address: {
    zip: string
    street: string[]
    county: string
    country: string
    city: string
  }
}

export const formatCompanyAutocomplete = (data: any): CompanyInfo[] =>
  data.companies

export type UserOption = {
  id: string
  name: string
  companyName: string
  // tag: string;
  imageUrl: string
  imageThumbUrl?: string
  isCompanyAdmin?: boolean
  isSuperUser?: boolean
  siteRoles: {
    [key: string]: {
      role: TUserRole
      name: string
    }
  }
}

export const formatFirestoreUserAutocomplete = (data: any): UserOption[] =>
  data.docs.map(
    (el: any): UserOption => {
      const {
        name,
        company: { name: companyName },
        id,
        imageUrl,
        imageThumbUrl,
        isCompanyAdmin,
        isSuperUser,
        siteRoles,
      } = el.data()
      return {
        name,
        id,
        companyName: companyName.toLowerCase(),
        imageUrl,
        imageThumbUrl,
        isCompanyAdmin,
        isSuperUser,
        siteRoles,
      }
    },
  )

export const formatFirestoreCompanyAutocomplete = (data: any): CompanyInfo[] =>
  data.docs.map(
    (el: any): CompanyInfo => {
      const { name, orgNr, address, id, mva } = el.data()
      return { name, orgNr, address, id, mva, existingCompany: true }
    },
  )
