import React from 'react'

import ColorCircularProgress from 'components/common/ColorCircularProgress'

import { useStyles } from './styles'

function LoadingSpinner(): React.ReactElement {
  const classes = useStyles()

  return <ColorCircularProgress className={classes.spinner} />
}

export default LoadingSpinner
