export const errorCodes = {
  USER_NOT_FOUND: 'auth/user-not-found',
  USER_ALREADY_EXIST: 'auth/email-already-in-use',
  INVALID_PASSWORD: 'auth/wrong-password',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  MANY_REQUESTS: 'auth/too-many-requests',
}

export const serverErrors = {
  BAD_REQUEST: 'Missing Parameter',
  UNAUTHORIZED: 'Unauthorized',
  NOT_FOUND: 'Nonexisting endpoint',
  INTERNAL_ERROR: 'Internal server error',
}
