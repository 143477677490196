import { useCallback, useMemo, useState } from 'react'

//
import { useCloudFunction } from 'hooks/useCloudFunctions'
import {
  useContext as useDetailsContext,
  useContextDispatch as useDetailsContextDispatch,
} from './Details'
import { useContext as useGoogleMapsContext } from 'components/common/GoogleMap'
import { useContextDispatch as useStepperDispatch } from 'components/common/Stepper'

export const usePost: TUsePost = () => {
  const [loading, setLoading] = useState(false)
  const detailsContext = useDetailsContext()
  const detailsDispatch = useDetailsContextDispatch()
  const googleMapsContext = useGoogleMapsContext()
  const [fetch] = useCloudFunction()
  const stepperDispatch = useStepperDispatch()

  const post = useCallback(async () => {
    setLoading(true)
    const response = await fetch('createSite', {
      geofence: {
        paths: googleMapsContext.polygon?.path ?? [],
        zoom: googleMapsContext.zoom ?? 18,
        bufferDistanceMeters: 20,
      },
      name: detailsContext.siteName,
      ownerBuilderOrgNr: detailsContext.client?.orgNr ?? '',
      companyId: detailsContext.siteOwner ?? '',
      contactPersonIds: detailsContext.contacts ?? [],
      address: {
        street: detailsContext.address?.street ?? [],
        zip: detailsContext.address?.zip ?? '',
        city: detailsContext.address?.city ?? '',
        county: detailsContext.address?.county ?? '',
        country: detailsContext.address?.country ?? '',
      },
    })

    const siteId = response?.data?.site?.id

    if (siteId) {
      detailsDispatch('SET_REQUEST_SUCCESS', { siteId })
      stepperDispatch('SET_ACTIVE_STEP', { index: 3 })
    } else {
      detailsDispatch('SET_REQUEST_ERRORS', {
        errors: ['Error! Please try again or contact support.'],
      })
    }
    setLoading(false)
  }, [
    detailsContext,
    detailsDispatch,
    fetch,
    googleMapsContext,
    stepperDispatch,
  ])

  const returnState = useMemo(() => {
    return { loading }
  }, [loading])

  return [post, returnState]
}

type TUsePost = () => [Function, { loading: boolean }]
