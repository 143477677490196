import React, { useCallback, useEffect } from 'react'
import { GoogleMap } from '@react-google-maps/api'

//

import { calculatePath } from './utils/calculatePath'
import { IAddressField, useContext, useContextDispatch } from './context'
import { IMapContainer, PolygonCoordinate } from './types'
import Toolbar from './Toolbar'
import './global.css'
import Polygon from './Polygon'
import BufferSlider from './BufferSlider'

const defaultMapStyles = { height: '55vh', borderRadius: '4px' }

let _mapElement: any = null
let _placesService: any

function GMap(props: IMapContainer): React.ReactElement {
  const {
    placeId,
    readonly = false,
    polygon,
    zoom,
    showToolbar = false,
    showBuffer = false,
    children,
    onMapDragEnd,
  } = props

  const propsPolygonVisible = polygon?.visible
  const propsPolygonPath = polygon?.path

  // context
  const state = useContext()
  const polygonPath = state.polygon?.path
  const dispatch = useContextDispatch()

  /**
   * Function to sort place details
   */
  const filterPlaceDetails = (
    addressComponents: IAddressField[],
    filterValue: string,
  ): string => {
    return (
      addressComponents?.find((addComp: IAddressField) =>
        addComp.types.includes(filterValue),
      )?.long_name || ''
    )
  }

  /**
   * Function to set center coordinates of map by placeId
   */
  const setCenterPositionFromPlaceId = useCallback(
    (placeId: IMapContainer['placeId']): void => {
      _placesService.getDetails(
        {
          placeId: placeId,
          fields: ['geometry.location', 'address_components'],
        },
        (place: any) => {
          dispatch('SET_CENTER_POSITION', {
            position: {
              lat: place?.geometry.location.lat(),
              lng: place?.geometry.location.lng(),
            },
          })

          dispatch('SET_PLACE_DETAILS', {
            details: {
              streetNumber: filterPlaceDetails(
                place?.address_components,
                'street_number',
              ),
              route: filterPlaceDetails(place?.address_components, 'route'),
              sublocality: filterPlaceDetails(
                place?.address_components,
                'sublocality',
              ),
              postalTown: filterPlaceDetails(
                place?.address_components,
                'postal_town',
              ),
              city: filterPlaceDetails(
                place?.address_components,
                'administrative_area_level_2',
              ),
              county: filterPlaceDetails(
                place?.address_components,
                'administrative_area_level_1',
              ),
              country: filterPlaceDetails(place?.address_components, 'country'),
              postalCode: filterPlaceDetails(
                place?.address_components,
                'postal_code',
              ),
              colloquialArea: filterPlaceDetails(
                place?.address_components,
                'colloquial_area',
              ),
              locality: filterPlaceDetails(
                place?.address_components,
                'locality',
              ),
            },
          })
        },
      )
    },
    [dispatch],
  )

  /**
   * Initialize map
   * Get position from placeId and set state.centerPosition
   */
  const initMap = useCallback(
    (map: any) => {
      _mapElement = map
      _placesService = new window.google.maps.places.PlacesService(_mapElement)

      placeId && setCenterPositionFromPlaceId(placeId)

      // Set initial zoom from props.zoom or default to 18
      const initZoom = zoom || 18
      dispatch('SET_ZOOM', { zoom: initZoom })
    },
    [dispatch, placeId, setCenterPositionFromPlaceId, zoom],
  )

  /**
   * Update center of map from placeId
   */
  useEffect(() => {
    if (placeId && _mapElement && _placesService) {
      setCenterPositionFromPlaceId(placeId)
    }
  }, [placeId, setCenterPositionFromPlaceId])

  /**
   * Update state.centerPosition if user moves the map
   */
  const _onMapDragEnd = useCallback((): void => {
    const _position = _mapElement?.getCenter()
    const position = {
      lat: _position?.lat(),
      lng: _position?.lng(),
    }

    dispatch('SET_CENTER_POSITION', {
      position,
    })
    onMapDragEnd && onMapDragEnd(position)
  }, [dispatch, onMapDragEnd])

  /**
   * Update state.zoom if user changes zoom level
   */
  const _onZoomChanged = useCallback((): void => {
    const zoom = _mapElement?.getZoom()

    zoom > -1 && dispatch('SET_ZOOM', { zoom })
  }, [dispatch])

  /**
   * Set polygon
   * TODO: does this work if we want to edit an existing site (polygon)?
   */
  useEffect(() => {
    if (propsPolygonVisible) {
      if (propsPolygonPath) {
        dispatch('SET_POLYGON_PATH', { path: propsPolygonPath })
      } else {
        // If a state doesn't already exist, set new default state
        if (!polygonPath) {
          const defaultPath: PolygonCoordinate[] = calculatePath(_mapElement)
          dispatch('SET_POLYGON_PATH', { path: defaultPath })
        }
      }
    }
  }, [propsPolygonPath, dispatch, propsPolygonVisible, polygonPath])

  return (
    <>
      {showToolbar ? <Toolbar /> : null}
      <GoogleMap
        id="google-map"
        mapContainerStyle={defaultMapStyles}
        zoom={state.zoom}
        options={{
          scrollwheel: false,
        }}
        center={state.centerPosition}
        onDragEnd={_onMapDragEnd}
        onZoomChanged={_onZoomChanged}
        onLoad={initMap}
      >
        {polygon?.visible && state?.polygon?.path ? (
          <Polygon readonly={readonly} showBuffer={showBuffer} />
        ) : null}
        {children}
      </GoogleMap>
      {showBuffer && !readonly ? (
        <BufferSlider distance={state.polygon?.bufferDistance} />
      ) : null}
    </>
  )
}

export default React.memo(GMap)
