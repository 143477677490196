import React, { ReactElement, useCallback, useState, useEffect } from 'react'

// Material-UI
import Slider from '@material-ui/core/Slider'
import Box from '@material-ui/core/Box'
import DialogContentText from '@material-ui/core/DialogContentText'

//
import { IBufferSlider } from './types'
import { useContext, useContextDispatch } from '../context'
import { TBufferDistance } from '../context/types'

const defaultDistance = 20

const BufferSlider = (props: IBufferSlider): ReactElement => {
  const { distance = defaultDistance, sliderProps } = props

  const [_distance, setDistance] = useState<TBufferDistance>(distance)

  // context
  const state = useContext()
  const bufferDistance = state?.polygon?.bufferDistance
  const dispatch = useContextDispatch()

  const _setDistance = (
    event: React.ChangeEvent<{}>,
    value: number | number[],
  ): void => {
    setDistance(value as number)
  }

  const commitChange = useCallback((): void => {
    dispatch('SET_BUFFER_DISTANCE', { distance: _distance })
  }, [_distance, dispatch])

  useEffect(() => {
    setDistance(bufferDistance || defaultDistance)
  }, [bufferDistance])

  return (
    <Box my={3}>
      <DialogContentText id="buffer-slider-label">
        Buffer ({distance}m)
      </DialogContentText>
      <Slider
        value={_distance}
        min={20}
        max={50}
        step={1}
        onChange={_setDistance}
        onChangeCommitted={() => {
          commitChange()
        }}
        aria-labelledby="buffer-slider-label"
        valueLabelDisplay="auto"
        {...sliderProps}
      />
    </Box>
  )
}

export default BufferSlider
