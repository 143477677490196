import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'

const State = createContext<any>(null)
const Actions = createContext<any>(null)

export const useMenuDrawerContext = (): ContextTypes => useContext(State)
export const useMenuDrawerContextActions = (): ActionTypes =>
  useContext(Actions)

const Provider = (props: P): React.ReactElement => {
  const { children } = props
  const [state, setState] = useState<{ open: boolean }>({
    open: localStorage.getItem('openMenuDrawer') === 'true' ? true : false,
  })

  const actions: ActionTypes = useMemo(() => {
    return {
      setOpen: (value): void => {
        setState(state => {
          return {
            ...state,
            open: value,
          }
        })
      },
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('openMenuDrawer', state.open ? 'true' : 'false')
  }, [state.open])

  useEffect(() => {
    localStorage.setItem('openMenuDrawer', state.open ? 'true' : 'false')
  }, [state.open])

  return (
    <State.Provider value={state}>
      <Actions.Provider value={actions}>{children}</Actions.Provider>
    </State.Provider>
  )
}

export default Provider

type P = {
  children: React.ReactNode
}

type ContextTypes = {
  open: boolean
}

type ActionTypes = {
  setOpen: (value: boolean) => void
}
