import React, { useCallback, useEffect, useState } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import EditSiteIcon from '@material-ui/icons/PinDrop'
import { Theme } from '@material-ui/core/styles'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

// Common
import GoogleMap, {
  LoadScript,
  Provider as GoogleMapContextProvider,
  PolygonCoordinate,
  useContext as useGoogleMapContext,
  useContextDispatch as useGoogleMapDispatch,
} from 'components/common/GoogleMap'

//
import { IEditSiteDialog } from './types'
import { useContext as useSiteContext } from 'state/context/siteContext'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import { useFetch } from 'hooks/useFetch'

const libraries = ['places']

function EditSiteDialog(props: IEditSiteDialog): React.ReactElement {
  const {
    onClose = (): void => {
      // do nothing
    },
  } = props

  const [openState, setOpenState] = useState(true)
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] =
    useState<boolean>(false)

  // Context
  const { selectedSite } = useSiteContext()
  const googleMapContext = useGoogleMapContext()
  const zoom = googleMapContext.zoom
  const historyIndex = googleMapContext.polygonHistory?.index
  const dispatchGoogleMap = useGoogleMapDispatch()

  // Hooks
  const { sitePolicy } = useSitePolicy()
  const [fetch, { loading }] = useFetch()

  const policyCanUpdate = selectedSite && sitePolicy().update(selectedSite)

  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  /**
   * Set initial polygon
   */

  useEffect(() => {
    const path = selectedSite?.geofence.paths as unknown as PolygonCoordinate[]

    dispatchGoogleMap('SET_POLYGON_PATH', { path })
  }, []) // eslint-disable-line

  useEffect(() => {
    zoom && setHasChanged(true)
  }, [zoom])

  /**
   * Update "has changed" and enable SAVE button
   */
  useEffect(() => {
    if (historyIndex) {
      setHasChanged(true)
    } else {
      setHasChanged(false)
    }
  }, [historyIndex])

  /**
   * Close the Dialog or show warning if unsaved data
   */
  const closeDialog = useCallback(() => {
    if (hasChanged) {
      setShowUnsavedChangesWarning(true)
    } else {
      setOpenState(false)
      setTimeout(() => {
        onClose()
      }, 300)
    }
  }, [hasChanged, onClose])

  /**
   * Close unsaved
   */
  const closeUnsavedChangesWarning = (): void => {
    setShowUnsavedChangesWarning(false)
  }

  /**
   * Save changes
   */
  const handleSave = useCallback((): void => {
    const currentGeofence = {
      bufferDistanceMeters: googleMapContext.polygon?.bufferDistance,
      zoom: googleMapContext.zoom,
      paths: googleMapContext.polygon?.path,
    }
    fetch({
      endpoint: 'updateSiteGeofence',
      data: {
        siteId: selectedSite?.id,
        updateGeofence: true,
        geofence: currentGeofence,
      },
    }).then((res) => {
      if (res.code === 'C2000') {
        window.furball.success('The site was updated')

        dispatchGoogleMap('RESET_POLYGON_HISTORY')

        /* updateSelectedSite({
        ...selectedSite,
        geofence: {
          ...selectedSite.geofence,
          ...currentGeofence,
        },
      }) */
      } else {
        window.furball.error('Error! Could not save. ' + res?.code)
      }
    })
    setHasChanged(false)
  }, [dispatchGoogleMap, fetch, googleMapContext, selectedSite])

  if (!selectedSite) return <></>

  return (
    <Dialog
      open={openState}
      onClose={(): void => {
        closeDialog()
      }}
      titleIcon={<EditSiteIcon fontSize="large" />}
    >
      <DialogTitle>Edit site</DialogTitle>
      <DialogContent>
        <DialogContentText>Site: {selectedSite?.name} </DialogContentText>

        <Box pt={3}>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
            libraries={libraries}
          >
            <GoogleMap
              polygon={{ visible: true }}
              showToolbar
              showBuffer
              zoom={selectedSite.geofence.zoom}
            />
          </LoadScript>
        </Box>

        <Dialog
          open={showUnsavedChangesWarning}
          onClose={closeUnsavedChangesWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullHeight={false}
          maxWidth={'sm'}
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to leave?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Changes will not be saved.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenState(false)
                setTimeout(() => {
                  onClose()
                }, 300)
              }}
            >
              Leave
            </Button>
            <Button color="primary" onClick={closeUnsavedChangesWarning}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
      {policyCanUpdate ? (
        <DialogActions>
          {/* <Button onClick={handleReset} disabled={!hasChanged}>
            Reset changes
          </Button> */}
          <Button
            variant={sm ? 'text' : 'contained'}
            color="primary"
            isWaiting={loading}
            onClick={handleSave}
            disabled={!hasChanged}
          >
            Save
          </Button>
        </DialogActions>
      ) : (
        <div />
      )}
    </Dialog>
  )
}

const EditSiteDialogWithContextProvider = (
  props: IEditSiteDialog,
): React.ReactElement => {
  const { selectedSite } = useSiteContext()

  if (!selectedSite) return <></>

  return (
    <GoogleMapContextProvider
      initialState={{
        centerPosition: {
          lat: selectedSite.geofence.center.latitude,
          lng: selectedSite.geofence.center.longitude,
        },
        polygon: {
          path: [],
          bufferDistance: selectedSite.geofence.bufferDistanceMeters,
        },
      }}
    >
      <EditSiteDialog {...props} />
    </GoogleMapContextProvider>
  )
}

export default EditSiteDialogWithContextProvider
