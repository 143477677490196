import React, { createContext, useState, useContext, useEffect } from 'react'

const SelectedSiteContext = createContext<any>(null)
const SelectedSiteActionsContext = createContext<any>(null)

export const useSelectedSiteContext = (): IFirestoreSite =>
  useContext(SelectedSiteContext)
export const useSelectedSiteActions = (): any =>
  useContext(SelectedSiteActionsContext)

const Provider = (props: P): React.ReactElement => {
  const { children } = props

  const [selectedSite, setSelectedSite] = useState<IFirestoreSite | null>(null)

  useEffect(() => {
    if (selectedSite) {
      localStorage.setItem('siteSelectedSite', selectedSite.id)
    }
  }, [selectedSite])

  return (
    <SelectedSiteContext.Provider value={selectedSite}>
      <SelectedSiteActionsContext.Provider value={setSelectedSite}>
        {children}
      </SelectedSiteActionsContext.Provider>
    </SelectedSiteContext.Provider>
  )
}

export default Provider

type P = {
  children: React.ReactNode
}
