import { CrewListData } from 'state/actions/site'

export const formatSiteData = (data: any): IFirestoreSite => {
  const { geofence, ...rest } = data

  // Note: internal code should handle `latitude` and `longitude`
  // instead of doing this loop on every single site.
  geofence.paths = geofence.paths.map((el: any) => ({
    lat: el.latitude,
    lng: el.longitude,
  }))

  return {
    geofence,
    ...rest,
  }
}

export const formatCrewListData = (
  data: IFirestoreCheckSession[],
): CrewListData[] => {
  return data.map(docData => {
    const {
      id,
      open,
      user: { name, company, imageUrl, imageThumbUrl },
      timestampIn,
    } = docData

    return {
      id,
      open,
      name,
      companyName: company?.name,
      orgNr: company?.orgNr,
      imageUrl,
      imageThumbUrl,
      timestampIn: timestampIn.seconds * 10 ** 3,
      data: docData,
    } as CrewListData
  })
}
