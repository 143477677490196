import React, { useState, useEffect, useCallback } from 'react'

// Material UI
import { Typography, TextField, FormControl } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import ImageArea from 'sections/systemAdmin/components/common/ImageArea'
import {
  ICompany,
  IUploadCompany,
} from 'sections/systemAdmin/state/types/saletool'
import { useStyles } from './styles'
import {
  numberRegExp,
  formErrors,
} from 'sections/systemAdmin/constants/salestool'
import { getCompaniesFromBrreg } from 'sections/systemAdmin/utils/company'

export interface ICompanyDialog {
  onClose: () => void
  company?: ICompany
  saveCompany(payload: IUploadCompany, file: File): void
  isEdit?: boolean
}

interface IOrganization {
  name: string
  orgNr: string
}

function CompanyDialog(props: ICompanyDialog): React.ReactElement {
  const { onClose, company, saveCompany, isEdit } = props
  const [isOpen, toggleDialog] = useState(true)
  const [currentCompanyName, setCurrentCompanyName] = useState('')
  const [options, setOptions] = useState<IOrganization[]>([])
  const [companyNr, setCompanyNr] = useState('')
  const [nameError, setNameError] = useState('')
  const [file, setFile] = useState<File | undefined>(undefined)
  const [fileError, setFileError] = useState('')
  const [imageUrl, setImageUrl] = useState(company?.logoUrl || '')
  const classes = useStyles()

  const setImage = useCallback((imageFile: File | undefined) => {
    setFile(imageFile)
    setImageUrl(imageFile ? URL.createObjectURL(imageFile) : '')
    setFileError(imageFile ? '' : formErrors.emptyImg)
  }, [])

  const submitForm = (e: any): void => {
    e.preventDefault()

    let isErrorExist = false
    if (!isEdit && !companyNr) {
      isErrorExist = true
      setNameError(formErrors.required)
    }

    if (!file) {
      isErrorExist = true
      setFileError(formErrors.emptyImg)
    }

    if (!isErrorExist && file) {
      saveCompany(
        props.company ? { id: props.company.id } : { orgNr: companyNr },
        file,
      )
    }
  }

  const handleUserSelect = useCallback(
    (e: any, value: any): void => {
      if (!value) return
      const currentCompany = options.find(company => company.name === value)

      if (currentCompany) {
        setNameError('')
        setCompanyNr(currentCompany.orgNr)
      }
    },
    [options],
  )

  useEffect(() => {
    if (!currentCompanyName) {
      setOptions([])
      return
    }

    const isNumeric = numberRegExp.test(currentCompanyName)
    if (isNumeric && currentCompanyName.length !== 9) {
      return
    }

    const params = isNumeric
      ? { orgNr: currentCompanyName }
      : { companyName: currentCompanyName }
    getCompaniesFromBrreg(params).then(value => {
      setOptions(value)
    })
  }, [currentCompanyName])

  const handleInputChange = useCallback(
    (e: any): void => {
      const value = e.target.value
      setNameError(value ? '' : formErrors.required)
      setCurrentCompanyName(value)
      if (companyNr) {
        setCompanyNr('')
      }
    },
    [companyNr],
  )

  return (
    <Dialog
      open={isOpen}
      onClose={(): void => {
        toggleDialog(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      fullHeight={false}
      maxWidth="md"
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle} variant="h5" component="h3">
          {isEdit ? company?.name : 'New company'}
        </Typography>
      </DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent>
          {!isEdit && (
            <FormControl className={classes.inputControlBlock}>
              <Autocomplete
                id="name"
                openOnFocus={true}
                size="small"
                popupIcon={null}
                noOptionsText="Enter name of company registered in Brønnøysundregisteret"
                options={options.length > 0 ? options.map(opt => opt.name) : []}
                renderInput={(params: any): React.ReactElement => {
                  return (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, autoComplete: 'off' }}
                      error={!!nameError}
                      helperText={nameError}
                    />
                  )
                }}
                onChange={handleUserSelect}
                onInputChange={handleInputChange}
              />
            </FormControl>
          )}
          <div className={classes.imageContainer}>
            <ImageArea imageUrl={imageUrl} setFile={setImage} />
            {fileError && (
              <Typography
                className={classes.imgError}
                variant="body1"
                component="p"
              >
                {fileError}
              </Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default React.memo(CompanyDialog)
