import React, { useEffect, useState, useCallback } from 'react'
import clsx from 'clsx'

// Material UI
import { Box, Grid, Typography, Button } from '@material-ui/core'

// Webtastic
import useStyledScrollbar from '@naevaas/webtastic/dist/hooks/useStyledScrollbar'

//
import {
  ICategory,
  ICreateCategory,
} from 'sections/systemAdmin/state/types/saletool'
import { Modals } from 'sections/systemAdmin/constants/routing'
import {
  createCategory,
  deleteCategories,
  getCategories,
} from 'sections/systemAdmin/utils/category'

import LoadingSpinner from 'sections/systemAdmin/components/common/LoadingSpinner'
import Navigation from 'sections/systemAdmin/components/common/Navigation'
import CategoriesTable from 'sections/systemAdmin/components/common/CategoriesTable'
import DeleteDialog from '../DeleteDialog'
import CreateCategoryDialog from '../CreateCategoryDialog'

import { useStyles } from './styles'

function Categories(): React.ReactElement {
  const [categories, setCategories] = useState<ICategory[]>([])
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(
    [],
  )
  const [dialogView, toggleDialog] = useState('')
  const [isLoading, setLoadingState] = useState(true)
  const styledScrollbar = useStyledScrollbar()
  const classes = useStyles()

  const closeDialog = useCallback(() => {
    toggleDialog('')
  }, [])

  const handleGetCategories = (): void => {
    getCategories().then((data: ICategory[]) => {
      setCategories(data)
      setLoadingState(false)
    })
  }
  const handleCreateCategory = useCallback((category: ICreateCategory) => {
    setLoadingState(true)
    createCategory(category).then(() => {
      handleGetCategories()
    })
  }, [])

  const handleDeleteCategories = useCallback(() => {
    setLoadingState(true)
    deleteCategories(selectedCategories).then(() => {
      setSelectedCategories([])
      handleGetCategories()
      closeDialog()
    })
  }, [selectedCategories, closeDialog])

  useEffect(() => {
    handleGetCategories()
  }, [])

  const selectedCategoriesLength = selectedCategories.length

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="flex-start"
      padding="24px"
      flexDirection="column"
    >
      <Navigation />
      <Typography className={classes.pageTitle} variant="h6" component="h2">
        Categories
      </Typography>
      <div className={classes.controlPanel}>
        <Button
          className={classes.createButton}
          color="primary"
          onClick={(): void => {
            toggleDialog(Modals.CREATE_CATEGORY)
          }}
        >
          Create new category
        </Button>
        {!!selectedCategoriesLength && (
          <Button
            color="primary"
            onClick={(): void => {
              toggleDialog(Modals.DELETE_CATEGORIES)
            }}
          >
            Delete {selectedCategoriesLength}{' '}
            {selectedCategoriesLength === 1 ? 'category' : 'categories'}
          </Button>
        )}
      </div>
      {!!categories.length && (
        <Grid
          container
          spacing={3}
          className={clsx(
            classes.cardsContainer,
            styledScrollbar.styledScrollbar,
          )}
        >
          <CategoriesTable
            categories={categories}
            selectedCategories={selectedCategories}
            selectCategory={setSelectedCategories}
          />
        </Grid>
      )}
      {dialogView === Modals.DELETE_CATEGORIES && (
        <DeleteDialog
          deleteItem={handleDeleteCategories}
          onClose={closeDialog}
          title="Delete selected categories?"
        />
      )}
      {dialogView === Modals.CREATE_CATEGORY && (
        <CreateCategoryDialog
          onClose={closeDialog}
          createCategory={handleCreateCategory}
        />
      )}
    </Box>
  )
}

export default Categories
