import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300,
    marginLeft: 'auto',
    marginRight: 'auto',

    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardActionArea: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  subtitle: {
    textTransform: 'uppercase',
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
}))
