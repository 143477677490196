import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    pageTitle: {
      margin: `${theme.spacing()}px 0`,
      textTransform: 'uppercase',
    },
    controlPanel: {
      alignSelf: 'center',
      display: 'flex',
    },
    cardsContainer: {
      marginTop: theme.spacing(2),
      overflowY: 'auto',
    },
    wrapper: {
      height: '100%',
    },
    cardWrapper: {
      height: '100%',
      position: 'relative',
    },
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    image: {
      height: '100%',
      display: 'block',
      margin: '0 auto',
    },
    imageContainer: {
      height: '200px',
      maxWidth: '400px',
      overflow: 'hidden',
      margin: `${theme.spacing()}px auto`,
    },
    editButton: {
      margin: 'auto 0 0 0',
    },
    checkbox: {
      marginLeft: 'auto',
      position: 'absolute',
      top: 0,
      right: 0,
    },
    createButton: {
      '&:not(:only-child)': {
        marginRight: theme.spacing(),
      },
    },
    cardTitle: {
      marginTop: theme.spacing(2),
    },
  }
})
