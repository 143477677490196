import React, { SyntheticEvent, useState, useEffect, useCallback } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuItem from '@material-ui/core/MenuItem'

//
import { INavigation } from './types'
import { useStyles } from './styles'

export default function Navigation(props: INavigation): React.ReactElement {
  const { allSites, selectedSite, onSelectSite, actions } = props

  const [currentKey, setCurrentKey] = useState(selectedSite?.id || '')

  const classes = useStyles()

  useEffect(() => {
    const siteId = selectedSite?.id
    siteId && setCurrentKey(siteId || '')
  }, [selectedSite]) // eslint-disable-line

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const value = event.target.value as number
    const keys = Object.keys(allSites)

    onSelectSite(keys[value])
  }

  const getNextKey = useCallback(
    () => {
      const keys = Object.keys(allSites)
      let nextIndex = keys.indexOf(currentKey) + 1

      if (nextIndex === keys.length) nextIndex = 0

      return keys[nextIndex]
    },
    [currentKey, allSites], // eslint-disable-line
  )

  const getPrevKey = useCallback(
    () => {
      const keys = Object.keys(allSites)
      let prevIndex = keys.indexOf(currentKey) - 1

      if (prevIndex < 0) prevIndex = keys.length - 1

      return keys[prevIndex]
    },
    [currentKey, allSites], // eslint-disable-line
  )

  const nextSite = useCallback(
    (_e: SyntheticEvent<HTMLButtonElement>) => {
      const newKey = getNextKey()
      onSelectSite(newKey)
      setCurrentKey(newKey)
    },
    [currentKey, selectedSite, allSites], // eslint-disable-line
  )

  const prevSite = useCallback(
    (_e: SyntheticEvent<HTMLButtonElement>) => {
      const newKey = getPrevKey()
      onSelectSite(newKey)
      setCurrentKey(newKey)
    },
    [currentKey, selectedSite, allSites], // eslint-disable-line
  )

  const getIndex = useCallback(() => {
    const index = Object.keys(allSites).indexOf(currentKey)

    if (index < 0) return 0

    return index
  }, [allSites, currentKey])

  return (
    <>
      <Box style={{ width: '100%', zIndex: 1 }} px={1}>
        <Paper style={{ width: '100%' }} elevation={2}>
          <Grid
            container
            alignItems="center"
            className={classes.selectSiteContainer}
          >
            <Grid item xs={12} sm={8} md={6}>
              <Box display="flex" alignItems="center">
                <Box>
                  <Tooltip title={allSites[getPrevKey()].name}>
                    <IconButton
                      color="primary"
                      aria-label={allSites[getPrevKey()].name}
                      onClick={prevSite}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box flex="auto" px={1} overflow="hidden">
                  <Select
                    disableUnderline={true}
                    className={classes.inputBase}
                    classes={{
                      select: classes.select,
                    }}
                    value={getIndex()}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{
                      'aria-label': 'Select site',
                    }}
                  >
                    {!selectedSite && (
                      <MenuItem value={''}>Select site...</MenuItem>
                    )}
                    {Object.values(allSites).map((site, i) => {
                      return (
                        <MenuItem key={site.id} value={i}>
                          {site.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Box>
                <Box>
                  <Tooltip title={allSites[getNextKey()].name}>
                    <IconButton
                      color="primary"
                      aria-label={allSites[getNextKey()].name}
                      onClick={nextSite}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
            {actions && (
              <Grid item xs={12} sm={4} md={6}>
                <Box textAlign="right" whiteSpace="nowrap">
                  {actions}
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    </>
  )
}
