import { Switch, Route, Redirect } from 'react-router'
import React from 'react'
import { Routes } from 'constants/routing'

import BookingRouter from 'sections/booking/BookingRouter'
//import ChatRouter from 'sections/chat/ChatRouter'
import SystemAdminRouter from 'sections/systemAdmin/components/router/Router'
import SiteAdminRouter from 'sections/siteAdmin/SiteAdminRouter'

const CustomRouter = (): React.ReactElement => (
  <Switch>
    {/** Booking */}
    <Route path={[Routes.BOOKING]} component={BookingRouter} />

    {/** Chat */}
    {/* <Route path={[Routes.CHAT, Routes.CHAT + '/:id']} component={ChatRouter} /> */}

    {/** Site admin */}
    <Route path={[Routes.SITE, Routes.SITES]} component={SiteAdminRouter} />

    {/** System admin */}
    <Route path={[Routes.SYSTEM]} component={SystemAdminRouter} />

    <Redirect to={`${Routes.SITE}/`} />
  </Switch>
)

export default CustomRouter
