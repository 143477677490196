import React, { useEffect } from 'react'

// Material-UI
import Container from '@material-ui/core/Container'

//
import AppBar from './AppBar'
import { IPageWithAppBar } from './types'

const PageWithAppBar = (props: IPageWithAppBar): React.ReactElement => {
  const {
    pageId = 'page',
    children,
    AppBarProps = {},
    ContainerProps = {},
  } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageId])

  return (
    <>
      <AppBar {...AppBarProps} />

      <Container maxWidth="lg" {...ContainerProps}>
        {children || ''}
      </Container>
    </>
  )
}

export default PageWithAppBar
