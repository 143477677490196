import { auth, firestore, storage } from 'firebase'
import { IProduct } from '../state/types/saletool'
import { uploadImageFile } from './resources'

export const uploadProduct = async (
  product: IProduct,
  file?: File,
): Promise<any> => {
  try {
    if (product.id) {
      product.productId = product.id
    }
    if (file) {
      if (product.imageUrl) {
        const currentFileRef = storage().refFromURL(product.imageUrl)
        await currentFileRef.delete()
      }
      const fullPath = await uploadImageFile(file, 'product')
      product.imagePath = fullPath
    }
    const token = await auth().currentUser?.getIdToken(true)
    await fetch(
      process.env.REACT_APP_API_BASE_URL + 'apiAdmin/updateScpssProduct',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      },
    )
  } catch (e) {
    window.furball.error('Something went wrong. Could not update the product')
    console.log(e)
  }
}

export const getProducts = async (): Promise<IProduct[]> => {
  try {
    const productList = [] as IProduct[]
    const productCollection = firestore()
      .collection('scpssProducts')
      .where('archived', '==', false)
    const querySnapshot = await productCollection.get()
    querySnapshot.forEach(doc => {
      productList.push(doc.data() as IProduct)
    })

    return productList
  } catch (e) {
    window.furball.error('Something went wrong. Could not get products')
    console.log(e)
    return [] as IProduct[]
  }
}
