import React from 'react'
import { Link } from 'react-router-dom'

// Material UI
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import PlusIcon from '@material-ui/icons/Add'

//
import { routes } from 'constants/routing'

const CreateSiteAppBarButton = (): React.ReactElement => {
  return (
    <Tooltip key="createsite" title="Create site" enterDelay={500}>
      <IconButton
        component={Link}
        to={routes.SITES.NEW}
        aria-label="Create site"
      >
        <PlusIcon />
      </IconButton>
    </Tooltip>
  )
}

export default CreateSiteAppBarButton
