import React, { useCallback, useEffect, useState } from 'react'

// Material UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AccessSiteIcon from '@material-ui/icons/VerifiedUser'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// Webtastic
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@naevaas/webtastic/dist/components/Dialog'
import CircularProgress from '@naevaas/webtastic/dist/components/CircularProgress'

//
import { ISiteAccessDialog } from './types'
import { ISitePermission } from 'types/site'
import List from './List'
import EditAccess from './EditAccess'
import AddAccess, { TAddAccessTypename } from './AddAccess'
import { useContext as useSiteContext } from 'state/context/siteContext'
import { useSitePolicy } from 'hooks/policies/useSitePolicy'
import useSitePermissionsListener from 'hooks/useSitePermissionsListener'

function SiteAccessDialog(props: ISiteAccessDialog): React.ReactElement {
  const {
    onClose = (): void => {
      // do nothing
    },
  } = props

  const [openState, setOpenState] = useState(true)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [editAccesses, setEditAccesses] = useState<{
    __typename: TAddAccessTypename
    items: ISitePermission[]
    new?: boolean
  } | null>(null)
  const [openAddAccess, setOpenAddAccess] = useState(false)

  // Context
  const { selectedSite } = useSiteContext()
  const selectedSiteId = selectedSite?.id ?? ''

  // Hooks
  const { sitePolicy } = useSitePolicy()
  const [
    permissionListener,
    { state: sitePermissions, isListening },
  ] = useSitePermissionsListener()

  useEffect(() => {
    permissionListener({ siteId: selectedSiteId })
  }, [permissionListener, selectedSite, selectedSiteId])

  const handleEditItem = (item: ISitePermission): void => {
    setEditAccesses({ __typename: item.__typename, items: [item] })
  }

  const doneEditing = useCallback((): void => {
    setEditAccesses(null)
  }, [])

  const handleNext = useCallback(
    (
      typename: ISitePermission['__typename'],
      items: ISitePermission[],
    ): void => {
      setOpenAddAccess(false)
      setEditAccesses({ __typename: typename, items, new: true })
    },
    [],
  )

  if (!selectedSite) return <></>

  return (
    <Dialog
      open={openState}
      onClose={(): void => {
        setOpenState(false)
        setTimeout(() => {
          onClose()
        }, 300)
      }}
      titleIcon={<AccessSiteIcon fontSize="large" />}
    >
      <DialogTitle>Site access</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <DialogContentText>Site: {selectedSite.name} </DialogContentText>
          </Grid>
          {sitePolicy().update(selectedSite) && (
            <Grid item xs sm style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={(): void => setOpenAddAccess(true)}
              >
                Create access
              </Button>
            </Grid>
          )}
        </Grid>

        <Box my={2}>
          <Tabs
            value={tabIndex}
            onChange={(_e, p: number): void => {
              setTabIndex(p)
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="Site access navigation"
          >
            <Tab label="Valid" />
            <Tab label="Invalid" />
          </Tabs>
        </Box>

        {isListening ? (
          <List
            site={selectedSite}
            status={tabIndex === 0 ? 'VALID' : 'INVALID'}
            sitePermissions={sitePermissions || null}
            handleEditItem={handleEditItem}
            onCreateAccess={(): void => setOpenAddAccess(true)}
          />
        ) : (
          <Box textAlign="center" mt={4}>
            <CircularProgress />
          </Box>
        )}
        {editAccesses && (
          <EditAccess
            onClose={doneEditing}
            site={selectedSite}
            accesses={editAccesses}
          />
        )}
        {openAddAccess && (
          <AddAccess
            onClose={(): void => {
              setOpenAddAccess(false)
            }}
            onNext={handleNext}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SiteAccessDialog
