import { auth, firestore } from 'firebase'
import { uploadImageFile } from './resources'
import { formatCompanyAutocomplete } from 'state/formatters/autocomplete'
import { ICompany, IInput, IUploadCompany } from '../state/types/saletool'

export const uploadCompany = async (
  data: IUploadCompany,
  file: File,
): Promise<any> => {
  try {
    const imagePath = await uploadImageFile(file, 'company')
    data.imagePath = imagePath

    const token = await auth().currentUser?.getIdToken(true)
    await fetch(
      process.env.REACT_APP_API_BASE_URL +
        'apiAdmin/updateScpssProductProvider',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    )
  } catch (e) {
    window.furball.error(
      'Something went wrong. Could not update/create company',
    )
    console.log(e)
  }
}

export const deleteCompanies = async (providerIds: string[]): Promise<any> => {
  try {
    const token = await auth().currentUser?.getIdToken(true)
    const body = JSON.stringify({
      providerIds,
    })
    await fetch(
      process.env.REACT_APP_API_BASE_URL +
        'apiAdmin/archiveScpssProductProviders',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body,
      },
    )
  } catch (e) {
    window.furball.error('Something went wrong. Could not delete the companies')
    console.log(e)
  }
}

export const getCompaniesFromBrreg = async (params: IInput): Promise<any> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}api2/searchCompany`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + process.env.REACT_APP_API2_TOKEN,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    )
    const data = await response.json()
    return data.companies.length !== 0 ? formatCompanyAutocomplete(data) : []
  } catch (e) {
    window.furball.error(
      'Something went wrong. Could not update companies list',
    )
    console.log(e)
    return []
  }
}

export const getCompanies = async (): Promise<ICompany[]> => {
  try {
    const companyCollection = firestore()
      .collection('scpssProductProviders')
      .where('archived', '==', false)
    const companyList = [] as ICompany[]
    const res = await companyCollection.get()
    res.forEach(doc => companyList.push(doc.data() as ICompany))
    return companyList
  } catch (e) {
    window.furball.error('Something went wrong. Could not get companies list')
    console.log(e)
    return [] as ICompany[]
  }
}
