import { Record, RecordOf } from 'immutable'

export interface ILayoutReducer {
  isCreatingUser: boolean
}

const initialStateObject = {
  isCreatingUser: false,
}

const LayoutFactory: Record.Factory<ILayoutReducer> =
  Record<ILayoutReducer>(initialStateObject)

const initialState: RecordOf<ILayoutReducer> = LayoutFactory()

export default function foo(state = initialState, { type }: any) {
  switch (type) {
    default: {
      return state
    }
  }
}
