import { useEffect } from 'react'
import * as yup from 'yup'

// Webtastic
import {
  useContextDispatch as useValidationDispatch,
  IState as IValidationState,
} from '@naevaas/webtastic/dist/components/TextFieldValidation'

//
import { useContextDispatch as useStepperDispatch } from 'components/common/Stepper'
import { useContext } from './context/index'

const schema = yup.object({
  siteName: yup.string().required('Required field').min(3, 'Min 3 characters'),

  address: yup.object({
    street: yup.string().required('Required field'),
    city: yup.string().required('Required field'),
    zip: yup.string().required('Required field'),
    county: yup.string(),
    country: yup.string().required('Required field'),
  }),
  contacts: yup.array().min(1, 'Add at least one user'),
  siteOwner: yup.string().required('Please select a company'),
  client: yup.string().required('Please select a c\u200bompany'),
})

const useValidation = (): void => {
  const formState = useContext()
  const validationDispatch = useValidationDispatch()
  const stepperDispatch = useStepperDispatch()

  useEffect(() => {
    schema.isValid(formState).then((v) => {
      //v ? console.log('VALID', v) : console.log('INVALID', v)
      stepperDispatch('SET_STEP_VALIDATION', { index: 2, validated: v })
    })

    schema
      .validate(formState, { abortEarly: false })
      .then((v) => {
        validationDispatch('SET_ERRORS', { errors: null })
      })
      .catch((err) => {
        const errors: IValidationState['errors'] = {}
        //console.log('err.inner', err.inner)

        err.inner.forEach((x: any) => {
          if (x.path !== undefined) {
            if (!(x.path in errors)) errors[x.path] = []
            errors[x.path]?.push(x.errors[0])
          }
        })

        validationDispatch('SET_ERRORS', { errors: errors })
      })
  }, [formState, stepperDispatch, validationDispatch])
}

export default useValidation
