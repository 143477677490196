import { makeStyles, Theme } from '@material-ui/core/styles'

const drawerWidth = 300
export const logoHeight = '27px'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    paper: {
      /* backgroundColor: theme.palette.background.default + '99', */
      backgroundColor: theme.palette.background.default,
      /* backgroundColor: theme.palette.background.paper, */
      /* backgroundColor: 'transparent',*/
      backdropFilter: 'blur(6px)',
    },
    logosOuterContainer: {
      height: logoHeight,
    },
    logoContainer: {
      position: 'absolute',
      height: logoHeight,
      left: '50%',
      '& svg': {
        position: 'relative',
        left: '-50%',
      },
    },
    logoHide: {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      opacity: 0,
    },
    logoShow: {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      opacity: 1,
    },
    drawerOpen: {
      width: drawerWidth,
      /* [theme.breakpoints.down('sm')]: {
        width: '100vw',
      }, */
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [`${theme.breakpoints.down('xs')} and (orientation: portrait)`]: {
        width: '100%',
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),
    },
    mainSection: {
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  }
})
