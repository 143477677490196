import React, { ReactElement, useEffect, useCallback, useState } from 'react'

// Material-UI
import LocationIcon from '@material-ui/icons/LocationOn'

// Webtastic
import Autocomplete, {
  IAutocompleteOption,
} from '@naevaas/webtastic/dist/components/Autocomplete'

//
import { ISearch } from './types'
import { IGoogleAutocompletePlace } from '../types'

let gAutocomplete: any // I did not get the google maps typings to work :(
type _IAutoCompleteOption = IAutocompleteOption<IGoogleAutocompletePlace>

const Search = (props: ISearch): ReactElement => {
  const { selectPlace } = props
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    _IAutoCompleteOption[] | null
  >(null)

  useEffect(() => {
    gAutocomplete = new window.google.maps.places.AutocompleteService()
  }, [])

  const searchLocations = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      gAutocomplete.getPlacePredictions(
        { input: e.target.value || ' ' },
        (predictions: IGoogleAutocompletePlace[], status: string) => {
          if (status === 'OK') {
            const options = predictions.map(sugg => {
              const option: _IAutoCompleteOption = {
                id: sugg.place_id,
                label: sugg.description,
                primaryText: sugg.description,
                avatarIcon: <LocationIcon />,
                data: sugg,
              }

              return option
            })
            setAutocompleteOptions(options || null)
          }
        },
      )
    },
    [],
  )

  const handleSelectionChange = (
    selection: _IAutoCompleteOption[],
    reason: string,
  ): void => {
    if (reason === 'select-option') {
      const u = selection[0].data
      u && selectPlace(u)
    } else if (reason === 'clear') {
      selectPlace(null)
    }
  }

  return (
    <Autocomplete
      onInputChange={searchLocations}
      onSelectionChange={handleSelectionChange}
      multiple={false}
      options={autocompleteOptions || undefined}
      isFetching={false}
      textFieldProps={{
        label: 'Address or location',
        placeholder: `Search...`,
      }}
      filterOptions={(
        options: IAutocompleteOption[],
      ): IAutocompleteOption[] => {
        return options
      }}
    />
  )
}

export default Search
