import React, { useEffect, useState, useCallback } from 'react'
import clsx from 'clsx'

// Material UI
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Typography,
  CardContent,
  Button,
} from '@material-ui/core'

// Webtastic
import useStyledScrollbar from '@naevaas/webtastic/dist/hooks/useStyledScrollbar'

//
import {
  IProduct,
  ICompany,
  ICategory,
} from 'sections/systemAdmin/state/types/saletool'
import { Modals } from 'sections/systemAdmin/constants/routing'
import { defaultCard as card } from 'sections/systemAdmin/constants/salestool'
import { uploadProduct, getProducts } from 'sections/systemAdmin/utils/product'
import { getCategories } from 'sections/systemAdmin/utils/category'
import { getCompanies } from 'sections/systemAdmin/utils/company'
import Navigation from 'sections/systemAdmin/components/common/Navigation'
import LoadingSpinner from 'sections/systemAdmin/components/common/LoadingSpinner'
import ProductDialog from '../ProductDialog'

import { useStyles } from './styles'

const defaultCard = card as IProduct

function Salestool(): React.ReactElement {
  const [products, setProducts] = useState<IProduct[]>([])
  const [currentProduct, setCurrentProduct] = useState<IProduct>(defaultCard)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [categories, setCategories] = useState<ICategory[]>([])
  const [dialogView, toggleDialog] = useState('')
  const [isLoading, setLoadingState] = useState(true)
  const styledScrollbar = useStyledScrollbar()
  const classes = useStyles()

  const handleGetCategories = (): void => {
    getCategories().then((data: ICategory[]) => {
      setCategories(data)
    })
  }

  const handleGetProducts = (): void => {
    getProducts().then((data: IProduct[]) => {
      setProducts(data)
      setLoadingState(false)
    })
  }

  const handleGetCompanies = (): void => {
    getCompanies().then((data: ICompany[]) => {
      setCompanies(data)
    })
  }

  const closeDialog = useCallback(() => {
    toggleDialog('')
  }, [])

  const saveProduct = useCallback((product: IProduct, file?: File) => {
    setLoadingState(true)
    uploadProduct(product, file).then(() => {
      handleGetProducts()
    })
  }, [])

  useEffect(() => {
    handleGetProducts()
    handleGetCompanies()
    handleGetCategories()
  }, [])

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="flex-start"
      padding="24px"
      flexDirection="column"
    >
      <Navigation />
      <Typography className={classes.pageTitle} variant="h6" component="h2">
        Naeva salestool
      </Typography>
      <Button
        className={classes.createButton}
        color="primary"
        onClick={(): void => {
          setCurrentProduct(defaultCard)
          toggleDialog(Modals.CREATE_PRODUCT)
        }}
      >
        Create new product
      </Button>
      <Grid
        container
        spacing={3}
        className={clsx(
          classes.cardsContainer,
          styledScrollbar.styledScrollbar,
        )}
      >
        {products.map((product: IProduct) => (
          <Grid key={product.id} item xs={12} md={6} lg={4}>
            <Card className={classes.wrap}>
              <CardActionArea
                className={classes.wrap}
                onClick={(): void => {
                  setCurrentProduct(product)
                  toggleDialog(Modals.EDIT_PRODUCT)
                }}
              >
                <CardContent className={classes.content}>
                  <Typography variant="h6" component="h3">
                    {product.name}
                  </Typography>
                  <div className={classes.imageContainer}>
                    <img
                      className={classes.image}
                      src={product.imageUrl}
                      alt={product.name}
                    />
                  </div>
                  <Typography variant="body1" component="p">
                    {product.description}
                  </Typography>
                  <Button component="div" className={classes.editButton}>
                    Edit
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {dialogView && (
        <ProductDialog
          onClose={closeDialog}
          categories={categories}
          product={currentProduct}
          saveProduct={saveProduct}
          companies={companies}
        />
      )}
    </Box>
  )
}

export default Salestool
