import React from 'react'

function ConditionalWrapper(props: P): React.ReactElement {
  const { condition, wrapper, children } = props
  return condition ? <>{wrapper(children)}</> : <>{children}</>
}

type P = {
  condition: boolean
  wrapper: (children: React.ReactNode) => React.ReactNode
  children: React.ReactNode
}

export default ConditionalWrapper
