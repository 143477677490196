import React, { ReactElement } from 'react'

// Material UI
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Tooltip from '@material-ui/core/Tooltip'

// Common
import { Provider as GoogleMapContextProvider } from 'components/common/GoogleMap/context'
import {
  Provider as StepperContextProvider,
  useContext as useStepperContext,
} from 'components/common/Stepper/context'
import Stepper from 'components/common/Stepper'
import WarnBeforeLeavePage from 'components/common/WarnBeforeLeavePage'
import { useContext as useGoogleMapContext } from 'components/common/GoogleMap/context'

//
import PageWithAppBar from 'components/layout/PageWithAppBar'
import Footer from './Footer'

import SelectLocation from './SelectLocation'
import Details from './Details'
import { Provider as DetailsContextProvider } from './Details/context'
import Completed from './Completed'

const CreateSite = (): ReactElement => {
  const { activeStep, completed } = useStepperContext()
  const { selectedPlace } = useGoogleMapContext()

  return (
    <>
      <WarnBeforeLeavePage warn={!!selectedPlace} />
      <PageWithAppBar
        pageId={`step-${activeStep}`}
        AppBarProps={{
          leftActions: [
            <Tooltip key="back-button" title="Back">
              <IconButton
                aria-label="Back"
                onClick={(): void => {
                  window.history.back()
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>,
          ],
          title: 'Create site',
          actions: [],
          lowerContent: <Stepper />,
        }}
      >
        <Box display={activeStep === 0 || activeStep === 1 ? 'block' : 'none'}>
          <SelectLocation />
        </Box>

        <Box pb={10} display={activeStep === 2 ? 'block' : 'none'}>
          <Details />
        </Box>

        {completed ? <Completed /> : null}
      </PageWithAppBar>
      <Footer />
    </>
  )
}

const CreateSiteWithContext = (): ReactElement => {
  return (
    <StepperContextProvider
      initialState={{
        steps: [
          { label: 'Select location', validated: false },
          { label: 'Outline site', validated: false },
          { label: 'Details', validated: false },
        ],
        activeStep: 0,
        completed: false,
      }}
    >
      <GoogleMapContextProvider
        initialState={{
          centerPosition: null,

          /* selectedPlace: {
            description: 'Trondheim, Norway',
            place_id: 'ChIJU34DR5cxbUYR1PM8jyjI9ws',
            reference: 'ChIJU34DR5cxbUYR1PM8jyjI9ws',
            structured_formatting: {
              main_text: 'Trondheim',
              secondary_text: 'Norway',
            },
          }, */
        }}
      >
        <DetailsContextProvider initialState={{ siteName: '' }}>
          <CreateSite />
        </DetailsContextProvider>
      </GoogleMapContextProvider>
    </StepperContextProvider>
  )
}

export default CreateSiteWithContext
