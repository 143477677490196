import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((_theme: Theme) => {
  return {
    root: {},
    cardHeader: {
      alignItems: 'center',
      height: '100%',
    },
    avatar: {
      backgroundColor: 'transparent',
      color: 'inherit',
      /* '&>svg path': {
        fill: 'black',
      }, */
    },
  }
})
