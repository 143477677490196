import React, { ReactElement, useEffect } from 'react'

//
import { IWarnBeforeLeavePage } from './types'

const beforeUnload = (e: any) => {
  const dialogText = 'Do you really want to leave this site?'
  e.returnValue = dialogText
  return dialogText
}

const hashChange = (e: any) => {
  const back = e?.newURL?.indexOf('#1') === -1

  if (back) {
    const r = window.confirm(
      'You have unsaved data. Are you sure you want to leave?',
    )
    if (r === true) {
      //console.log('leave')
      window.history.back()
    } else {
      //console.log('stay')
      window.location.hash = '1'
    }
  }
}

const WarnBeforeLeavePage = (props: IWarnBeforeLeavePage): ReactElement => {
  const { warn = true } = props

  useEffect(() => {
    if (warn) {
      window.addEventListener('beforeunload', beforeUnload)

      window.location.hash = '1'
      window.addEventListener('hashchange', hashChange)
    }

    return (): void => {
      // Cleanup event listeners
      window.removeEventListener('beforeunload', beforeUnload)
      window.removeEventListener('hashchange', hashChange)
    }
  }, [warn])

  return <></>
}

export default WarnBeforeLeavePage
