import React, { useCallback } from 'react'
import Checkbox from '@material-ui/core/Checkbox'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import { ICategory } from 'sections/systemAdmin/state/types/saletool'

import { useStyles } from './styles'

type Order = 'asc' | 'desc'

const pageCount = {
  MIN: 15,
  MID: 25,
  MAX: 50,
}

export interface ICategoriesTable {
  categories: ICategory[]
  selectedCategories: string[]
  selectCategory: (categories: string[]) => void
}

function CategoriesTable(props: ICategoriesTable): React.ReactElement {
  const { categories, selectCategory, selectedCategories } = props
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(pageCount.MIN)

  const changeSortDirection = useCallback(() => {
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }, [order])

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const currentSelectedItems = event.target.checked
        ? categories.map((category: ICategory) => category.value)
        : []
      selectCategory(currentSelectedItems)
    },
    [categories, selectCategory],
  )

  const handleRowClick = useCallback(
    (id: string) => {
      const isSelected = selectedCategories.some(
        (selectedItem: string) => selectedItem === id,
      )
      const newSelected = isSelected
        ? selectedCategories.filter(
            (selectedItem: string) => selectedItem !== id,
          )
        : [...selectedCategories, id]

      selectCategory(newSelected)
    },
    [selectedCategories, selectCategory],
  )

  const onPageChange = useCallback((event: any, newPage: number) => {
    setPage(newPage)
  }, [])

  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
    },
    [],
  )

  const isSelected = useCallback(
    (id: string) =>
      selectedCategories.some((selectedId: string) => selectedId === id),
    [selectedCategories],
  )

  return (
    <TableContainer className={classes.root}>
      <Table aria-labelledby="Companies table" size="medium">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  selectedCategories.length > 0 &&
                  selectedCategories.length < categories.length
                }
                checked={
                  categories.length > 0 &&
                  selectedCategories.length === categories.length
                }
                onChange={handleSelectAllClick}
                color="primary"
              />
            </TableCell>
            <TableCell
              key="name"
              align="left"
              padding="none"
              sortDirection={order}
            >
              <TableSortLabel
                active
                direction={order}
                onClick={changeSortDirection}
              >
                Category name
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories
            .sort((a: ICategory, b: ICategory) => {
              const nameA = a.label.toUpperCase()
              const nameB = b.label.toUpperCase()
              if (nameA < nameB) {
                return order === 'asc' ? -1 : 1
              } else if (nameA > nameB) {
                return order === 'asc' ? 1 : -1
              }
              return 0
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item: ICategory) => {
              const isItemSelected = isSelected(item.value)

              return (
                <TableRow
                  hover
                  onClick={(): void => {
                    handleRowClick(item.value)
                  }}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={item.value}
                  selected={isItemSelected}
                  classes={{
                    selected: classes.selectedRow,
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} color="primary" />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={item.value}
                    scope="row"
                    padding="none"
                  >
                    {item.label}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={Object.values(pageCount)}
        component="div"
        count={categories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        className={classes.pagination}
        classes={{ toolbar: classes.toolbar }}
      />
    </TableContainer>
  )
}

export default React.memo(CategoriesTable)
