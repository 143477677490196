import { Record, RecordOf } from 'immutable'
import { UserActionTypes } from 'state/types/user'

const initialStateObject = { pageMessage: 'reducers' }

const HomeFactory: Record.Factory<IHomeReducer> =
  Record<IHomeReducer>(initialStateObject)

export interface IHomeReducer {
  pageMessage?: string
}

const initialState: RecordOf<IHomeReducer> = HomeFactory()

export default function foo(state = initialState, action: any) {
  switch (action.type) {
    case UserActionTypes.LOG_OUT: {
      return initialState
    }
    default: {
      return state
    }
  }
}
