import React, { ReactElement, useEffect, useState } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

//
import { IMachines } from './types'
import useFetchMachines from 'sections/booking/hooks/useFetchMachines'
import MachineCard from 'sections/booking/components/MachineCard'
import { TStep } from 'sections/booking/components/MachineDialog/types'
import MachineDialog from 'sections/booking/components/MachineDialog'

const Machines = (props: IMachines): ReactElement => {
  const { siteId } = props
  const [viewMachine, setViewMachine] = useState<
    { machine: IFirestoreMachine; step: TStep } | undefined
  >(undefined)

  const [fetch, { data }] = useFetchMachines()

  useEffect(() => {
    fetch({ filter: { siteId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId])

  const openMachineDetails = (machine: IFirestoreMachine): void => {
    setViewMachine({
      step: 'DETAILS',
      machine,
    })
  }

  const openMachineBookingForm = (machine: IFirestoreMachine): void => {
    setViewMachine({
      step: 'FORM',
      machine,
    })
  }

  return (
    <Box my={4}>
      {data?.length ? (
        <Grid container spacing={3}>
          {data.map(machine => {
            return (
              <Grid key={machine.id} item>
                <MachineCard
                  image={machine?.imageThumbUrl || undefined}
                  title={<>{machine.name}</>}
                  subtitle={<>{machine.category}</>}
                  onClick={(): void => {
                    openMachineDetails(machine)
                  }}
                  /* openItemForm={openItemForm} */
                  actions={
                    <Button
                      size="small"
                      color="primary"
                      onClick={(): void => openMachineBookingForm(machine)}
                    >
                      Book
                    </Button>
                  }
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Box mt={10}>
          <Grid container>
            <Grid item xs style={{ textAlign: 'center' }}>
              <Typography variant="h5" color="textSecondary">
                Found no machines
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      <MachineDialog
        machine={viewMachine?.machine}
        booking={{
          action: 'NEW',
        }}
        step={viewMachine?.step}
        onClose={(): void => {
          setViewMachine(undefined)
        }}
      />
    </Box>
  )
}

export default Machines
