import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'

export const useStyledScrollbar = makeStyles((theme: Theme) => {
  return {
    styledScrollbar: {
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme.palette.primary.main} rgba(0, 0, 0, 0)`,
      '&::-webkit-scrollbar': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '2px',
      },
    },
  }
})

export const useStyles = makeStyles((theme: Theme) => {
  return {
    appContainer: {
      minHeight: '100vh',
      /* display: 'flex', */
      /* '& .styled-scrollbar': {
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.primary.main} rgba(0, 0, 0, 0)`,
        '&::-webkit-scrollbar': {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          width: '4px',
          height: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.primary.main,
          borderRadius: '2px',
        },
      }, */
    },

    spinner: {
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
    },
    stagingBar: {
      position: 'absolute',
      top: '70px',
      left: '325px',
      width: '310px',
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      padding: '10px 0px',
      margin: 0,
      borderRadius: '20px',
      fontSize: '16px',
      transition: 'all 0.5s',
    },
    stagingBarOnLeft: {
      left: 70,
    },
  }
})
