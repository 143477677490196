import { useState, useRef } from 'react'
import firebase from 'firebase/app'

//
import { IFile } from './fileSelector'

export default function useFileUploader(): Response {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IFile | null>(null)
  const [error, setError] = useState(null)

  const { current: storageRef } = useRef(firebase.storage().ref())

  const upload = async (props: IUploadProps): Promise<IFile> => {
    setLoading(true)
    try {
      const { path = 'temp/', file } = props

      const completePath = `${path}${file.randName}`

      const fileRef = storageRef.child(completePath)

      const uploadTaskSnapshot = await fileRef.putString(
        file.dataURL,
        'data_url',
      )

      // Currently we do not use the downloadURL.
      //const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
      const downloadURL = 'true' // Truthy string

      const newFile = {
        ...file,
        downloadURL,
        __data: uploadTaskSnapshot,
      }

      setLoading(false)
      setData(newFile)

      return newFile
    } catch (error) {
      setLoading(false)
      setError(error)
      throw error
    }
  }

  const result = {
    loading,
    data,
    error,
  }

  return [upload, result]
}

interface IUploadProps {
  path?: string
  file: IFile
}

type Response = [
  (props: IUploadProps) => Promise<IFile>,
  { loading: boolean; data: IFile | null; error: object | null },
]
