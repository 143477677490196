import { useState, useCallback, useRef } from 'react'

//
import { ISiteList } from 'types/site'
import { formatSiteData } from 'state/formatters/site'
import * as db from 'utils/firestoreDB'

const useFetchCompanySites = (): Response => {
  const [sites, setSites] = useState<{
    [siteId: string]: IFirestoreSite
  } | null>(null)
  const [isListening, setIsListening] = useState(false)

  const unsubRef = useRef<Function | null>(null)

  const fetch = useCallback((props: IUseFetchCompanySites) => {
    const { companyId, filter } = props

    // Unsubscribe previous listener
    unsubRef.current && unsubRef.current()

    if (companyId && !isListening) {
      let query = db.sites().where('ownerCompany.id', '==', companyId)

      if (filter?.siteRoleWorkerId !== undefined) {
        query = query.where(
          'siteRoleWorkerIds',
          'array-contains',
          filter.siteRoleWorkerId,
        )
      }

      const unsubscribe = query.onSnapshot(function (response) {
        const sites: ISiteList = {}

        if (!response.empty) {
          response.docs.forEach((item) => {
            const site = item.data()
            sites[site.id] = formatSiteData(site)
          })
        }
        setSites(sites)
        setIsListening(true)
      })

      unsubRef.current = unsubscribe
    }

    // Unsubscribe on unmount
    return (): void => {
      unsubRef.current && unsubRef.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [fetch, { sites }]
}

export default useFetchCompanySites

export interface IUseFetchCompanySites {
  companyId: string
  filter?: {
    siteRoleWorkerId?: string
  }
}

type Response = [
  (props: IUseFetchCompanySites) => void,
  {
    sites: ISiteList | null
  },
]
