import { IState, TAction } from './types'

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'SET_SITE_NAME': {
      return {
        ...state,
        siteName: action.name,
      }
    }

    case 'SET_ADDRESS': {
      return {
        ...state,
        address: action.address,
      }
    }

    case 'SET_CONTACTS': {
      return {
        ...state,
        contacts: action.contacts,
      }
    }

    case 'SET_SITE_OWNER': {
      return {
        ...state,
        siteOwner: action.companyId,
      }
    }

    case 'SET_CLIENT': {
      return {
        ...state,
        client: action.company ?? undefined,
      }
    }

    case 'SET_REQUEST_ERRORS': {
      return {
        ...state,
        requestErrors: action.errors,
      }
    }

    case 'CLEAR_REQUEST_ERRORS': {
      return {
        ...state,
        requestErrors: undefined,
      }
    }

    case 'SET_REQUEST_SUCCESS': {
      return {
        ...state,
        requestSuccess: {
          siteId: action.siteId,
        },
      }
    }

    default:
      return state
  }
}
