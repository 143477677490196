import { useState, useCallback, useRef } from 'react'

//
import {
  IFirestoreGateKeeper,
  IFirestoreSite,
} from '@naevaas/backend/dist/types'

import * as db from 'utils/firestoreDB'

const useGateKeeperSubscription = (): Response => {
  const [gates, setGates] = useState<TGates | null>(null)
  const [isListening, setIsListening] = useState(false)

  const unsubRef = useRef<Function | null>(null)

  const subscribe = useCallback((props: IUseGateKeeperSubscription) => {
    const { siteId } = props

    // Unsubscribe previous listener
    unsubRef.current && unsubRef.current()

    if (siteId && !isListening) {
      let query = db.gateKeepers().where('connectedToSiteId', '==', siteId)

      const unsubscribe = query.onSnapshot(function (response) {
        const gates: TGates = {}

        if (!response.empty) {
          response.docs.forEach((item) => {
            const gate = item.data()
            gates[gate.id] = gate
          })
        }
        setGates(gates)
        setIsListening(true)
      })

      unsubRef.current = unsubscribe
    }

    // Unsubscribe on unmount
    return (): void => {
      unsubRef.current && unsubRef.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [subscribe, { gates: gates }]
}

export default useGateKeeperSubscription

type TGates = { [gateId: string]: IFirestoreGateKeeper }

export interface IUseGateKeeperSubscription {
  siteId: IFirestoreSite['id']
}

type Response = [
  (props: IUseGateKeeperSubscription) => void,
  {
    gates: TGates | null
  },
]
