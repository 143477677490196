import { storage } from 'firebase/app'
import { generateUUID } from './common'

type resource = 'product' | 'company'

export const uploadImageFile = async (
  file: File,
  type: resource,
): Promise<any> => {
  const name = generateUUID()
  const imgType = file.type.substring(6)
  const ref =
    type === 'product'
      ? 'scpssProducts/productImages/' + name + '.' + imgType
      : 'scpssProductProviders/' + name + '/' + name + '.' + imgType
  const imagesRef = storage()
    .ref()
    .child(ref)
  await imagesRef.put(file)

  return imagesRef.fullPath
}
