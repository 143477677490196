import { useFetch } from 'hooks/useFetch'

export const useUpdateRequirement = (): Response => {
  const [fetch] = useFetch()

  const request = async (props: IRequest): Promise<any> => {
    const { site, fieldName, fieldValue } = props

    let endpoint = ''
    let requirements = {}
    switch (fieldName) {
      case 'checkInByProxy': {
        endpoint = 'updateSiteGeofence'
        if (fieldValue === true) {
          requirements = {
            checkInByProxy: true,
            checkOutByProxy: true,
            checkInByRefero: false,
            checkOutByRefero: false,
          }
        } else {
          requirements = {
            checkInByProxy: false,
            checkOutByProxy: false,
          }
        }

        break
      }
      case 'checkInByRefero': {
        endpoint = 'updateSiteGeofence'
        if (fieldValue === true) {
          requirements = {
            checkInByRefero: true,
            checkOutByRefero: true,
            checkInByProxy: false,
            checkOutByProxy: true,
          }
        } else {
          requirements = {
            checkInByRefero: false,
            checkOutByRefero: false,
            checkOutByProxy: false,
          }
        }

        break
      }
      case 'checkOutByProxy': {
        endpoint = 'updateSiteGeofence'

        if (site.checkSettings.checkInByRefero === true) {
          requirements = { checkOutByProxy: fieldValue }
        }

        break
      }
      default: {
        endpoint = 'updateSiteInfo'
        requirements = {
          workerRequirements: {
            [fieldName]: fieldValue,
          },
        }
      }
    }

    const request = await fetch({
      endpoint,
      data: {
        siteId: site.id,
        ...requirements,
      },
    })
      .then(response => {
        if (response.code === 'C2000') {
          return { fieldName, fieldValue }
        } else {
          window.furball.error('Error! Could not save. ' + response?.code)
        }
      })
      .catch(() => {
        window.furball.error('Error: Please try again or contact support.')
      })

    return request
  }

  return request
}

interface IRequest {
  site: IFirestoreSite
  fieldName: string
  fieldValue: boolean
}

type Response = (props: IRequest) => Promise<any>
