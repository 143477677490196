import React, { useCallback } from 'react'

// Material-UI
import { Link, Box } from '@material-ui/core'

import Logo from 'components/common/graphics/logo'
import SingInForm from './SingInForm'
import { useStyles } from 'components/views/SignIn/styles'
import { Routes } from 'constants/routing'
import { useAuthContextActions } from 'state/context/authContext'

interface ISignupProps {
  history: any
}

function SignIn(props: ISignupProps): React.ReactElement {
  const { history } = props

  const classes = useStyles()
  const authContextActions = useAuthContextActions()

  const handleRedirect = (): void => {
    history.push(Routes.SIGN_UP)
  }
  const signInWithGoogle = useCallback(async (): Promise<void> => {
    await authContextActions.signInWithGoogle()
  }, [authContextActions])

  const submitSignInForm = useCallback(
    async (values: any): Promise<void> => {
      await authContextActions.signIn(values.password, values.email)
    },
    [authContextActions],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      height="100%"
    >
      <Box pt={10}>
        <Logo />
      </Box>
      <Box pt={3}>
        {`Don't have an account?`}{' '}
        <Link onClick={handleRedirect} className={classes.link} type="link">
          Sign Up
        </Link>
      </Box>

      <Box pt={10}>
        <SingInForm
          submitForm={submitSignInForm}
          signInWithGoogle={signInWithGoogle}
        />
      </Box>
    </Box>
  )
}

export default SignIn
