import React, { ReactElement, useState, useEffect } from 'react'

// Material UI
import CompanyIcon from '@material-ui/icons/Business'

// Webtastic
import Autocomplete, {
  IAutocompleteOption,
} from '@naevaas/webtastic/dist/components/Autocomplete'

//
import { IFindUserOrCompany } from './types'
import useDebounce from 'hooks/useDebounce'
import useFindCompanies from 'hooks/useFindCompanies'
import { useCloudFunction } from 'hooks/useCloudFunctions'

const FindUserOrCompany = (props: IFindUserOrCompany): ReactElement => {
  const {
    onSelectionChange: onSelectedChange,
    typename = 'USER',
    initialSelection = [],
    autocompleteProps = {},
    companyFilter,
  } = props

  /* const multiple = autocompleteProps?.multiple ?? true
  const textFieldProps = autocompleteProps?.textFieldProps */
  const {
    multiple = true,
    textFieldProps,
    options: defaultOptions,
    ...autocompletePropsRest
  } = autocompleteProps

  const [keyword, setKeyword] = useState<string | null>(null)
  const [selectedItems, setSelectedItems] =
    useState<IAutocompleteOption[]>(initialSelection)
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    IAutocompleteOption[] | null
  >(null)
  const [autocompleteIsFetching, setAutocompleteIsFetching] = useState(false)

  const [fetchUsers] = useCloudFunction()
  const [findCompanies] = useFindCompanies()
  const debouncedKeyword = useDebounce(keyword, 250)

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setKeyword(e.target.value)
  }

  const handleSelectionChange = (
    selection: IAutocompleteOption[],
    reason: string,
  ): void => {
    setSelectedItems(selection)
    onSelectedChange && onSelectedChange(selection, reason)
  }

  useEffect(() => {
    if (debouncedKeyword && !defaultOptions) {
      setAutocompleteIsFetching(true)
      if (typename === 'USER') {
        fetchUsers('searchUser', {
          searchString: debouncedKeyword.trim(),
          excludeSelf: false,
        })
          .then((response) => {
            const users = response?.data?.users
            const options = [] as IAutocompleteOption[]
            if (users) {
              users.forEach((user) => {
                options.push({
                  id: user.id,
                  avatarUrl: user.imageThumbUrl ?? '',
                  label: user.name,
                  primaryText: user.name,
                  secondaryText: user.companyName,
                  data: user,
                })
              })
            }

            setAutocompleteOptions(options.length ? options : null)
          })
          .finally(() => {
            setAutocompleteIsFetching(false)
          })
      } else if (typename === 'COMPANY') {
        findCompanies({
          keyword: debouncedKeyword,
          ...companyFilter,
        })
          .then((res) => {
            if (res) {
              const options = [] as IAutocompleteOption[]
              res.forEach((v) => {
                options.push({
                  id: v.id,
                  avatarUrl: '',
                  avatarIcon: <CompanyIcon />,
                  label: v.name,
                  primaryText: v.name,
                  secondaryText: v?.orgNr || '',
                  data: v,
                })
              })
              setAutocompleteOptions(options)
            }
          })
          .finally(() => {
            setAutocompleteIsFetching(false)
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typename, debouncedKeyword, defaultOptions])

  return (
    <>
      <Autocomplete
        onInputChange={handleInputChange}
        onSelectionChange={handleSelectionChange}
        multiple={multiple}
        options={defaultOptions || autocompleteOptions || undefined}
        isFetching={autocompleteIsFetching}
        value={multiple ? selectedItems : selectedItems[0] ?? null}
        textFieldProps={{
          placeholder: `Search by name`,
          label: `${
            typename === 'USER'
              ? multiple
                ? 'U\u200bsers'
                : 'U\u200bser'
              : multiple
              ? 'C\u200bompanies'
              : 'C\u200bompany'
          }`,
          ...textFieldProps,
        }}
        {...autocompletePropsRest}
      />
    </>
  )
}

export default FindUserOrCompany
