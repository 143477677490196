import React, { ReactElement, useCallback, useState } from 'react'

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

// Webtastic
import Button from '@naevaas/webtastic/dist/components/Button'

// Common
import {
  useContext as useStepperContext,
  useContextDispatch as useStepperContextDispatch,
} from 'components/common/Stepper/context'

//
import { usePost } from '../usePost'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      /* border: 'red dashed 2px', */
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }
})

const Footer = (): ReactElement => {
  const [post, { loading }] = usePost()
  const { steps, activeStep } = useStepperContext()
  const dispatch = useStepperContextDispatch()
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  const disableStepButton = loading || !steps[activeStep]?.validated

  const classes = useStyles()

  const handleNext = useCallback(() => {
    if (activeStep === steps.length - 1) {
      post()
    } else {
      dispatch('SET_ACTIVE_STEP', { index: activeStep + 1 })
    }
  }, [activeStep, dispatch, post, steps.length])

  const handleBack = useCallback(() => {
    dispatch('SET_ACTIVE_STEP', { index: activeStep - 1 })
  }, [activeStep, dispatch])

  const handleReset = useCallback(() => {
    dispatch('SET_ACTIVE_STEP', { index: 0 })
  }, [dispatch])

  return (
    <Box className={classes.root} display="flex" p={2} zIndex={1}>
      <Box flex="auto" />
      <Box>
        {activeStep === steps.length ? (
          <Button onClick={handleReset}>Reset</Button>
        ) : (
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              Back
            </Button>
            <Tooltip
              title={'Please drag the dots to create an outline of the site'}
              open={activeStep === 1 && isTooltipOpen && disableStepButton}
            >
              <span
                onTouchStart={(): void => {
                  if (disableStepButton) {
                    setTooltipOpen(!isTooltipOpen)
                  }
                }}
                onMouseEnter={(): void => {
                  if (disableStepButton) {
                    setTooltipOpen(true)
                  }
                }}
                onMouseLeave={(): void => {
                  if (disableStepButton) {
                    setTooltipOpen(false)
                  }
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={disableStepButton}
                  isWaiting={loading}
                >
                  {activeStep === steps.length - 1 ? 'Create' : 'Next'}
                </Button>
              </span>
            </Tooltip>
          </div>
        )}
      </Box>
    </Box>
  )
}

export default Footer
