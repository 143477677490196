import React, { ReactElement } from 'react'

// Webtastic
import TextFieldWithValidation, {
  useContext as useValidationContext,
} from '@naevaas/webtastic/dist/components/TextFieldValidation'

//
import { useContext, useContextDispatch } from '../context'

const SiteName = (): ReactElement => {
  const { siteName } = useContext()
  const dispatch = useContextDispatch()

  const { errors } = useValidationContext()

  return (
    <>
      <TextFieldWithValidation
        id="site-name"
        label="Site name"
        value={siteName}
        onChange={(e: any): void => {
          dispatch('SET_SITE_NAME', { name: e.target.value })
        }}
        errors={errors?.siteName ?? null}
      />
    </>
  )
}

export default SiteName
