import React from 'react'

// Material UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'

// Webtastic
import Dialog, {
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@naevaas/webtastic/dist/components/Dialog'
import Button from '@naevaas/webtastic/dist/components/Button'

//
import AddDocument from './AddDocument'
import DocumentList from './DocumentList/DocumentList'

import { useContext, useContextDispatch, IDocumentsDialog } from '.'

const DocumentsDialog = (props: IDocumentsDialog): React.ReactElement => {
  const { site } = props

  // context
  const state = useContext()
  const dispatch = useContextDispatch()

  const closeDialog = () => dispatch('SET_DIALOG_IS_OPEN', { open: false })
  const navigateToActive = () => dispatch('SET_VIEW', { view: 'ACTIVE' })
  const navigateToArchive = () => dispatch('SET_VIEW', { view: 'ARCHIVE' })
  const navigateToUploader = () => dispatch('SET_VIEW', { view: 'UPLOADER' })
  const cancelUpload = () => {
    dispatch('SET_VIEW', { view: 'ACTIVE' })
  }
  const startUpload = () => {
    dispatch('SET_UPLOAD_STATUS', { status: 'START' })
  }

  return (
    <Dialog
      open={state.dialogIsOpen}
      onClose={closeDialog}
      fullWidth={true}
      maxWidth={'md'}
    >
      {
        /**
         * Title
         */
        <DialogTitle>
          <Box display="flex" alignItems="center">
            {state.view !== 'ACTIVE' && (
              <Box mr={1}>
                <IconButton onClick={navigateToActive}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
            )}
            <Box>
              {state.view === 'ACTIVE' ? 'Required documents' : null}
              {state.view === 'ARCHIVE' ? 'Archive' : null}
              {state.view === 'UPLOADER' ? 'Add document' : null}
            </Box>
          </Box>
        </DialogTitle>
      }

      {
        /**
         * Content
         */
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm>
              <DialogContentText>Site: {site.name}</DialogContentText>
            </Grid>
          </Grid>

          <>
            {state.view === 'ACTIVE' && (
              <Box display="flex">
                <Box flex="auto"></Box>
                <Box display="flex">
                  <Box mr={2}>
                    <Button onClick={navigateToArchive}>
                      Archived documents
                    </Button>
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={navigateToUploader}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            )}
          </>

          {['ACTIVE', 'ARCHIVE'].includes(state.view || '') && (
            <DocumentList
              items={site.rde}
              isArchived={state.view === 'ARCHIVE'}
              siteId={site.id}
            />
          )}

          {state.view === 'UPLOADER' ? <AddDocument siteId={site.id} /> : null}
        </DialogContent>
      }

      {/**
       * Actions
       */}
      <>
        {state.view === 'UPLOADER' && (
          <DialogActions>
            <Button onClick={cancelUpload}>Cancel</Button>
            <Button
              onClick={startUpload}
              variant="contained"
              color="primary"
              disabled={state.uploadStatus !== 'READY'}
              isWaiting={['START', 'IN_PROGRESS'].includes(
                state.uploadStatus || '',
              )}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </>
    </Dialog>
  )
}

export default React.memo(DocumentsDialog)
