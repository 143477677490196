import React, { ReactElement } from 'react'

// Material-UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// Webtastic

import { Provider as ValidationProvider } from '@naevaas/webtastic/dist/components/TextFieldValidation'

//
import SiteName from './SiteName'
import Address from './Address'
import Contacts from './Contacts'
import SiteOwner from './SiteOwner'
import Client from './Client'
import useValidation from './useValidation'
import ErrorDialog from './ErrorDialog'

const Details = (): ReactElement => {
  useValidation()

  return (
    <>
      <form noValidate autoComplete="off">
        <Box mb={2}>
          <Typography variant="h6" component="h2">
            Site
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs sm={6}>
            <SiteName />
          </Grid>
          <Address />
        </Grid>

        <Box mt={4}>
          <Typography variant="h6" component="h2">
            Contacts
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs md={6}>
            <Contacts />
          </Grid>
        </Grid>

        <Box mt={4} mb={2}>
          <Typography variant="h6" component="h2">
            Site owner
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs md={6}>
            <SiteOwner />
          </Grid>
        </Grid>

        <Box mt={4} mb={2}>
          <Typography variant="h6" component="h2">
            Client
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs md={6}>
            <Client />
          </Grid>
        </Grid>
        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      </form>
      <ErrorDialog />
    </>
  )
}

const DetailsWithContext = (): ReactElement => {
  return (
    <ValidationProvider>
      <Details />
    </ValidationProvider>
  )
}

export default DetailsWithContext
