import React, { ReactElement } from 'react'

// Material-UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'

// Webtastic
import { DialogContentText } from '@naevaas/webtastic/dist/components/Dialog'

//
import { useBookingContext } from 'sections/booking/context/bookingContext'
import { ReactComponent as PartyIcon } from 'sections/booking/utils/images/Party.svg'
import BookingDetails from 'sections/booking/components/BookingDetails'

const BookingConfirm = (): ReactElement => {
  // Context
  const bookingContext = useBookingContext()
  const booking = bookingContext?.doc || {}

  if (!bookingContext) {
    return <div />
  }

  return (
    <>
      <Box my={3} mx={2}>
        {booking.completed ? (
          <Box textAlign="center">
            <h1>YOU ROCK!</h1>
            <Box my={2}>
              <h3>You have successfully booked the machine.</h3>
            </Box>
            <Box mt={8}>
              <SvgIcon
                viewBox="0 0 1002.788 309.23"
                style={{ fontSize: '400px', height: 'unset' }}
              >
                <PartyIcon />
              </SvgIcon>
            </Box>
          </Box>
        ) : (
          <>
            <h3>Summary</h3>
            <Box>
              <Typography variant="body2">Machine</Typography>
              <DialogContentText>{`${booking.machineMake} ${booking.machineModel}`}</DialogContentText>
            </Box>
            <Typography variant="body2">Period</Typography>
            <DialogContentText component="div">
              <BookingDetails booking={booking} />
            </DialogContentText>
          </>
        )}
      </Box>
    </>
  )
}

export default BookingConfirm
