import React, { ReactElement, useEffect, useMemo } from 'react'

// Material UI
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// Webtastic
import {
  useContext as useValidationContext,
  useValidationHelper,
} from '@naevaas/webtastic/dist/components/TextFieldValidation'

// Common
import FindUserOrCompany, {
  IFindUserOrCompany,
} from 'components/common/FindUserOrCompany'

//
import { useContextDispatch } from '../context'
import { useAuthUserContext } from 'state/context/authUserContext'

const Contacts = (): ReactElement => {
  const authUser = useAuthUserContext()
  const dispatch = useContextDispatch()

  const { errors } = useValidationContext()

  const validationHelper = useValidationHelper({
    errors: errors?.contacts ?? null,
  })

  const initialContact = useMemo(() => {
    return authUser
      ? {
          id: authUser?.uid as string,
          label: authUser?.name,
          primaryText: authUser?.name,
        }
      : null
  }, [authUser])

  /**
   * Set initial selection
   */
  useEffect(() => {
    if (initialContact) {
      dispatch('SET_CONTACTS', { contacts: [initialContact.id] })
    }
  }, [dispatch, initialContact])

  const onSelectionChange: IFindUserOrCompany['onSelectionChange'] = (
    selection,
  ): void => {
    const selectedIds = selection.map((user) => {
      return user.id
    })

    dispatch('SET_CONTACTS', { contacts: selectedIds })
    validationHelper.handleFocus()
    validationHelper.handleBlur()
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="body2" color="textSecondary">
          One or more persons to contact with questions or to get information.
        </Typography>
      </Box>
      <FindUserOrCompany
        typename="USER"
        initialSelection={initialContact ? [initialContact] : undefined}
        onSelectionChange={onSelectionChange}
        autocompleteProps={{
          textFieldProps: {
            onFocus: validationHelper.handleFocus,
            onBlur: validationHelper.handleBlur,
            helperText: validationHelper.helperText,
            error: validationHelper.isError,
            required: true,
          },
        }}
      />
    </>
  )
}

export default Contacts
